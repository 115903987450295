import { Box, Typography, useTheme } from "@mui/material";
import { NavbarIcon } from "../atoms/navbar/Icon";

interface IShowAll {
  showIcon: boolean;
  justifyContent: "space-between" | "flex-start";
  rightComponent: React.ReactNode;
  isEmpty: boolean;
  title?: string;
}

const ShowAll = ({ rightComponent, justifyContent, showIcon, isEmpty, title }: IShowAll) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: justifyContent,
        height: 30,
        width: showIcon ? "90%" : "100%",
        padding: !showIcon ? "" : "2px 16px 6px 0px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          textOverflow: "ellipsis",
          gap: "5px",
        }}
      >
        {showIcon && !isEmpty && (
          <NavbarIcon variant={"show"} sx={{ height: "22px", width: "22px" }} />
        )}
        {showIcon && isEmpty && (
          <NavbarIcon variant={"hide"} sx={{ height: "22px", width: "22px" }} />
        )}

        {!isEmpty && (
          <Typography
            pl={showIcon ? 0.5 : 0}
            variant={showIcon ? "h4" : "body2"}
            color={showIcon ? theme.palette.custom.grayTypography : ""}
          >
            {title ? title : showIcon ? "Show:" : "Show All"}
          </Typography>
        )}
      </Box>
      {rightComponent}
    </Box>
  );
};
export default ShowAll;
