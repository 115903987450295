import { Box, useTheme } from "@mui/material";
import { ScreenSmallerThen } from "../../utilities/UIHelper";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { CircularLoader } from "../../molecules/skeleton-loader/CircularLoader";
import { APITableLoader } from "./APITableLoader";
import { ComplianceFramework, RecentViewed } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { TableLoader } from "./TableLoader";

export const OrganizationOverviewLoader = () => {
  const isSmallScreen = ScreenSmallerThen();
  const theme = useTheme();
  const fillBlankSpace = true;

  const frameworksColumnVisibility: IColumnVisibility<ComplianceFramework>[] = [
    {
      field: "name",
      headerName: "Framework",
      visible: true,
    },
    {
      field: "created_on",
      headerName: "Started On",
      visible: true,
    },
    {
      field: "progress",
      headerName: "Control Progress",
      visible: true,
    },
    {
      field: "progress",
      headerName: "Progress",
      visible: true,
    },
  ];

  const columnsVisibility: IColumnVisibility<RecentViewed>[] = [
    {
      field: "object",
      headerName: "Id",
      visible: true,
    },
    {
      field: "object",
      headerName: "Name / Summary",
      visible: true,
    },
    {
      field: "object",
      headerName: "Type",
      visible: true,
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      gap={isSmallScreen ? "20px" : "0px"}
    >
      <Box
        display="flex"
        height={isSmallScreen ? "unset" : "344px"}
        flexDirection={isSmallScreen ? "column" : "row"}
        gap={isSmallScreen ? "20px" : "0px"}
        sx={{
          borderBottomLeftRadius: isSmallScreen ? "6px" : "0px",
          borderBottomRightRadius: isSmallScreen ? "6px" : "0px",
        }}
      >
        <Box
          padding="10px"
          overflow="scroll"
          display="flex"
          flexGrow="1"
          alignItems="center"
          justifyContent="center"
          sx={{
            borderBottomLeftRadius: isSmallScreen ? "6px" : "0px",
            borderBottomRightRadius: isSmallScreen ? "6px" : "0px",
          }}
          bgcolor={theme.palette.custom.secondaryBackground}
          width={isSmallScreen ? "100%" : "50%"}
          border="1px solid"
          borderRight={0}
          borderColor={theme.palette.custom.secondaryBorder}
        >
          <CircularLoader width="240px" height="240px" />
        </Box>
        <Box width={isSmallScreen ? "100%" : "50%"} overflow="visible">
          <Box
            sx={{
              maxWidth: "100%",
              width: "100%",
              height: fillBlankSpace ? "100%" : "unset",
              overflow: "visible",
            }}
          >
            <Box height={isSmallScreen ? "100%" : "344px"}>
              <Box
                paddingBottom={"0px"}
                borderRadius="6px"
                border="1px solid"
                minHeight="100%"
                maxHeight="100%"
                overflow="auto"
                borderColor={theme.palette.custom.secondaryBorder}
                bgcolor={theme.palette.custom.secondaryBackground}
                sx={
                  isSmallScreen
                    ? {}
                    : {
                        borderTopRightRadius: "0px",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderBottomRightRadius: "0px",
                      }
                }
              >
                <APITableLoader
                  columnsVisibility={frameworksColumnVisibility}
                  tableSize={"small"}
                  secondComponent={false}
                  tableSearch={false}
                  filtersVisibility={false}
                  dateFilters={false}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        width="100%"
        border="1px solid"
        borderRadius="6px"
        borderTop={isSmallScreen ? 1 : 0}
        borderColor={theme.palette.custom.secondaryBorder}
        bgcolor={theme.palette.custom.secondaryBackground}
        flexDirection="column"
        sx={{
          borderTopLeftRadius: isSmallScreen ? "6px" : "0px",
          borderTopRightRadius: isSmallScreen ? "6px" : "0px",
        }}
      >
        <Box
          display="flex"
          width="100%"
          gap="10px"
          padding="10px"
          bgcolor={theme.palette.custom.secondaryBackground}
          sx={{ borderTopLeftRadius: "6px", borderTopRightRadius: "6px" }}
        >
          <RectangularLoader height="35px" width="108px" />
          <RectangularLoader height="35px" width="108px" />
          <RectangularLoader height="35px" width="108px" />
        </Box>
        <TableLoader columnsVisibility={columnsVisibility} rows={5} />
      </Box>
    </Box>
  );
};
