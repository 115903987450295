import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { IPlan, useSubscription } from "../../context/SubscriptionContext";

import OrganizationContainer from "../OrganizationContainer";
import { PlanCard } from "../PlanCard";
import { CancelPlanModal } from "./CancelPlanModal";
import { ReactComponent as CoreIcon } from "../../assets/planIcons/core.svg";
import { ReactComponent as PremiumIcon } from "../../assets/planIcons/premium.svg";
import { ReactComponent as EnterpriseIcon } from "../../assets/planIcons/enterprise.svg";
import { useState } from "react";
import { AxiosError } from "axios";
import { openSnackbar } from "../../context/SnackbarContext";
import { Awaitable } from "../../utilities/common";

interface PlanProps {
  minHeight?: string;
  showTitle?: boolean;
}

export const Plan = (props: PlanProps) => {
  const { minHeight, showTitle } = props;
  const theme = useTheme();
  const {
    createSubscription,
    updateSubscription,
    subscriptions,
    currentPlan,
    isCancelled,
    isUpdating,
    plans,
    daysLeft,
  } = useSubscription();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const isSmScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLgScreen = useMediaQuery(theme.breakpoints.down("xl"));

  const startPlan = async (plan: IPlan) => {
    try {
      await createSubscription(plan.id);
      openSnackbar("Started subscription", "success");
    } catch (e) {
      const errorMessage = (e as AxiosError<{ detail: string }>)?.response?.data?.detail;
      openSnackbar(errorMessage ?? "Failed to start subscription", "error");
    }
  };

  const changePlan = async (plan: IPlan) => {
    try {
      await updateSubscription(plan.id);
      openSnackbar("Changed subscription", "success");
    } catch (e) {
      const errorMessage = (e as AxiosError<{ detail: string }>)?.response?.data?.detail;
      openSnackbar(errorMessage ?? "Failed to update subscription", "error");
    }
  };

  const getButtonProps = (plan: IPlan): [string, () => Awaitable<void>] => {
    const isTrialSubscription =
      subscriptions.find((sub) => sub.items.data[0].plan.product === currentPlan?.id)?.status ===
      "trialing";
    const isCanceledSubscription =
      subscriptions.find((sub) => sub.items.data[0].plan.product === currentPlan?.id)?.status ===
      "canceled";
    const isCancellable = plan.id === currentPlan?.id && !isCancelled;
    const isPaused = subscriptions[0]?.status === "paused";
    const contactUsCallback = () => {
      // const emailUrl = "mailto:george.chamoun@fairo.ai";
      // window.open(emailUrl, "_blank");
    };
    if (isTrialSubscription && daysLeft > 0 && plan.id === currentPlan?.id) {
      return [`${daysLeft}D Remaining`, async () => {}];
    }
    if (currentPlan == null || isTrialSubscription || isPaused || isCanceledSubscription) {
      if (plan.metadata.CAN_SELF_SUBSCRIBE === "TRUE") {
        return ["Start Plan", async () => await startPlan(plan)];
      }
      return ["Contact Us", contactUsCallback];
    } else if (plan.id === currentPlan.id) {
      if (isCancellable) {
        return ["Cancel Plan", () => setIsCancelModalOpen(true)];
      }
      return ["Resubscribe", async () => await changePlan(plan)];
    } else {
      if (plan.metadata.CAN_SELF_SUBSCRIBE !== "TRUE") {
        return ["Contact Us", contactUsCallback];
      }
      const currentPlanPrice = Number(
        currentPlan.metadata.DISPLAY_PRICE.split("/")[0].replace("$", "")
      );
      const planPrice = Number(plan.metadata.DISPLAY_PRICE.split("/")[0].replace("$", ""));
      return [
        currentPlanPrice < planPrice ? "Upgrade Plan" : "Downgrade Plan",
        async () => await changePlan(plan),
      ];
    }
  };

  return (
    <OrganizationContainer
      fullWidthContent={true}
      minHeight={minHeight}
      content={
        <Box sx={{ width: "100%", paddingTop: "50px" }}>
          <CancelPlanModal isOpen={isCancelModalOpen} handleModal={setIsCancelModalOpen} />
          {showTitle && (
            <Typography variant="h2" marginBottom="15px">
              Plans
            </Typography>
          )}
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="center"
            gap="15px"
            flexWrap="wrap"
            alignItems={isSmScreen ? "center" : "stretch"}
          >
            {plans.map((plan) => {
              const { id, name, metadata } = plan;
              const [buttonText, buttonCallback] = getButtonProps(plan);
              return (
                <PlanCard
                  isActive={currentPlan?.id === id}
                  title={name}
                  subtitle={metadata.DISPLAY_PRICE}
                  summary={metadata.FEATURE_LIST.split("|")}
                  buttonText={buttonText}
                  onClick={buttonCallback}
                  disabled={isUpdating}
                />
              );
            })}
          </Box>
        </Box>
      }
    />
  );
};

export default Plan;
