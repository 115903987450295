import { Box, useTheme } from "@mui/material";
import React from "react";

const PrimaryBox = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        minHeight: "70vh",
        backgroundColor: "background.paper",
        borderRadius: "6px",
        justifyContent: "flex-start",
        flexDirection: "column",
        border: `1px solid ${theme.palette.custom.secondaryBorder}`,
      }}
    >
      {children}
    </Box>
  );
};

export default PrimaryBox;
