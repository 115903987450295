import { Box, Stack, useTheme } from "@mui/material";
import { ScreenSmallerThen } from "../../utilities/UIHelper";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { User } from "../../models/types";
import SecondaryBox from "../../atoms/box/SecondaryBox";

export const OrganizationUsersPageLoader = () => {
  const isSmallScreen = ScreenSmallerThen();
  const pageSize = isSmallScreen ? "unset" : "calc(80vh - 56px)";
  const theme = useTheme();

  const columnsVisibilityRegistered: IColumnVisibility<User>[] = [
    { field: "name", headerName: "Name", visible: true },
    {
      field: "date_joined",
      headerName: "Joined on",
      visible: true,
    },
    { field: "email", headerName: "Email", visible: true },
    {
      field: "permissions",
      headerName: "Permissions",
      visible: true,
    },
    {
      field: "is_active",
      headerName: "Status",
      visible: true,
    },
  ];

  const columnsVisibilityInvited: IColumnVisibility<User>[] = [
    { field: "name", headerName: "Name", visible: true },
    {
      field: "invited_on",
      headerName: "Invited on",
      visible: true,
    },
    { field: "email", headerName: "Email", visible: true },
    {
      field: "is_invited",
      headerName: "Re-send invitation",
      visible: true,
    },
    {
      field: "is_active",
      headerName: "Cancel invitation",
      visible: true,
    },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        borderWidth: "1px",
        borderRadius: "6px",
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderColor: theme.palette.divider,
        borderStyle: "solid",
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Stack minHeight={pageSize} spacing="10px" gap="75px" padding="10px">
        <Stack gap="10px">
          <RectangularLoader height="35px" width="100px" />
          <SecondaryBox>
            <APITableLoader
              columnsVisibility={columnsVisibilityRegistered}
              tableSize={"small"}
              secondComponent={true}
              tableSearch={true}
              filtersVisibility={true}
              dateFilters={false}
            />
          </SecondaryBox>
        </Stack>
        <Stack gap="10px">
          <RectangularLoader height="35px" width="100px" />
          <SecondaryBox>
            <APITableLoader
              columnsVisibility={columnsVisibilityInvited}
              tableSize={"small"}
              secondComponent={false}
              tableSearch={true}
              filtersVisibility={true}
              dateFilters={false}
            />
          </SecondaryBox>
        </Stack>
      </Stack>
    </Box>
  );
};
