import { Box, Divider, useTheme } from "@mui/material";
import { TestCase } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { GraphLoader } from "../../organisms/skeleton-loaders/GraphLoader";
import { ScreenSmallerThen } from "../../utilities/UIHelper";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

export const TestRunDetailsLoader = () => {
  const theme = useTheme();

  const testCaseColumnsVisibility: IColumnVisibility<TestCase>[] = [
    {
      field: "test_case_num",
      headerName: "Test Case",
      visible: true,
    },
    {
      field: "name",
      headerName: "Name",
      visible: true,
    },
    {
      field: "test_results",
      headerName: "Status",
      visible: true,
    },
    {
      field: "test_results",
      headerName: "Results",
      visible: true,
    },
  ];

  const fillBlankSpace = true;
  const isSmallScreen = ScreenSmallerThen("1100px");
  const bigFixedHeight = `calc(80vh - 56px)`;
  const bigFixedHeightSize = isSmallScreen ? "100%" : bigFixedHeight;
  return (
    <>
      <Box
        display="flex"
        width="100%"
        marginTop="15px"
        border="1px solid"
        borderBottom={0}
        borderColor={theme.palette.custom.secondaryBorder}
        bgcolor={theme.palette.custom.secondaryBackground}
        flexDirection="column"
        sx={{
          backgroundColor: "background.paper",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
        }}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          padding="10px"
          bgcolor={theme.palette.custom.secondaryBackground}
          sx={{ borderTopLeftRadius: "6px", borderTopRightRadius: "6px" }}
        >
          <RectangularLoader height="35px" width="225px" />
          <RectangularLoader height="35px" width="143px" />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={isSmallScreen ? "column" : "row"}
        sx={{ borderTopRightRadius: "6px", borderTopLeftRadius: "6px" }}
        flexWrap="wrap"
        paddingBottom="120px"
        gap={isSmallScreen ? "20px" : "0px"}
      >
        <Box
          display="flex"
          flexDirection="column"
          bgcolor={theme.palette.custom.secondaryBackground}
          border={"1px solid"}
          borderRight={isSmallScreen ? `1px solid ${theme.palette.custom.secondaryBorder}` : 0}
          width={isSmallScreen ? "100%" : "50%"}
          borderColor={theme.palette.custom.secondaryBorder}
          height={`calc(80vh - 56px)`}
          sx={
            isSmallScreen
              ? { borderBottomLeftRadius: "6px", borderBottomRightRadius: "6px" }
              : { borderBottomLeftRadius: "6px" }
          }
        >
          <GraphLoader
            title="Results Timeline"
            variant="rectangular"
            timeFilter={true}
            dateFilter={true}
            height="190px"
            width="501px"
            legend={true}
            legendHeight="48px"
            legendWidth="88px"
          />
          <Divider />
          <GraphLoader
            title="Overall Results"
            variant="circular"
            height="190px"
            width="190px"
            legend={true}
            legendHeight="68px"
            legendWidth="98px"
          />
        </Box>
        <Box width={isSmallScreen ? "100%" : "50%"} minHeight="100%" overflow="visible">
          <Box
            sx={{
              maxWidth: "100%",
              width: "100%",
              height: fillBlankSpace ? "100%" : "unset",
              overflow: "visible",
            }}
          >
            <Box height={bigFixedHeight ? bigFixedHeightSize : fillBlankSpace ? "100%" : "unset"}>
              <Box
                paddingBottom={"0px"}
                borderRadius="6px"
                border="1px solid"
                minHeight="100%"
                maxHeight="100%"
                overflow="auto"
                borderColor={theme.palette.custom.secondaryBorder}
                bgcolor={theme.palette.custom.secondaryBackground}
              >
                <APITableLoader
                  columnsVisibility={testCaseColumnsVisibility}
                  tableSize={"big"}
                  secondComponent={true}
                  tableSearch={true}
                  filtersVisibility={false}
                  dateFilters={false}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
