import { Box, Typography } from "@mui/material";
import { User } from "../../../models/types";
import { microsoft_rai_base_forms } from "../../../utilities/Questionnaires";
import { LoadingButton } from "@mui/lab";
import { SectionAssignment } from "./SectionAssignment";

type AssignUsersProps = {
  assignees: {
    ref: string;
    assignee: User | null;
  }[];
  setAssignees: (assginees: any) => void;
  onCreate: () => void;
  onBack: () => void;
};

export const AssignUsers = (props: AssignUsersProps) => {
  const { assignees, setAssignees, onCreate, onBack } = props;

  return (
    <Box display="flex" flexDirection="column" gap="10px" justifyContent="space-between">
      <Box display="flex" flexDirection="column" gap="10px" width="100%">
        <Typography variant="h1" textAlign="center">
          Assign Users
        </Typography>
        <Typography variant="body2" textAlign="center">
          There are missing users for specific roles in sections/questions in order to complete
          successfully the impact assessment.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap="10px" width="100%">
        {microsoft_rai_base_forms.map((section) => (
          <SectionAssignment section={section} assignees={assignees} setAssignees={setAssignees} />
        ))}
      </Box>
      <Box width="100%" display="flex" gap="10px" justifyContent="space-between">
        <LoadingButton onClick={() => onBack()}>Go back</LoadingButton>
        <LoadingButton variant="contained" onClick={() => onCreate()}>
          Create
        </LoadingButton>
      </Box>
    </Box>
  );
};
