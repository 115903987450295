import { MLFlowModelResponse } from "../models/types";
import { mlflowGetAuthenticated } from "./ApiService";

const mlflowEndpoints = {
  models: () => "/mlflow-static/ajax-api/2.0/mlflow/registered-models",
};

export const getMLFlowModels = (search: string) => {
  return mlflowGetAuthenticated<MLFlowModelResponse>(
    `${mlflowEndpoints.models()}/search?filter=name+ilike+%27%25${search}%25%27&max_results=25&order_by=name+ASC`
  );
};
