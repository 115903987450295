import { Box, Divider, useTheme } from "@mui/material";
import { TestRun } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { GraphLoader } from "../../organisms/skeleton-loaders/GraphLoader";
import { ScreenSmallerThen } from "../../utilities/UIHelper";

export const TestRunListPageLoader = () => {
  const theme = useTheme();

  const testRunColumnsVisibility: IColumnVisibility<TestRun>[] = [
    {
      field: "test_run_num",
      headerName: "Test Run",
      visible: true,
    },
    {
      field: "name",
      headerName: "Name",
      visible: true,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: false,
    },
    {
      field: "status",
      headerName: "Status",
      visible: true,
    },
    {
      field: "test_results",
      headerName: "Progress",
      visible: true,
    },
  ];

  const fillBlankSpace = true;
  const isSmallScreen = ScreenSmallerThen("1100px");
  const bigFixedHeight = `calc(80vh - 56px)`;
  const bigFixedHeightSize = isSmallScreen ? "100%" : bigFixedHeight;
  return (
    <Box
      display="flex"
      flexDirection={isSmallScreen ? "column" : "row"}
      sx={{ borderTopRightRadius: "6px", borderTopLeftRadius: "6px" }}
      flexWrap="wrap"
      paddingBottom="120px"
      gap={isSmallScreen ? "20px" : "0px"}
    >
      <Box
        display="flex"
        flexDirection="column"
        bgcolor={theme.palette.custom.secondaryBackground}
        border={"1px solid"}
        borderRight={isSmallScreen ? `1px solid ${theme.palette.custom.secondaryBorder}` : 0}
        width={isSmallScreen ? "100%" : "50%"}
        borderColor={theme.palette.custom.secondaryBorder}
        height={`calc(80vh - 56px)`}
        sx={
          isSmallScreen
            ? { borderBottomLeftRadius: "6px", borderBottomRightRadius: "6px" }
            : { borderBottomLeftRadius: "6px" }
        }
      >
        <GraphLoader
          title="Results Timeline"
          variant="rectangular"
          timeFilter={true}
          dateFilter={true}
          height="190px"
          width="501px"
          legend={true}
          legendHeight="48px"
          legendWidth="88px"
        />
        <Divider />
        <GraphLoader
          title="Overall Results"
          variant="circular"
          height="190px"
          width="190px"
          legend={true}
          legendHeight="68px"
          legendWidth="98px"
        />
      </Box>
      <Box width={isSmallScreen ? "100%" : "50%"} minHeight="100%" overflow="visible">
        <Box
          sx={{
            maxWidth: "100%",
            width: "100%",
            height: fillBlankSpace ? "100%" : "unset",
            overflow: "visible",
          }}
        >
          <Box height={bigFixedHeight ? bigFixedHeightSize : fillBlankSpace ? "100%" : "unset"}>
            <Box
              paddingBottom={"0px"}
              borderRadius="6px"
              border="1px solid"
              minHeight="100%"
              maxHeight="100%"
              overflow="auto"
              borderColor={theme.palette.custom.secondaryBorder}
              bgcolor={theme.palette.custom.secondaryBackground}
              sx={{ borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}
            >
              <APITableLoader
                columnsVisibility={testRunColumnsVisibility}
                tableSize={"big"}
                secondComponent={true}
                tableSearch={true}
                filtersVisibility={false}
                dateFilters={false}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
