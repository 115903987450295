import { getCookie, setCookie } from "../context/CookiesContext";
import queryClient from "../state/QueryStore";
import { ApiEndpoint, httpGetPublic, httpPostPublic } from "./ApiService";
import { shutdownIntercom } from "./IntercomService";

let _accessToken: string | null = null;
let _refreshToken: string | null = null;
let _userHash: string | null = null;
let _username: string | null = null;
let _2faToken: string | null = null;
let _2faMethod: "TOTP" | "SMS" | null = null;

interface IAuthTokens {
  accessToken: string;
  refreshToken: string;
  userHash: string;
}

/** username filled in login form */
const getUsername = (): string | null => {
  if (!_username) {
    _username = getCookie("_username");
  }

  return _username;
};
const setUsername = (username: string) => {
  _username = username;
  setCookie("_username", username);
};
const clearUsername = () => {
  _username = null;
  setCookie("_username", "");
};

// @TODO move tokens to cookies
const getAuthTokens = (): IAuthTokens | null => {
  if (!_accessToken || _refreshToken || _userHash) {
    // check cookies
    _accessToken = getCookie("_accessToken");
    _refreshToken = getCookie("_refreshToken");
    _userHash = getCookie("_userHash");
  }

  if (!_accessToken || !_refreshToken || !_userHash) {
    return null;
  }

  return {
    accessToken: _accessToken,
    refreshToken: _refreshToken,
    userHash: _userHash,
  };
};

const getUserHash = (): string | null => {
  return getCookie("_userHash");
};

const setAuthTokens = (tokens: IAuthTokens) => {
  setAccessToken(tokens.accessToken);
  setUserHash(tokens.userHash);
  _refreshToken = tokens.refreshToken;
  setCookie("_refreshToken", _refreshToken);
  clear2FaTokens();
};

const clear2FaTokens = () => {
  setCookie("_2faToken", "");
  setCookie("_2faMethod", "");
  _2faMethod = null;
  _2faToken = null;
};

const set2FaTokens = (data: { token: string; method: "TOTP" | "SMS" }) => {
  _2faMethod = data.method;
  _2faToken = data.token;
  setCookie("_2faToken", _2faToken);
  setCookie("_2faMethod", _2faMethod);
};

const setAccessToken = (token: string) => {
  _accessToken = token;
  setCookie("_accessToken", _accessToken);
};

const setUserHash = (userHash: string) => {
  _userHash = userHash;
  setCookie("_userHash", _userHash);
};

/**Refreshes JWT access token*/
const refreshAccessToken = () => {
  return httpPostPublic(ApiEndpoint.userAuthRefreshToken, {
    refresh: _refreshToken,
  })
    .then((tokenRefreshResponse) => {
      const newToken = tokenRefreshResponse.data.access;
      setAccessToken(newToken);
      return newToken;
    })
    .catch((error) => {
      window.location.href = "/login";
      return signOut();
    });
};

const fetchAuthTokens = async (
  stytch_token_type: string,
  authToken: string,
  twoFactorCode?: string
): Promise<{ token: string; method: string } | null> => {
  try {
    const params: any = {
      stytch_token_type,
      token: authToken,
    };
    if (twoFactorCode) {
      params.two_factor_code = twoFactorCode;
    }
    const resp = await httpGetPublic(ApiEndpoint.userAuthObtainToken, {
      params,
    });
    const { refresh, access, token, require_2fa, method, user_hash } = resp.data;
    if (require_2fa) {
      set2FaTokens({ token, method });
      return { token, method };
    } else {
      setAuthTokens({ accessToken: access, refreshToken: refresh, userHash: user_hash });
      return null;
    }
  } catch (err) {
    throw err;
  }
};

const signInWithEmail = async (email: string) => {
  const resp = await httpPostPublic(ApiEndpoint.userAuthObtainToken, {
    email: email.toLowerCase(),
  });
};

const signOut = async () => {
  setAuthTokens({
    accessToken: "",
    refreshToken: "",
    userHash: "",
  });
  clearUsername();
  shutdownIntercom();

  // reset state
  queryClient.clear();
};

export {
  getUsername,
  getAuthTokens,
  setAuthTokens,
  fetchAuthTokens,
  refreshAccessToken,
  setAccessToken,
  signInWithEmail,
  signOut,
  getUserHash,
  type IAuthTokens,
};
