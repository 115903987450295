import { Box } from "@mui/material";
import { NavbarIcon } from "../../../atoms/navbar/Icon";
import { SmallModal } from "../../../components/SmallModal";
import UserTag from "../../../components/UserTag";
import { useReports } from "../../../hooks/useReports";
import { BaseFilters, FilterOption, Report } from "../../../models/types";
import { IColumnVisibility } from "../../../molecules/ColumnVisibility";
import { EmptyTable } from "../../../molecules/EmptyTable";
import { TableTextWithEllipsis } from "../../../molecules/TableTextWithEllipsis";
import { defaultDateDisplay } from "../../../utilities/UIHelper";
import { APITable } from "../../APITable";

const FILTER_OPTIONS: FilterOption<BaseFilters>[] = [
  { label: "ID", value: "id" },
  { label: "Report", value: "report_num" },
];

export const ReportsModal = (props: { open: boolean; onClose: () => void }) => {
  const { open, onClose } = props;
  const columnsVisibility: IColumnVisibility<Report>[] = [
    {
      field: "report_num",
      headerName: "ID",
      visible: true,
      renderer: (report) => (
        <TableTextWithEllipsis
          onClick={() => window.open(report.url, "_blank")}
          value={`Report-${report.report_num}`}
        />
      ),
    },
    {
      field: "report_type",
      headerName: "Report Type",
      visible: true,
      renderer: (report) => <TableTextWithEllipsis value={report.report_type} />,
    },
    {
      field: "created_on",
      headerName: "Generated On",
      visible: true,
      renderer: (report) => (
        <TableTextWithEllipsis value={defaultDateDisplay(report.created_on) ?? ""} />
      ),
    },
    {
      field: "created_by",
      headerName: "Generated By",
      visible: true,
      renderer: (report) => <UserTag name={report.created_by.name} />,
    },
    {
      field: "url",
      headerName: "Download",
      visible: true,
      renderer: (report) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          onClick={() => window.open(report.url, "_blank")}
          sx={{ cursor: "pointer" }}
        >
          <NavbarIcon
            variant="download-logo"
            sx={{
              width: "20px",
              minWidth: "20px",
              height: "20px",
              minHeight: "20px",
            }}
          />
        </Box>
      ),
    },
  ];

  return (
    <SmallModal size={"large"} open={open} onClose={onClose} title="Reports">
      <APITable
        useGetData={useReports}
        columnsVisibility={columnsVisibility}
        queryParams={{}}
        smallEmptyHeight={true}
        filterOptions={FILTER_OPTIONS}
        emptyTableComponent={
          <EmptyTable
            variant="empty-box"
            label="No reports"
            description={`New reports will appear here.`}
          />
        }
        tableSize="small"
      />
    </SmallModal>
  );
};
