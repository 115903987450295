import { VersionHistory, VersionTag } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./ApiService";

const versionHistoryEndpoints = {
  versionHistory: () => "/version_history",
  versionTags: () => "/version_tag",
  versionTag: (versionTagId: string) => `/version_tag/${versionTagId}`,
};

export const getVersionHistory = async (params: any) => {
  return httpGetAuthenticated<ListResponse<VersionHistory>>(
    versionHistoryEndpoints.versionHistory(),
    {
      params,
    }
  );
};

export const createVersionHistoryWithTagID = async (payload: {
  object_id: string;
  object_type: string;
  object: any;
  tag_id: string;
  name: string;
  version: string;
}) => {
  const { data: versionTag } = await httpPostAuthenticated<VersionTag>(
    versionHistoryEndpoints.versionTags(),
    { tag_id: payload.tag_id, name: payload.name, version: payload.version }
  );
  const { data } = await httpPostAuthenticated<VersionHistory>(
    versionHistoryEndpoints.versionHistory(),
    {
      object_id: payload.object_id,
      object_type: payload.object_type,
      object: payload.object,
      tag: versionTag.id,
    }
  );
  queryClient.invalidateQueries({ queryKey: QueryKey.VersionHistory });
  return data;
};

export const getVersionTag = async (params: any) => {
  return httpGetAuthenticated<ListResponse<VersionTag>>(versionHistoryEndpoints.versionTags(), {
    params,
  });
};

export const editVersionTag = async (
  versionTagId: string,
  payload: {
    tag_id: string;
    name: string;
  }
) => {
  const { data } = await httpPatchAuthenticated(
    versionHistoryEndpoints.versionTag(versionTagId),
    payload
  );
  queryClient.invalidateQueries({ queryKey: QueryKey.VersionHistory });
  return data;
};
