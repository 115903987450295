import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Suspense, lazy, useEffect } from "react";
import { Container as ModalContainer } from "react-modal-promise";
import { QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { OrganizationProtectedRoutes } from "./components/OrganizationProtectedRoute";
import { SnackbarProvider } from "./context/SnackbarContext";
import { SubscriptionProvider } from "./context/SubscriptionContext";
import { AppThemeProvider } from "./context/ThemeContext";
import { ProvideAuth, useAuth } from "./hooks/useAuth";
import queryClient from "./state/QueryStore";

import "./App.css";
import { NavigatorProvider } from "./context/NavigatorContext";
import { OrganizationProvider } from "./context/OrganizationContext";
import { PaywallProvider } from "./context/PaywallContext";
import { ActionDetailsLoader } from "./layouts/skeleton-loaders/ActionDetailsLoader";
import { AssetDetailsLoader } from "./layouts/skeleton-loaders/AssetDetailsLoader";
import { CompliancePageLoader } from "./layouts/skeleton-loaders/CompliancePageLoader";
import { EvidenceDetailsLoader } from "./layouts/skeleton-loaders/EvidenceDetailsLoader";
import { ExecutionPlanDetailsLoader } from "./layouts/skeleton-loaders/ExecutionPlanDetailsLoader";
import { LibraryDetailsLoader } from "./layouts/skeleton-loaders/LibraryDetailsLoader";
import { ListViewLoader } from "./layouts/skeleton-loaders/ListViewLoader";
import { ListViewWithTabsLoader } from "./layouts/skeleton-loaders/ListViewWithTabsLoader";
import { MainAuthenticatedPageLoader } from "./layouts/skeleton-loaders/MainAuthenticatedPageLoader";
import { MetricDetailsLoader } from "./layouts/skeleton-loaders/MetricDetailsLoader";
import { NodeDetailsLoader } from "./layouts/skeleton-loaders/NodeDetailsLoader";
import { OrganizationPageLoader } from "./layouts/skeleton-loaders/OrganizationPageLoader";
import { OrganizationSettingsApiKeyLoader } from "./layouts/skeleton-loaders/OrganizationSettingsApiKeyLoader";
import { OrganizationSettingsBillingLoader } from "./layouts/skeleton-loaders/OrganizationSettingsBillingLoader";
import { OrganizationSettingsGeneralLoader } from "./layouts/skeleton-loaders/OrganizationSettingsGeneralLoader";
import { OrganizationSettingsLoader } from "./layouts/skeleton-loaders/OrganizationSettingsLoader";
import { OrganizationSettingsPlanLoader } from "./layouts/skeleton-loaders/OrganizationSettingsPlanLoader";
import { OrganizationUsersPageLoader } from "./layouts/skeleton-loaders/OrganizationUsersPageLoader";
import { ProcessDetailsLoader } from "./layouts/skeleton-loaders/ProcessDetailsLoader";
import { RegistryDetailsLoader } from "./layouts/skeleton-loaders/RegistryDetailsLoader";
import { RoleDetailsLoader } from "./layouts/skeleton-loaders/RoleDetailsLoader";
import { TestCaseDetailsLoader } from "./layouts/skeleton-loaders/TestCaseDetailsLoader";
import { TestPageLoader } from "./layouts/skeleton-loaders/TestPageLoader";
import { TestRunDetailsLoader } from "./layouts/skeleton-loaders/TestRunDetailsLoader";
import { TestRunListPageLoader } from "./layouts/skeleton-loaders/TestRunListPageLoader";
import { UserAccessKeysLoader } from "./layouts/skeleton-loaders/UserAccessKeysLoader";
import { UserPageLoader } from "./layouts/skeleton-loaders/UserPageLoader";
import { WorkflowDetailsLoader } from "./layouts/skeleton-loaders/WorkflowDetailsLoader";
import { FrameworksDetailLoader } from "./organisms/skeleton-loaders/FrameworksDetailLoader";
import { FrameworksListLoader } from "./organisms/skeleton-loaders/FrameworksListLoader";
import {
  ImpactAssessmentDetailLoader,
  ImpactAssessmentReviewLoader,
} from "./organisms/skeleton-loaders/ImpactAssessmentDetailLoader";
import { OrganizationOverviewLoader } from "./organisms/skeleton-loaders/OrganizationOverviewLoader";
import { PluginLoader } from "./organisms/skeleton-loaders/PluginLoader";
import { UserSettingsLoader } from "./organisms/skeleton-loaders/UserSettingsLoader";
import { Checkout } from "./pages/Checkout";
import JiraIntegrationPage from "./pages/JiraIntegrationPage";
import MainAuthenticatedPage from "./pages/MainAuthenticatedPage";
import { updateIntercomLoggedIn, updateIntercomLoggedOut } from "./services/IntercomService";

/** Page loading should be done lazy */

// Default header navigator
const PageNavigator = lazy(() => import("./components/layout/PageWithNavigatorLayout"));

// 404 Not found
const InvalidRoutePage = lazy(() => import("./pages/InvalidRoutePage"));

// Profile
const ProfilePage = lazy(() => import("./pages/profile/ProfilePage"));
const AccountSettingsPage = lazy(() => import("./pages/UserSettingsPage"));
const AccessKeysPage = lazy(() => import("./pages/profile/AccessKeysPage"));

// Auth
const LoginPage = lazy(() => import("./pages/LoginPage"));
const RegisterPage = lazy(() => import("./pages/RegisterPage"));
const AuthProtectedRoute = lazy(() => import("./components/AuthProtectedRoute"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const RegisterUserPage = lazy(() => import("./pages/RegisterUserPage"));

// Metrics
const MetricDetailPage = lazy(() => import("./pages/tests/MetricDetailPage"));
const MetricsListPage = lazy(() => import("./pages/tests/MetricsListPage"));

// Workflows
const WorkflowsListPage = lazy(() => import("./pages/WorkflowsListPage"));
const WorkflowPage = lazy(() => import("./pages/WorkflowPage"));
const WorkflowExecutionPlan = lazy(() => import("./pages/WorkflowEditExecutionPlan"));

// Process Graph
const ProcessGraphPage = lazy(() => import("./pages/ProcessGraphPage"));

// Process Node
const ProcessNodePage = lazy(() => import("./pages/ProcessNodePage"));

// Actions
const ActionPage = lazy(() => import("./pages/ActionPage"));
const ActionsListPage = lazy(() => import("./pages/ActionsListPage"));

// Assets
const AssetsListPage = lazy(() => import("./pages/AssetsListPage"));
const AssetPage = lazy(() => import("./pages/AssetPage"));

// Tests
const TestMainPage = lazy(() => import("./pages/tests/TestPage"));
const TestCasePage = lazy(() => import("./pages/tests/TestCasePage"));
const TestCasesListPage = lazy(() => import("./pages/tests/TestCasesListPage"));
const TestRunListPage = lazy(() => import("./pages/tests/TestRunListPage"));
const TestRunDetailPage = lazy(() => import("./pages/tests/TestRunDetailPage"));
const AlgorithmsPage = lazy(() => import("./pages/AlgorithmsPage"));
const DatasetsPage = lazy(() => import("./pages/DatasetsPage"));

// Compliances
const ComplianceMainPage = lazy(() => import("./pages/compliance/CompliancePage"));
const FrameworksDetailPage = lazy(() => import("./pages/compliance/FrameworksDetailPage"));
const FrameworksListPage = lazy(() => import("./pages/compliance/FrameworksListPage"));
const RegistryDetailPage = lazy(() => import("./pages/compliance/RegistryDetailPage"));
const RegistryItemDetailPage = lazy(() => import("./pages/compliance/RegistryItemDetailPage"));
const RegistryListPage = lazy(() => import("./pages/compliance/RegistryListPage"));
const LibraryDetailPage = lazy(() => import("./pages/compliance/LibraryDetailPage"));
const LibraryListPage = lazy(() => import("./pages/compliance/LibraryListPage"));
const EvidenceDetailPage = lazy(() => import("./pages/compliance/EvidenceDetailPage"));
const QuestionnairesListPage = lazy(() => import("./pages/compliance/QuestionnairesListPage"));
const QuestionnairesDetailPage = lazy(() => import("./pages/compliance/QuestionnairesDetailPage"));
const ImpactAssessmentListPage = lazy(() => import("./pages/compliance/ImpactAssessmentListPage"));
const ImpactAssessmentDetailPage = lazy(
  () => import("./pages/compliance/ImpactAssessmentDetailPage")
);
const ImpactAssessmentReviewPage = lazy(
  () => import("./pages/compliance/ImpactAssessmentReviewPage")
);
const ImpactAssessmentSectionReviewPage = lazy(
  () => import("./pages/compliance/ImpactAssessmentSectionReviewPage")
);

// Roles
const RolePage = lazy(() => import("./pages/roles/RolePage"));
const RoleDetailPage = lazy(() => import("./pages/roles/RoleDetailPage"));
const RolesListPage = lazy(() => import("./pages/roles/RolesListPage"));
const PolicyListPage = lazy(() => import("./pages/roles/PolicyListPage"));

// Resources
const ResourcesListPage = lazy(() => import("./pages/resources/ResourcesListPage"));
const ResourcePage = lazy(() => import("./pages/resources/ResourcePage"));

// Organization
const OrganizationPage = lazy(() => import("./pages/OrganizationPage"));
const VerticalOrgSettings = lazy(() => import("./components/VerticalOrgSettings"));
const GithubIntegrationPage = lazy(() => import("./pages/GithubIntegrationPage"));
const OrganizationOverview = lazy(
  () => import("./organisms/organization/overview/OrganizationOverview")
);
const OrganizationDashboardSelector = lazy(
  () => import("./organisms/organization/overview/DashboardSelector")
);
const UserDashboardSelector = lazy(
  () => import("./organisms/organization/users/UserDashboardSelector")
);
const OrganizationUsersView = lazy(() => import("./components/OrganizationUsersView"));
const OrganizationPlan = lazy(() => import("./components/Plan"));
const OrganizationBillingInformation = lazy(() => import("./components/BillingInformation"));
const OrganizationSettingsGeneral = lazy(() => import("./components/OrganizationSettingsGeneral"));
const OrganizationSettingsApiKey = lazy(() => import("./components/OrganizationSettingsApiKey"));
const OrganizationIntegrations = lazy(
  () => import("./organisms/organization/integrations/OrganizationIntegrations")
);

// Audit Logs
const AuditLogPage = lazy(() => import("./pages/AuditLogPage"));

// Version History
const VersionHistoryListPage = lazy(() => import("./pages/VersionHistoryListPage"));

// Demo
const DemoPage = lazy(() => import("./pages/Demo"));

function App() {
  return (
    <ProvideAuth>
      <AppRouter />
    </ProvideAuth>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const AppRouter = () => {
  const { tryGetUser, user: loggedInUser, organization } = useAuth();
  const isAdmin = loggedInUser?.permissions === "Admin" ? true : false;
  useEffect(() => {
    if (loggedInUser && organization) {
      updateIntercomLoggedIn(loggedInUser, organization);
    } else {
      updateIntercomLoggedOut();
    }
  }, [loggedInUser, organization]);

  useEffect(() => {
    // fetch basic info requried in every session
    // like user, organization
    tryGetUser();
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <QueryClientProvider client={queryClient}>
        <AppThemeProvider>
          <SnackbarProvider>
            <SubscriptionProvider>
              <NavigatorProvider>
                <PaywallProvider>
                  <OrganizationProvider>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <CssBaseline />
                      <Routes>
                        <Route
                          path="/"
                          element={
                            <AuthProtectedRoute>
                              <Suspense fallback={<MainAuthenticatedPageLoader />}>
                                <MainAuthenticatedPage />
                              </Suspense>
                            </AuthProtectedRoute>
                          }
                        >
                          <Route
                            path="demo-settings"
                            element={
                              <PageNavigator initialShowOnlyFirst={true} initialDisabled={true} />
                            }
                          >
                            <Route
                              path=""
                              element={
                                <Suspense fallback={<DemoPage />}>
                                  <DemoPage />
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route
                            path="organization"
                            element={
                              <PageNavigator initialShowOnlyFirst={true} initialDisabled={true} />
                            }
                          >
                            <Route
                              path=""
                              element={
                                <Suspense fallback={<OrganizationPageLoader />}>
                                  <OrganizationPage />
                                </Suspense>
                              }
                            >
                              <Route
                                index
                                element={
                                  <Suspense fallback={<OrganizationOverviewLoader />}>
                                    <OrganizationDashboardSelector />
                                  </Suspense>
                                }
                              />
                              <Route
                                path="overview"
                                element={
                                  <Suspense fallback={<OrganizationOverviewLoader />}>
                                    <OrganizationDashboardSelector />
                                  </Suspense>
                                }
                              />
                              <Route
                                path="users"
                                element={
                                  <Suspense fallback={<OrganizationUsersPageLoader />}>
                                    <UserDashboardSelector />
                                  </Suspense>
                                }
                              />
                              <Route
                                path="integrations"
                                element={
                                  <Suspense fallback={<PluginLoader />}>
                                    <OrganizationIntegrations />
                                  </Suspense>
                                }
                              />
                              {isAdmin ? (
                                <Route
                                  path="settings"
                                  element={
                                    <Suspense fallback={<OrganizationSettingsLoader />}>
                                      <VerticalOrgSettings />
                                    </Suspense>
                                  }
                                >
                                  <Route
                                    index
                                    element={
                                      <Suspense fallback={<OrganizationSettingsGeneralLoader />}>
                                        <OrganizationSettingsGeneral />
                                      </Suspense>
                                    }
                                  />
                                  <Route
                                    path="general"
                                    element={
                                      <Suspense fallback={<OrganizationSettingsGeneralLoader />}>
                                        <OrganizationSettingsGeneral />
                                      </Suspense>
                                    }
                                  />
                                  <Route
                                    path="api"
                                    element={
                                      <Suspense fallback={<OrganizationSettingsApiKeyLoader />}>
                                        <OrganizationSettingsApiKey />
                                      </Suspense>
                                    }
                                  />
                                  <Route
                                    path="billing"
                                    element={
                                      <Suspense fallback={<OrganizationSettingsBillingLoader />}>
                                        <OrganizationBillingInformation />
                                      </Suspense>
                                    }
                                  />
                                  <Route
                                    path="plan"
                                    element={
                                      <Suspense fallback={<OrganizationSettingsPlanLoader />}>
                                        <OrganizationPlan />
                                      </Suspense>
                                    }
                                  />
                                </Route>
                              ) : null}
                            </Route>
                          </Route>
                          <Route
                            path="organization/register_user"
                            element={
                              <Suspense>
                                <RegisterUserPage />
                              </Suspense>
                            }
                          />
                          <Route
                            element={
                              <Suspense fallback={<MainAuthenticatedPageLoader />}>
                                <OrganizationProtectedRoutes />
                              </Suspense>
                            }
                          >
                            <Route
                              path="workflows"
                              element={
                                <Suspense fallback={<MainAuthenticatedPageLoader />}>
                                  <PageNavigator initialShowEditing={false} />
                                </Suspense>
                              }
                            >
                              <Route
                                path=""
                                element={
                                  <Suspense fallback={<ListViewLoader type="workflow" />}>
                                    <WorkflowsListPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path=":clientWorkflowId"
                                element={
                                  <Suspense fallback={<WorkflowDetailsLoader />}>
                                    <WorkflowPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path=":clientWorkflowId/execution/:clientExecutionPlanId"
                                element={
                                  <Suspense fallback={<ExecutionPlanDetailsLoader />}>
                                    <WorkflowExecutionPlan />
                                  </Suspense>
                                }
                              />
                              <Route
                                path=":clientWorkflowId/:clientProcessId"
                                element={
                                  <Suspense fallback={<ProcessDetailsLoader />}>
                                    <ProcessGraphPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path=":clientWorkflowId/:clientProcessId/:clientNodeId"
                                element={
                                  <Suspense fallback={<NodeDetailsLoader />}>
                                    <ProcessNodePage />
                                  </Suspense>
                                }
                              />
                            </Route>
                            <Route
                              path="tests"
                              element={
                                <Suspense fallback={<MainAuthenticatedPageLoader />}>
                                  <PageNavigator initialShowEditing={false} />
                                </Suspense>
                              }
                            >
                              <Route
                                path=""
                                element={
                                  <Suspense fallback={<TestPageLoader />}>
                                    <TestMainPage />
                                  </Suspense>
                                }
                              >
                                <Route
                                  path="test-runs"
                                  element={
                                    <Suspense fallback={<TestRunListPageLoader />}>
                                      <TestRunListPage />
                                    </Suspense>
                                  }
                                />
                                <Route
                                  path="test-cases"
                                  element={
                                    <Suspense
                                      fallback={
                                        <ListViewLoader
                                          type="testcase"
                                          paddingTop={false}
                                          bigFixedHeight={`calc(80vh - 56px)`}
                                        />
                                      }
                                    >
                                      <TestCasesListPage />
                                    </Suspense>
                                  }
                                />
                                <Route
                                  path="metrics"
                                  element={
                                    <Suspense
                                      fallback={
                                        <ListViewLoader
                                          type="metric"
                                          paddingTop={false}
                                          bigFixedHeight={`calc(80vh - 56px)`}
                                        />
                                      }
                                    >
                                      <MetricsListPage />
                                    </Suspense>
                                  }
                                />
                              </Route>
                              <Route
                                path="test-runs/:testRunNumber"
                                element={
                                  <Suspense fallback={<TestRunDetailsLoader />}>
                                    <TestRunDetailPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path="test-cases/:testNumber"
                                element={
                                  <Suspense fallback={<TestCaseDetailsLoader />}>
                                    <TestCasePage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path="metrics/:metricNumber"
                                element={
                                  <Suspense fallback={<MetricDetailsLoader />}>
                                    <MetricDetailPage />
                                  </Suspense>
                                }
                              />
                            </Route>
                            <Route
                              path="oversight"
                              element={
                                <Suspense fallback={<MainAuthenticatedPageLoader />}>
                                  <PageNavigator initialShowEditing={false} />
                                </Suspense>
                              }
                            >
                              <Route
                                path=""
                                element={
                                  <Suspense fallback={<CompliancePageLoader />}>
                                    <ComplianceMainPage />
                                  </Suspense>
                                }
                              >
                                <Route
                                  path="frameworks"
                                  element={
                                    <Suspense fallback={<FrameworksListLoader />}>
                                      <FrameworksListPage />
                                    </Suspense>
                                  }
                                />
                                <Route
                                  path="registry"
                                  element={
                                    <Suspense
                                      fallback={
                                        <ListViewLoader
                                          type="registry"
                                          paddingTop={false}
                                          bigFixedHeight={`calc(80vh - 56px)`}
                                        />
                                      }
                                    >
                                      <RegistryListPage />
                                    </Suspense>
                                  }
                                />
                                <Route
                                  path="registry-item"
                                  element={
                                    <Suspense
                                      fallback={
                                        <ListViewLoader
                                          type="registry"
                                          paddingTop={false}
                                          bigFixedHeight={`calc(80vh - 56px)`}
                                        />
                                      }
                                    >
                                      <RegistryListPage />
                                    </Suspense>
                                  }
                                />
                                <Route
                                  path="library"
                                  element={
                                    <Suspense
                                      fallback={
                                        <ListViewLoader
                                          type="library"
                                          paddingTop={false}
                                          bigFixedHeight={`calc(80vh - 56px)`}
                                        />
                                      }
                                    >
                                      <LibraryListPage />
                                    </Suspense>
                                  }
                                />
                                <Route
                                  path="questionnaires"
                                  element={
                                    <Suspense
                                      fallback={
                                        <ListViewLoader
                                          type="questionnaire"
                                          paddingTop={false}
                                          bigFixedHeight={`calc(80vh - 56px)`}
                                        />
                                      }
                                    >
                                      <QuestionnairesListPage />
                                    </Suspense>
                                  }
                                />
                                <Route
                                  path="impact-assessment"
                                  element={
                                    <Suspense
                                      fallback={
                                        <ListViewLoader
                                          type="impact-assessment"
                                          paddingTop={false}
                                          bigFixedHeight={`calc(80vh - 56px)`}
                                        />
                                      }
                                    >
                                      <ImpactAssessmentListPage />
                                    </Suspense>
                                  }
                                />
                              </Route>
                              <Route
                                path="frameworks/:frameworkNumber"
                                element={
                                  <Suspense fallback={<FrameworksDetailLoader />}>
                                    <FrameworksDetailPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path="frameworks/fairo/:frameworkNumber"
                                element={
                                  <Suspense fallback={<FrameworksDetailLoader />}>
                                    <FrameworksDetailPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path="library/fairo/:libraryNumber"
                                element={
                                  <Suspense fallback={<LibraryDetailsLoader />}>
                                    <LibraryDetailPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path="library/:libraryNumber"
                                element={
                                  <Suspense fallback={<LibraryDetailsLoader />}>
                                    <LibraryDetailPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path="library/:elementExternalId/:evidenceNumber"
                                element={
                                  <Suspense fallback={<EvidenceDetailsLoader />}>
                                    <EvidenceDetailPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path="library/:elementExternalId/fairo/:evidenceNumber"
                                element={
                                  <Suspense fallback={<EvidenceDetailsLoader />}>
                                    <EvidenceDetailPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path="registry/:registryNumber"
                                element={
                                  <Suspense fallback={<RegistryDetailsLoader />}>
                                    <RegistryDetailPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path="registry-item/:registryNumber"
                                element={
                                  <Suspense fallback={<RegistryDetailsLoader />}>
                                    <RegistryItemDetailPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path="questionnaires/:questionnaireNumber"
                                element={
                                  <Suspense fallback={<RegistryDetailsLoader />}>
                                    <QuestionnairesDetailPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path="impact-assessment/:impactAssessmentNumber"
                                element={
                                  <Suspense fallback={<ImpactAssessmentDetailLoader />}>
                                    <ImpactAssessmentDetailPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path="impact-assessment/:impactAssessmentNumber/questionnaire/:questionnaireNumber/review"
                                element={
                                  <Suspense fallback={<ImpactAssessmentReviewLoader />}>
                                    <ImpactAssessmentReviewPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path="impact-assessment/:impactAssessmentNumber/section/:sectionNumber/review"
                                element={
                                  <Suspense fallback={<ImpactAssessmentReviewLoader />}>
                                    <ImpactAssessmentSectionReviewPage />
                                  </Suspense>
                                }
                              />
                            </Route>
                            <Route
                              path="algorithms"
                              element={
                                <Suspense fallback={<MainAuthenticatedPageLoader />}>
                                  <AlgorithmsPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="datasets"
                              element={
                                <Suspense fallback={<MainAuthenticatedPageLoader />}>
                                  <DatasetsPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="roles"
                              element={
                                <Suspense fallback={<MainAuthenticatedPageLoader />}>
                                  <PageNavigator initialShowEditing={false} />
                                </Suspense>
                              }
                            >
                              <Route
                                path=""
                                element={
                                  <Suspense
                                    fallback={
                                      <ListViewWithTabsLoader
                                        type="role"
                                        bigFixedHeight={`calc(80vh - 56px)`}
                                      />
                                    }
                                  >
                                    <RolePage />
                                  </Suspense>
                                }
                              >
                                <Route
                                  path=""
                                  element={
                                    <Suspense
                                      fallback={
                                        <ListViewLoader
                                          type="role"
                                          paddingTop={false}
                                          bigFixedHeight={`calc(80vh - 56px)`}
                                        />
                                      }
                                    >
                                      <RolesListPage />
                                    </Suspense>
                                  }
                                />
                                <Route
                                  path="policies"
                                  element={
                                    <Suspense
                                      fallback={
                                        <ListViewLoader
                                          type="policy"
                                          paddingTop={false}
                                          bigFixedHeight={`calc(80vh - 56px)`}
                                        />
                                      }
                                    >
                                      <PolicyListPage />
                                    </Suspense>
                                  }
                                />
                              </Route>
                              <Route
                                path=":roleNumber"
                                element={
                                  <Suspense fallback={<RoleDetailsLoader />}>
                                    <RoleDetailPage />
                                  </Suspense>
                                }
                              />
                            </Route>
                            <Route
                              path="actions"
                              element={
                                <Suspense fallback={<MainAuthenticatedPageLoader />}>
                                  <PageNavigator initialShowEditing={false} />
                                </Suspense>
                              }
                            >
                              <Route
                                path=""
                                element={
                                  <Suspense fallback={<ListViewLoader type="action" />}>
                                    <ActionsListPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path=":actionNumber"
                                element={
                                  <Suspense fallback={<ActionDetailsLoader />}>
                                    <ActionPage />
                                  </Suspense>
                                }
                              />
                            </Route>
                            <Route
                              path="assets"
                              element={
                                <Suspense fallback={<MainAuthenticatedPageLoader />}>
                                  <PageNavigator initialShowEditing={false} />
                                </Suspense>
                              }
                            >
                              <Route
                                path=""
                                element={
                                  <Suspense fallback={<ListViewLoader type="asset" />}>
                                    <AssetsListPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path=":assetNumber"
                                element={
                                  <Suspense fallback={<AssetDetailsLoader />}>
                                    <AssetPage />
                                  </Suspense>
                                }
                              />
                            </Route>
                            <Route
                              path="resources"
                              element={
                                <Suspense fallback={<MainAuthenticatedPageLoader />}>
                                  <PageNavigator initialShowEditing={false} />
                                </Suspense>
                              }
                            >
                              <Route
                                path=""
                                element={
                                  <Suspense fallback={<ListViewLoader type="resource" />}>
                                    <ResourcesListPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path=":resourceNumber"
                                element={
                                  <Suspense>
                                    <ResourcePage />
                                  </Suspense>
                                }
                              />
                            </Route>
                            <Route
                              path="user"
                              element={
                                <Suspense fallback={<MainAuthenticatedPageLoader />}>
                                  <PageNavigator initialDisabled={true} />
                                </Suspense>
                              }
                            >
                              <Route
                                path=""
                                element={
                                  <Suspense fallback={<UserPageLoader />}>
                                    <ProfilePage />
                                  </Suspense>
                                }
                              >
                                <Route
                                  path="settings"
                                  element={
                                    <Suspense fallback={<UserSettingsLoader />}>
                                      <AccountSettingsPage />
                                    </Suspense>
                                  }
                                />
                                <Route
                                  path="access-keys"
                                  element={
                                    <Suspense fallback={<UserAccessKeysLoader />}>
                                      <AccessKeysPage />
                                    </Suspense>
                                  }
                                />
                              </Route>
                            </Route>
                            <Route
                              path="audit-log"
                              element={
                                <Suspense fallback={<MainAuthenticatedPageLoader />}>
                                  <PageNavigator
                                    initialShowFirstLast={true}
                                    initialShowEditing={false}
                                  />
                                </Suspense>
                              }
                            >
                              <Route
                                path=""
                                element={
                                  <Suspense fallback={<ListViewLoader type="auditlog" />}>
                                    <AuditLogPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path=":objectType1"
                                element={
                                  <Suspense fallback={<ListViewLoader type="auditlog" />}>
                                    <AuditLogPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path=":objectType1/:objectId1"
                                element={
                                  <Suspense fallback={<ListViewLoader type="auditlog" />}>
                                    <AuditLogPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path=":objectType1/:objectId1/:objectType2"
                                element={
                                  <Suspense fallback={<ListViewLoader type="auditlog" />}>
                                    <AuditLogPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path=":objectType1/:objectId1/:objectType2/:objectId2"
                                element={
                                  <Suspense fallback={<ListViewLoader type="auditlog" />}>
                                    <AuditLogPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path=":objectType1/:objectId1/:objectType2/:objectId2/:objectType3"
                                element={
                                  <Suspense fallback={<ListViewLoader type="auditlog" />}>
                                    <AuditLogPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path=":objectType1/:objectId1/:objectType2/:objectId2/:objectType3/:objectId3"
                                element={
                                  <Suspense fallback={<ListViewLoader type="auditlog" />}>
                                    <AuditLogPage />
                                  </Suspense>
                                }
                              />
                            </Route>
                            <Route
                              path="version-history"
                              element={
                                <Suspense fallback={<MainAuthenticatedPageLoader />}>
                                  <PageNavigator
                                    initialShowFirstLast={true}
                                    initialShowEditing={false}
                                  />
                                </Suspense>
                              }
                            >
                              <Route
                                path=""
                                element={
                                  <Suspense fallback={<ListViewLoader type="version" />}>
                                    <VersionHistoryListPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path=":objectType1"
                                element={
                                  <Suspense fallback={<ListViewLoader type="version" />}>
                                    <VersionHistoryListPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path=":objectType1/:objectId1"
                                element={
                                  <Suspense fallback={<ListViewLoader type="version" />}>
                                    <VersionHistoryListPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path=":objectType1/:objectId1/:objectType2"
                                element={
                                  <Suspense fallback={<ListViewLoader type="version" />}>
                                    <VersionHistoryListPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path=":objectType1/:objectId1/:objectType2/:objectId2"
                                element={
                                  <Suspense fallback={<ListViewLoader type="version" />}>
                                    <VersionHistoryListPage />
                                  </Suspense>
                                }
                              />
                              <Route
                                path=":objectType1/:objectId1/:objectType2/:objectId2/:objectType3/:objectId3"
                                element={
                                  <Suspense fallback={<ListViewLoader type="version" />}>
                                    <VersionHistoryListPage />
                                  </Suspense>
                                }
                              />
                            </Route>
                          </Route>
                          <Route path="*" element={<InvalidRoutePage />} />
                        </Route>
                        <Route
                          path="/login"
                          element={
                            <Suspense>
                              <LoginPage />
                            </Suspense>
                          }
                        />
                        <Route
                          path="/register"
                          element={
                            <Suspense>
                              <RegisterPage />
                            </Suspense>
                          }
                        />
                        <Route
                          path="/forgotpassword"
                          element={
                            <Suspense>
                              <ForgotPassword />
                            </Suspense>
                          }
                        />
                        <Route
                          path="/subscribe/:status"
                          element={
                            <Suspense>
                              <Checkout />
                            </Suspense>
                          }
                        />
                        <Route
                          path="/integrations/github"
                          element={
                            <Suspense>
                              <GithubIntegrationPage />
                            </Suspense>
                          }
                        />
                        <Route
                          path="/integrations/jira"
                          element={
                            <Suspense>
                              <JiraIntegrationPage />
                            </Suspense>
                          }
                        />
                      </Routes>
                      <ModalContainer />
                    </LocalizationProvider>
                  </OrganizationProvider>
                </PaywallProvider>
              </NavigatorProvider>
            </SubscriptionProvider>
          </SnackbarProvider>
        </AppThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
