import { Box, Button } from "@mui/material";
import { SmallModal } from "../../components/SmallModal";
import { usePaywallContext } from "../../context/PaywallContext";
import Plan from "../../components/Plan";

const PaywallModal = () => {
  const { open, close, learnMoreCallback } = usePaywallContext();
  return (
    <SmallModal
      open={open}
      onClose={close}
      size="large"
      title="Contact us to become an enterprise customer"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <Plan minHeight="55vh" showTitle={false} />
      </Box>
    </SmallModal>
  );
};

export { PaywallModal };
