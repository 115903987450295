import { useQuery } from "react-query";
import { AssignedToMeFetchOptions, UserFetchOptions } from "../models/types";
import { assignedToMe, getUsers } from "../services/UserSettingsService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useUsers = (params: UserFetchOptions) => {
  return useQuery([QueryKey.User, params], async () => {
    const { data } = await getUsers(params);
    return data;
  });
};

export const fetchUsers = (params: UserFetchOptions, includeApiKeyUsers: boolean = false) => {
  // as default we don't fetch api key users, but if it's desired them params accept the prop and will overide the default false set here
  return queryClient.fetchQuery([QueryKey.User, params], async () => {
    const fetchParams = includeApiKeyUsers ? params : { api_key: false, ...params };
    const { data } = await getUsers(fetchParams);
    return data;
  });
};

export const searchUsers = async (searchText: string, includeApiKeyUsers: boolean = false) => {
  const { results } = await fetchUsers({ search: searchText }, includeApiKeyUsers);
  return results;
};

export const useAssignedToMe = (params: AssignedToMeFetchOptions) => {
  const paramsWithoutUserId = { ...params, userId: undefined };
  return useQuery([QueryKey.AssignedToMe, params], async () => {
    const { data } = await assignedToMe(paramsWithoutUserId);
    return data;
  });
};
