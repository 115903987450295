import { useQuery } from "react-query";
import {
  getImpactAssessmentType,
  getImpactAssessmentTypes,
} from "../services/ImpactAssessmentTypeService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useImpactAssessmentTypes = (params: any) => {
  return useQuery([QueryKey.ImpactAssessmentType, params], async () => {
    const { data } = await getImpactAssessmentTypes(params);
    return data;
  });
};

export const fetchImpactAssessmentTypes = (params: any) => {
  return queryClient.fetchQuery([QueryKey.ImpactAssessmentType, params], async () => {
    const { data } = await getImpactAssessmentTypes(params);
    return data;
  });
};

export const searchImpactAssessmentTypes = async (searchText: string) => {
  const types = await fetchImpactAssessmentTypes({ search: searchText, fairo_data: true });
  return types.results;
};

export const useImpactAssessmentType = (impactAssessmentTypeId: string) => {
  return useQuery([QueryKey.ImpactAssessmentType, impactAssessmentTypeId], async () => {
    const { data } = await getImpactAssessmentType(impactAssessmentTypeId);
    return data;
  });
};

export const fetchImpactAssessmentType = (impactAssessmentTypeId: string) => {
  return queryClient.fetchQuery(
    [QueryKey.ImpactAssessmentType, impactAssessmentTypeId],
    async () => {
      const { data } = await getImpactAssessmentType(impactAssessmentTypeId);
      return data;
    }
  );
};
