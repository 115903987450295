/* eslint-disable no-multi-str */
import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { NavbarIcon } from "../../atoms/navbar/Icon";
import { useEffect, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import { getQuestionnaires } from "../../services/QuestionnaireService";
import { getAnswers } from "../../services/QuestionnaireAnswerService";
import { getSemanticSimilarity } from "../../services/SemanticSimilarityAI";
import { SemanticSimiliarityItem } from "../../models/types";
import { uniqueTypeAndNumber } from "../../utilities/UIHelper";
import { LoadingButton } from "@mui/lab";
import { openSnackbar } from "../../context/SnackbarContext";

type AISuggestionProps = {
  impactAssessmentId: string;
  title: string;
  setShowSemanticSearchResult: () => void;
  answers: string[];
  showSemanticSearch: boolean;
};

export const AISuggestion = ({
  title,
  impactAssessmentId,
  setShowSemanticSearchResult,
  answers,
  showSemanticSearch,
}: AISuggestionProps) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [fetchingRegulations, setFetchingRegulations] = useState(false);
  const [text, setText] = useState("");
  const [expanded, setExpanded] = useState<boolean>(false);
  const [foundRelatedRegulations, setFoundRelatedRegulations] = useState<SemanticSimiliarityItem[]>(
    []
  );
  const [finished, setFinished] = useState<boolean>(false);
  const showAiSuggestion =
    title === "System description" ||
    title === "Purpose of the system" ||
    title === "How is the system currently deployed?" ||
    title === "Intended uses of the system" ||
    title === "SHOW_REGULATIONS";
  const getText = () => {
    switch (title) {
      case "System description":
        return "Your response here should help potential reviewers understand what, exactly, you’re building.\n \
                Describe what kind of AI capabilities the system has. Use simple language and be specific, avoiding vague concepts as much as possible.\n\n  \
                Write for an audience that has a basic understanding of AI systems but no understanding of this specific system.\n\n \
                Example Prompts:\n\n \
                • What are you building?\n \
                • What does it do?\n \
                • How does it work?";
      case "Purpose of the system":
        return "This section should help potential reviewers understand the specific features (capabilities) of the system and how the system being evaluated in this impact assessment relates to existing systems or features. \n\n\
                In the section, please describe the systems features and capabilities overall, not the features of specific models that the system may use.\n\n\
                FAQs\n\n\
                What do ‘system features’ mean in this context?\n\n\
                System features are the functionalities or capabilities you use within a system to complete a set of tasks or actions.\n\n\
                What if there are no existing features because the system is completely new?\n\n\
                Do not complete ‘existing features’ section.";
      case "How is the system currently deployed?":
        return "This section should help potential reviewers understand how the system will be deployed to users or customers.\n\n\
                Some possible responses could include:\n\n\
                • Online Service\n\
                • Platform Service\n\
                • Code\n\
                • On Premises\n\
                • Container";
      case "Intended uses of the system":
        return "Suggestions for filling out Intended Uses Section\n\n\
                1) Brainstorm possible uses Start by listing as many potential ways someone could use the system, no matter how outlandish they seem at first.\n\n\
                2) Categorize possible uses: Intended, unsupported or misuse In the Impact Assessment we ask about several different types of uses. For each possible use you listed in step one, determine if the use is an intended use, unsupported use, or misuse of the system.\n\n\
                3) Check if any uses are also Sensitive Uses or Restricted Uses Check all uses against the definitions for Restricted Uses and Sensitive Uses.\n\n\
                Follow the guidance for any Restricted Uses.";
      case "SHOW_REGULATIONS":
        return text;
      default:
        return "";
    }
  };

  const [showPerformSemanticSearch, setShowPerformSemanticSearch] = useState<boolean>(false);
  const [showActionButtons, setShowActionButton] = useState<boolean>(false);
  const [confirmSemanticSearch, setConfirmSemanticSearch] = useState<boolean>(false);
  const [regulations, setRegulations] = useState<SemanticSimiliarityItem[]>([]);
  const [loadingRegulations, setLoadingRegulations] = useState<boolean>(false);
  const [showRegulations, setShowRegulations] = useState(false);
  const [showFullResults, setShowFullResults] = useState<boolean>(false);

  const handleRegulationsOptions = async () => {
    try {
      setFetchingRegulations(true);
      const { data } = await getQuestionnaires({
        "metadata_section[]": [1],
        impact_assessment: impactAssessmentId,
      });
      let related_regulations: SemanticSimiliarityItem[] = [];
      await Promise.all(
        data?.results?.map(async (questionnaire) => {
          if (
            questionnaire.name === "Microsoft RAI Impact Assessment: Section 1.8 - Intended Uses"
          ) {
            const { data: answers } = await getAnswers({
              questionnaire: questionnaire.id,
              status: "Final",
            });
            if (answers?.results?.length > 0) {
              const answer = JSON.parse(answers.results[0].answer);
              if (Array.isArray(answer)) {
                await Promise.all(
                  answer.map(async (ans) => {
                    const parsedAnswer = JSON.parse(ans);
                    const response = await getSemanticSimilarity(
                      parsedAnswer.name,
                      parsedAnswer.description
                    );
                    related_regulations.push(...response);
                  })
                );
              } else {
                const parsedAnswer = JSON.parse(answer);
                const response = await getSemanticSimilarity(
                  parsedAnswer.name,
                  parsedAnswer.description
                );
                related_regulations.push(...response);
              }
            }
            const typePriority: { [type: string]: number } = {
              article: 1, // Highest priority
              recital: 2,
              annex: 3, // Lowest priority
            };
            const sorttedItems = related_regulations.sort((a, b) => {
              const typeComparison = typePriority[a.type] - typePriority[b.type];
              if (typeComparison === 0) {
                // If types are the same, sort by number
                return a.number - b.number;
              }
              return typeComparison;
            });
            const uniqueItems = uniqueTypeAndNumber(sorttedItems);
            setFoundRelatedRegulations(uniqueItems);
            const countArticles = uniqueItems.filter((item) => item.type === "article").length;
            const countRecitals = uniqueItems.filter((item) => item.type === "recital").length;
            const countAnnex = uniqueItems.filter((item) => item.type === "annex").length;

            setText(`Semantic search of EU AI Act returned:\n\n\
                  ${countArticles} Articles\n\
                  ${countRecitals} Recitals\n\
                  ${countAnnex} Appendices`);
          }
        })
      );
      return "No intended uses found... Please complete Section 1 of the questionnaire...";
    } catch (error) {
      return "Failed to fetch data...";
    } finally {
      setLoading(false);
      setFetchingRegulations(false);
    }
  };

  const handleMatchRegulations = async () => {
    setConfirmSemanticSearch(true);
    try {
      setLoadingRegulations(true);
      let related_regulations: SemanticSimiliarityItem[] = [];
      await Promise.all(
        answers.map(async (answer) => {
          if (Array.isArray(answer)) {
            await Promise.all(
              answer.map(async (ans) => {
                const parsedAnswer = JSON.parse(ans);
                const response = await getSemanticSimilarity(
                  parsedAnswer.name,
                  parsedAnswer.description
                );
                related_regulations.push(...response);
              })
            );
          } else {
            const parsedAnswer = JSON.parse(answer);
            const response = await getSemanticSimilarity(
              parsedAnswer.name,
              parsedAnswer.description
            );
            related_regulations.push(...response);
          }
        })
      );

      const typePriority: { [type: string]: number } = {
        article: 1, // Highest priority
        recital: 2,
        annex: 3, // Lowest priority
      };
      const sorttedItems = related_regulations.sort((a, b) => {
        const typeComparison = typePriority[a.type] - typePriority[b.type];
        if (typeComparison === 0) {
          // If types are the same, sort by number
          return a.number - b.number;
        }
        return typeComparison;
      });
      setRegulations(uniqueTypeAndNumber(sorttedItems));
      setShowRegulations(true);
    } catch (e) {
      console.log(e);
      openSnackbar("Failed to fetch regulations", "error");
    } finally {
      setLoadingRegulations(false);
    }
  };

  const handleExpanded = (val: boolean) => {
    setExpanded(val);
    if (!val) {
      setFinished(true);
    }
  };

  useEffect(() => {
    if (expanded && !finished) {
      setTimeout(() => {
        setLoading(false);
      }, 3500);
    }
  }, [expanded]);

  useEffect(() => {
    if (title === "SHOW_REGULATIONS") {
      handleRegulationsOptions();
    }
  }, [title]);

  return showAiSuggestion ? (
    <Box display="flex" flexDirection="column" gap="10px">
      <Box
        sx={{
          transition: "height 0.2s ease",
        }}
        display="flex"
        width="100%"
        padding="10px"
        flexDirection="column"
        gap="10px"
        bgcolor={theme.palette.custom.cardBackground}
        borderRadius="3px"
        border={`1px solid ${theme.palette.custom.secondaryBorder}`}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          gap="10px"
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="10px"
            alignItems="center"
          >
            <NavbarIcon
              variant="fairo-ai"
              sx={{
                width: "24px",
                height: "24px",
                minHeight: "24px",
                minWidth: "24px",
              }}
            />
            <Typography variant="h4" color={theme.palette.custom.blueTypography}>
              Fairo AI Suggestion
            </Typography>
          </Box>
          <Box
            sx={{ cursor: "pointer" }}
            display="flex"
            alignItems="center"
            onClick={() => handleExpanded(!expanded)}
          >
            <NavbarIcon
              variant={expanded ? "chevron-up" : "chevron-down"}
              selected={true}
              sx={{
                width: "16px",
                height: "16px",
                minHeight: "16px",
                minWidth: "16px",
              }}
            />
          </Box>
        </Box>
        {expanded && (
          <Box
            overflow="hidden"
            sx={{
              transition: "height 2s ease",
            }}
          >
            {(loading || fetchingRegulations) && <AILoading />}
            {!loading && !fetchingRegulations && (
              <Typography variant="body2" whiteSpace="pre-line">
                <TypeAnimation
                  sequence={[getText(), 100, () => setShowPerformSemanticSearch(true)]}
                  wrapper="div"
                  cursor={false}
                  repeat={0}
                  speed={90}
                  preRenderFirstString={finished}
                />
              </Typography>
            )}
          </Box>
        )}
      </Box>
      {showSemanticSearch && showPerformSemanticSearch && (
        <Box
          sx={{
            transition: "height 0.2s ease",
          }}
          display="flex"
          width="100%"
          padding="10px"
          flexDirection="column"
          gap="10px"
          bgcolor="#FAFAFA"
          borderRadius="3px"
          border={`1px solid ${theme.palette.custom.secondaryBorder}`}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="10px"
            alignItems="center"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap="10px"
              alignItems="center"
            >
              <NavbarIcon
                variant="fairo-ai"
                sx={{
                  width: "24px",
                  height: "24px",
                  minHeight: "24px",
                  minWidth: "24px",
                }}
              />
              <Typography variant="h4" color={theme.palette.custom.blueTypography}>
                <TypeAnimation
                  sequence={[
                    "Perform EU AI Act Semantic Similarity Search?",
                    100,
                    () => setShowActionButton(true),
                  ]}
                  wrapper="div"
                  cursor={false}
                  repeat={0}
                  speed={90}
                />
              </Typography>
            </Box>
            {showActionButtons && (
              <Box display="flex" alignItems="center">
                <LoadingButton variant="text" onClick={() => setShowPerformSemanticSearch(false)}>
                  No
                </LoadingButton>
                <LoadingButton variant="contained" onClick={() => handleMatchRegulations()}>
                  Yes
                </LoadingButton>
              </Box>
            )}
          </Box>
          {confirmSemanticSearch && (
            <Box
              overflow="hidden"
              sx={{
                transition: "height 2s ease",
              }}
            >
              {(loadingRegulations || !showRegulations) && <AILoading />}
              {!loadingRegulations && regulations && (
                <Typography variant="body2" whiteSpace="pre-line">
                  <TypeAnimation
                    sequence={[
                      `Discovered ${
                        regulations.filter((item) => item.type === "article").length
                      } Articles, ${
                        regulations.filter((item) => item.type === "recital").length
                      } Recitals, and ${
                        regulations.filter((item) => item.type === "annex").length
                      } Annexes that appear to be related to your intended use descriptions.`,
                      100,
                      () => setShowFullResults(true),
                    ]}
                    wrapper="div"
                    cursor={false}
                    repeat={0}
                    speed={90}
                  />
                </Typography>
              )}
              {showFullResults && (
                <LoadingButton
                  variant="text"
                  onClick={() => setShowSemanticSearchResult()}
                  sx={{
                    paddingX: "0px",
                  }}
                >
                  See Full Results
                </LoadingButton>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  ) : null;
};

export const AILoading = () => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="row" gap="2px">
      <Skeleton
        variant="circular"
        width="6px"
        height="6px"
        sx={{
          backgroundColor: `${theme.palette.custom.blueTypography}70`,
        }}
      />
      <Skeleton
        variant="circular"
        width="6px"
        height="6px"
        sx={{
          backgroundColor: `${theme.palette.custom.blueTypography}70`,
        }}
      />
      <Skeleton
        variant="circular"
        width="6px"
        height="6px"
        sx={{
          backgroundColor: `${theme.palette.custom.blueTypography}70`,
        }}
      />
    </Box>
  );
};
