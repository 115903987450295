import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { ReactComponent as EvidenceCompletedIcon } from "../../assets/complianceIcons/completed-icon.svg";
import { ReactComponent as CustomRegistryDark } from "../../assets/complianceIcons/custom-registry-dark.svg";
import { ReactComponent as CustomRegistry } from "../../assets/complianceIcons/custom-registry.svg";
import { ReactComponent as EuaiLogo } from "../../assets/complianceIcons/eu-ai-act-logo.svg";
import { ReactComponent as HITRUSTDark } from "../../assets/complianceIcons/hitrust-dark.svg";
import { ReactComponent as HITRUSTLight } from "../../assets/complianceIcons/hitrust-light.svg";
import { ReactComponent as IsoLogoDark } from "../../assets/complianceIcons/iso-logo-dark.svg";
import { ReactComponent as IsoLogo } from "../../assets/complianceIcons/iso-logo.svg";
import { ReactComponent as NistLogoDark } from "../../assets/complianceIcons/nist-logo-dark.svg";
import { ReactComponent as NistLogo } from "../../assets/complianceIcons/nist-logo.svg";
import { ReactComponent as EvidenceOverdueIcon } from "../../assets/complianceIcons/overdue-icon.svg";
import { ReactComponent as EvidencePendingIcon } from "../../assets/complianceIcons/pending-icon.svg";
import { ReactComponent as UseCaseRegistryDark } from "../../assets/complianceIcons/use-case-registry-dark.svg";
import { ReactComponent as UseCaseRegistry } from "../../assets/complianceIcons/use-case-registry.svg";
import { ReactComponent as VendorRegistryDark } from "../../assets/complianceIcons/vendor-registry-dark.svg";
import { ReactComponent as VendorRegistry } from "../../assets/complianceIcons/vendor-registry.svg";
import { ReactComponent as AddIcon } from "../../assets/general/add-icon.svg";
import { ReactComponent as AuditLogIcon } from "../../assets/general/audit-log.svg";
import { ReactComponent as CalendarDarkIcon } from "../../assets/general/calendar-dark.svg";
import { ReactComponent as CalendarLightIcon } from "../../assets/general/calendar-light.svg";
import { ReactComponent as CheckIcon } from "../../assets/general/check.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/general/chevron-down.svg";
import { ReactComponent as ChevronUpIcon } from "../../assets/general/chevron-up.svg";
import { ReactComponent as CompletedIcon } from "../../assets/general/completed.svg";
import { ReactComponent as CopyIcon } from "../../assets/general/copy.svg";
import { ReactComponent as CreatedByFairoIcon } from "../../assets/general/created-by-fairo.svg";
import { ReactComponent as DialogCloseDarkIcon } from "../../assets/general/dialog-close-icon-dark.svg";
import { ReactComponent as DialogCloseLightIcon } from "../../assets/general/dialog-close-icon-light.svg";
import { ReactComponent as DownloadDarkIcon } from "../../assets/general/download-icon-dark.svg";
import { ReactComponent as DownloadIcon } from "../../assets/general/download-icon.svg";
import { ReactComponent as EditDarkIcon } from "../../assets/general/edit-icon-dark.svg";
import { ReactComponent as EditLightIcon } from "../../assets/general/edit-icon-light.svg";
import { ReactComponent as EllipseDarkIcon } from "../../assets/general/ellipse-icon-dark.svg";
import { ReactComponent as EllipseLightIcon } from "../../assets/general/ellipse-icon-light.svg";
import { ReactComponent as EmptyImageIcon } from "../../assets/general/empty-image.svg";
import { ReactComponent as FairoAIIcon } from "../../assets/general/fairo-ai.svg";
import { ReactComponent as FilePlus } from "../../assets/general/file-plus.svg";
import { ReactComponent as FolderPlus } from "../../assets/general/folder-plus.svg";
import { ReactComponent as GoogleIcon } from "../../assets/general/google-icon.svg";
import { ReactComponent as HideDarkIcon } from "../../assets/general/hide-icon-dark.svg";
import { ReactComponent as HideLightIcon } from "../../assets/general/hide-icon-light.svg";
import { ReactComponent as MetricsLightIcon } from "../../assets/general/metrics-empty.svg";
import { ReactComponent as MetricsIcon } from "../../assets/general/metrics.svg";
import { ReactComponent as MicrosoftIcon } from "../../assets/general/microsoft-icon.svg";
import { ReactComponent as MoreInfoIcon } from "../../assets/general/more-info.svg";
import { ReactComponent as PotentialRisksIcon } from "../../assets/general/potential-risks.svg";
import { ReactComponent as ReadonlyIcon } from "../../assets/general/readonly.svg";
import { ReactComponent as RegulationsIcon } from "../../assets/general/regulations.svg";
import { ReactComponent as ReportsGenerateIcon } from "../../assets/general/reports-generate.svg";
import { ReactComponent as ReportsListIcon } from "../../assets/general/reports-list.svg";
import { ReactComponent as ShowDarkIcon } from "../../assets/general/show-icon-dark.svg";
import { ReactComponent as ShowLightIcon } from "../../assets/general/show-icon-light.svg";
import { ReactComponent as SuggestedActionsIcon } from "../../assets/general/suggested-actions.svg";
import { ReactComponent as TooltipIcon } from "../../assets/general/tooltip.svg";
import { ReactComponent as UnlockDarkIcon } from "../../assets/general/unlock-dark.svg";
import { ReactComponent as UnlockLightIcon } from "../../assets/general/unlock-light.svg";
import { ReactComponent as VersionHistoryIcon } from "../../assets/general/version-history.svg";
import { ReactComponent as WelcomeToFairoIcon } from "../../assets/general/welcome-to-fairo.svg";
import { ReactComponent as CanadianAlgorithmicIcon } from "../../assets/impactAssessment/canadian-algorithmic.svg";
import { ReactComponent as EqualAIAlgorithmicIcon } from "../../assets/impactAssessment/equal-ai-algorithmic.svg";
import { ReactComponent as ImpactAssessmentLoadingIcon } from "../../assets/impactAssessment/loading.svg";
import { ReactComponent as MicrosoftRaiIcon } from "../../assets/impactAssessment/microsoft-rai.svg";
import { ReactComponent as OCEDAlgorithmicIcon } from "../../assets/impactAssessment/oced-algorithmic.svg";
import { ReactComponent as USCIOCouncilAlgorithmicIcon } from "../../assets/impactAssessment/us-cio-council-algorithmic.svg";
import { ReactComponent as FairoLogoDark } from "../../assets/logo/fairo-logo-dark.svg";
import { ReactComponent as FairoLogoLight } from "../../assets/logo/fairo-logo-light.svg";
import { ReactComponent as ActionIcon } from "../../assets/navbarIcons/action.svg";
import { ReactComponent as AssetIcon } from "../../assets/navbarIcons/asset.svg";
import { ReactComponent as ComplianceIcon } from "../../assets/navbarIcons/compliance.svg";
import { ReactComponent as DownArrow } from "../../assets/navbarIcons/down-arrow.svg";
import { ReactComponent as EmptyBoxIcon } from "../../assets/navbarIcons/empty-box.svg";
import { ReactComponent as LeftArrow } from "../../assets/navbarIcons/left-arrow.svg";
import { ReactComponent as LockIcon } from "../../assets/navbarIcons/lock.svg";
import { ReactComponent as MLFlowActive } from "../../assets/navbarIcons/mlflow-active.svg";
import { ReactComponent as MLFlowDisabled } from "../../assets/navbarIcons/mlflow-disable.svg";
import { ReactComponent as NoVisibleIcon } from "../../assets/navbarIcons/no-visible.svg";
import { ReactComponent as NotStartedIcon } from "../../assets/navbarIcons/not-started.svg";
import { ReactComponent as NotificationAlertIcon } from "../../assets/navbarIcons/notification-with-alert.svg";
import { ReactComponent as NotificationIcon } from "../../assets/navbarIcons/notification.svg";
import { ReactComponent as OrganizationIcon } from "../../assets/navbarIcons/organization.svg";
import { ReactComponent as PolicySmIcon } from "../../assets/navbarIcons/policy-sm.svg";
import { ReactComponent as PolicyIcon } from "../../assets/navbarIcons/policy.svg";
import { ReactComponent as QuestionnaireIcon } from "../../assets/navbarIcons/questionnaire.svg";
import { ReactComponent as ResourcesIcon } from "../../assets/navbarIcons/resources.svg";
import { ReactComponent as RightArrow } from "../../assets/navbarIcons/right-arrow.svg";
import { ReactComponent as RoleIcon } from "../../assets/navbarIcons/role.svg";
import { ReactComponent as SignoutIcon } from "../../assets/navbarIcons/signout.svg";
import { ReactComponent as TestIcon } from "../../assets/navbarIcons/test.svg";
import { ReactComponent as DarkThemeIcon } from "../../assets/navbarIcons/theme-dark.svg";
import { ReactComponent as LightThemeIcon } from "../../assets/navbarIcons/theme-light.svg";
import { ReactComponent as TopArrow } from "../../assets/navbarIcons/top-arrow.svg";
import { ReactComponent as TrashCanIcon } from "../../assets/navbarIcons/trash-can.svg";
import { ReactComponent as UserDarkIcon } from "../../assets/navbarIcons/user-dark.svg";
import { ReactComponent as UserLightIcon } from "../../assets/navbarIcons/user-light.svg";
import { ReactComponent as WorkflowIcon } from "../../assets/navbarIcons/workflow.svg";
import { ReactComponent as AssestsPaywallIcon } from "../../assets/paywall/assets.svg";
import { ReactComponent as CompliancePaywallIcon } from "../../assets/paywall/compliance.svg";
import { ReactComponent as MlflowPaywallIcon } from "../../assets/paywall/mlflow.svg";
import { ReactComponent as PoliciesPaywallIcon } from "../../assets/paywall/policies.svg";
import { ReactComponent as ResourcesPaywallIcon } from "../../assets/paywall/resources.svg";
import { ReactComponent as TestsPaywallIcon } from "../../assets/paywall/tests.svg";
import { ReactComponent as WorkflowsPaywallIcon } from "../../assets/paywall/workflows.svg";
import { ReactComponent as AwsDarkIcon } from "../../assets/pluginIcons/aws-dark.svg";
import { ReactComponent as AwsLightIcon } from "../../assets/pluginIcons/aws-light.svg";
import { ReactComponent as AzureDarkIcon } from "../../assets/pluginIcons/azure-dark.svg";
import { ReactComponent as AzureLightIcon } from "../../assets/pluginIcons/azure-light.svg";
import { ReactComponent as ConfluenceDarkIcon } from "../../assets/pluginIcons/confluence-dark.svg";
import { ReactComponent as ConfluenceLightIcon } from "../../assets/pluginIcons/confluence-light.svg";
import { ReactComponent as DropboxDarkIcon } from "../../assets/pluginIcons/dropbox-dark.svg";
import { ReactComponent as DropboxLightIcon } from "../../assets/pluginIcons/dropbox-light.svg";
import { ReactComponent as GithubDarkIcon } from "../../assets/pluginIcons/github-dark.svg";
import { ReactComponent as GithubLightIcon } from "../../assets/pluginIcons/github-light.svg";
import { ReactComponent as HuggingFaceDarkIcon } from "../../assets/pluginIcons/hugging-face-dark.svg";
import { ReactComponent as HuggingFaceLightIcon } from "../../assets/pluginIcons/hugging-face-light.svg";
import { ReactComponent as JiraDarkIcon } from "../../assets/pluginIcons/jira-dark.svg";
import { ReactComponent as JiraLightIcon } from "../../assets/pluginIcons/jira-light.svg";
import { ReactComponent as OpenAIDarkIcon } from "../../assets/pluginIcons/open-ai-dark.svg";
import { ReactComponent as OpenAILightIcon } from "../../assets/pluginIcons/open-ai-light.svg";
import { ReactComponent as SharePointDarkIcon } from "../../assets/pluginIcons/sharepoint-dark.svg";
import { ReactComponent as SharePointLightIcon } from "../../assets/pluginIcons/sharepoint-light.svg";
import { ReactComponent as SlackDarkIcon } from "../../assets/pluginIcons/slack-dark.svg";
import { ReactComponent as SlackLightIcon } from "../../assets/pluginIcons/slack-light.svg";

import CloseIcon from "@mui/icons-material/CloseRounded";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Theme, useTheme } from "@mui/material";
import { useSubscription } from "../../context/SubscriptionContext";
import {
  DEFAULT_ICON_HEIGHT,
  DEFAULT_ICON_WIDTH,
  DEFAULT_INTERNAL_BORDER_RADIUS,
} from "../../utilities/UIHelper";

export type INavbarIconVariant =
  | "organization"
  | "workflow"
  | "test"
  | "asset"
  | "role"
  | "action"
  | "user"
  | "notification"
  | "notification_alert"
  | "light-theme"
  | "dark-theme"
  | "signout"
  | "right-arrow"
  | "left-arrow"
  | "menu"
  | "close"
  | "fairo-logo"
  | "download-logo"
  | "download-dark-logo"
  | "no-visible"
  | "show"
  | "hide"
  | "ellipse"
  | "dialog-close"
  | "add-icon"
  | "edit-icon"
  | "calendar"
  | "chevron-down"
  | "edit-icon"
  | "tooltip"
  | "metrics"
  | "metrics-empty"
  | "created-by-fairo"
  | "compliance"
  | "evidence-pending"
  | "evidence-overdue"
  | "evidence-completed"
  | "google-icon"
  | "microsoft-icon"
  | "mlflow"
  | "policy"
  | "policy-sm"
  | "lock"
  | "audit-log"
  | "version-history"
  | "copy"
  | "aws"
  | "azure"
  | "confluence"
  | "dropbox"
  | "github"
  | "hugging-face"
  | "jira"
  | "open-ai"
  | "sharepoint"
  | "slack"
  | "unlock"
  | "readonly"
  | "empty-box"
  | "trash-can"
  | "chevron-up"
  | "resources"
  | "check"
  | "more-info"
  | "folder-plus"
  | "file-plus"
  | "questionnaire"
  | "empty-image"
  | "radio-checked"
  | "radio-unchecked"
  | "reports-list"
  | "reports-generate"
  | "welcome-to-fairo"
  | "completed"
  | "assets-paywall"
  | "compliance-paywall"
  | "mlflow-paywall"
  | "policies-paywall"
  | "resources-paywall"
  | "tests-paywall"
  | "workflows-paywall"
  | "impact-assessment-loading"
  | "top-arrow"
  | "down-arrow"
  | "not-started"
  | "fairo-ai"
  | "potential-risks"
  | "regulations"
  | "suggested-actions"
  | "nist-logo"
  | "iso-logo"
  | "euai-logo"
  | "hitrust-logo"
  | "vendor-registry"
  | "use-case-registry"
  | "custom-registry"
  | "canadian-algorithmic"
  | "equal-ai-algorithmic"
  | "microsoft-rai"
  | "oced-algorithmic"
  | "us-cio-coucil-algorithmic";

interface INavbarIcons {
  variant: INavbarIconVariant;
  selected?: boolean;
  color?: string;
  backgroundColor?: string;
  strokeWidth?: string;
  sx?: {
    minHeight?: string;
    minWidth?: string;
    width?: string;
    height?: string;
    padding?: string;
  };
}

const Icon = ({
  variant,
  style,
  theme,
}: {
  variant: INavbarIconVariant;
  style: any;
  theme: Theme;
}) => {
  const isDarkTheme = theme.palette.mode === "dark";
  const { currentPlan } = useSubscription();
  const isMLFlowSubscribed = currentPlan?.name === "Fairo Enterprise";
  switch (variant) {
    case "organization":
      return <OrganizationIcon style={style} />;
    case "workflow":
      return <WorkflowIcon style={style} />;
    case "test":
      return <TestIcon style={style} />;
    case "asset":
      return <AssetIcon style={style} />;
    case "role":
      return <RoleIcon style={style} />;
    case "action":
      return <ActionIcon style={style} />;
    case "notification":
      return <NotificationIcon style={style} />;
    case "notification_alert":
      return <NotificationAlertIcon style={style} />;
    case "light-theme":
      return <LightThemeIcon style={style} />;
    case "dark-theme":
      return <DarkThemeIcon style={style} />;
    case "signout":
      return <SignoutIcon style={style} />;
    case "right-arrow":
      return <RightArrow style={style} />;
    case "left-arrow":
      return <LeftArrow style={style} />;
    case "menu":
      return <MenuIcon style={style} />;
    case "close":
      return <CloseIcon style={style} />;
    case "fairo-logo":
      return isDarkTheme ? <FairoLogoDark style={style} /> : <FairoLogoLight style={style} />;
    case "download-logo":
      return isDarkTheme ? <DownloadDarkIcon style={style} /> : <DownloadIcon style={style} />;
    case "download-dark-logo":
      return <DownloadDarkIcon style={style} />;
    case "no-visible":
      return <NoVisibleIcon style={style} />;
    case "metrics":
      return <MetricsIcon style={style} />;
    case "metrics-empty":
      return <MetricsLightIcon style={style} />;
    case "chevron-down":
      return <ChevronDownIcon style={style} />;
    case "user":
      return isDarkTheme ? <UserDarkIcon style={style} /> : <UserLightIcon style={style} />;
    case "show":
      return isDarkTheme ? <ShowDarkIcon style={style} /> : <ShowLightIcon style={style} />;
    case "hide":
      return isDarkTheme ? <HideDarkIcon style={style} /> : <HideLightIcon style={style} />;
    case "ellipse":
      return isDarkTheme ? <EllipseDarkIcon style={style} /> : <EllipseLightIcon style={style} />;
    case "dialog-close":
      return isDarkTheme ? (
        <DialogCloseDarkIcon style={style} />
      ) : (
        <DialogCloseLightIcon style={style} />
      );
    case "add-icon":
      return <AddIcon style={style} />;
    case "edit-icon":
      return isDarkTheme ? <EditDarkIcon style={style} /> : <EditLightIcon style={style} />;
    case "calendar":
      return isDarkTheme ? <CalendarDarkIcon style={style} /> : <CalendarLightIcon style={style} />;
    case "tooltip":
      return <TooltipIcon style={style} />;
    case "created-by-fairo":
      return <CreatedByFairoIcon style={style} />;
    case "compliance":
      return <ComplianceIcon style={style} />;
    case "evidence-pending":
      return (
        <EvidencePendingIcon style={{ ...style, stroke: theme.palette.custom.yellowTypography }} />
      );
    case "evidence-overdue":
      return <EvidenceOverdueIcon style={style} />;
    case "evidence-completed":
      return <EvidenceCompletedIcon style={style} />;
    case "google-icon":
      return <GoogleIcon style={style} />;
    case "microsoft-icon":
      return <MicrosoftIcon style={style} />;
    case "mlflow":
      return isMLFlowSubscribed ? <MLFlowActive style={style} /> : <MLFlowDisabled style={style} />;
    case "policy":
      return <PolicyIcon style={style} />;
    case "policy-sm":
      return <PolicySmIcon style={style} />;
    case "lock":
      return <LockIcon style={style} />;
    case "audit-log":
      return <AuditLogIcon style={style} />;
    case "version-history":
      return <VersionHistoryIcon style={style} />;
    case "copy":
      return <CopyIcon style={style} />;
    case "aws":
      return isDarkTheme ? <AwsDarkIcon style={style} /> : <AwsLightIcon style={style} />;
    case "azure":
      return isDarkTheme ? <AzureDarkIcon style={style} /> : <AzureLightIcon style={style} />;
    case "confluence":
      return isDarkTheme ? (
        <ConfluenceDarkIcon style={style} />
      ) : (
        <ConfluenceLightIcon style={style} />
      );
    case "dropbox":
      return isDarkTheme ? <DropboxDarkIcon style={style} /> : <DropboxLightIcon style={style} />;
    case "github":
      return isDarkTheme ? <GithubDarkIcon style={style} /> : <GithubLightIcon style={style} />;
    case "hugging-face":
      return isDarkTheme ? (
        <HuggingFaceDarkIcon style={style} />
      ) : (
        <HuggingFaceLightIcon style={style} />
      );
    case "jira":
      return isDarkTheme ? <JiraDarkIcon style={style} /> : <JiraLightIcon style={style} />;
    case "open-ai":
      return isDarkTheme ? <OpenAIDarkIcon style={style} /> : <OpenAILightIcon style={style} />;
    case "sharepoint":
      return isDarkTheme ? (
        <SharePointDarkIcon style={style} />
      ) : (
        <SharePointLightIcon style={style} />
      );
    case "slack":
      return isDarkTheme ? <SlackDarkIcon style={style} /> : <SlackLightIcon style={style} />;
    case "unlock":
      return isDarkTheme ? <UnlockDarkIcon style={style} /> : <UnlockLightIcon style={style} />;
    case "readonly":
      return <ReadonlyIcon style={style} />;
    case "empty-box":
      return <EmptyBoxIcon style={{ ...style }} />;
    case "trash-can":
      return <TrashCanIcon style={{ ...style }} />;
    case "chevron-up":
      return <ChevronUpIcon style={{ ...style }} />;
    case "resources":
      return <ResourcesIcon style={{ ...style }} />;
    case "check":
      return <CheckIcon style={{ ...style }} />;
    case "more-info":
      return <MoreInfoIcon style={{ ...style }} />;
    case "folder-plus":
      return <FolderPlus style={{ ...style }} />;
    case "file-plus":
      return <FilePlus style={{ ...style }} />;
    case "questionnaire":
      return <QuestionnaireIcon style={{ ...style }} />;
    case "empty-image":
      return <EmptyImageIcon style={{ ...style }} />;
    case "radio-checked":
      const color = isDarkTheme ? theme.palette.custom.white : theme.palette.custom.blueTypography;
      return <RadioButtonChecked style={{ ...style, stroke: color, color: color }} />;
    case "radio-unchecked":
      const uncheckedColor = isDarkTheme
        ? theme.palette.custom.white
        : theme.palette.custom.disabledButton;
      return (
        <RadioButtonUnchecked style={{ ...style, stroke: uncheckedColor, color: uncheckedColor }} />
      );
    case "reports-list":
      return <ReportsListIcon style={{ ...style }} />;
    case "reports-generate":
      return <ReportsGenerateIcon style={{ ...style }} />;
    case "welcome-to-fairo":
      return <WelcomeToFairoIcon style={{ ...style }} />;
    case "completed":
      return <CompletedIcon style={{ ...style }} />;
    case "assets-paywall":
      return <AssestsPaywallIcon style={{ ...style }} />;
    case "compliance-paywall":
      return <CompliancePaywallIcon style={{ ...style }} />;
    case "mlflow-paywall":
      return <MlflowPaywallIcon style={{ ...style }} />;
    case "policies-paywall":
      return <PoliciesPaywallIcon style={{ ...style }} />;
    case "resources-paywall":
      return <ResourcesPaywallIcon style={{ ...style }} />;
    case "tests-paywall":
      return <TestsPaywallIcon style={{ ...style }} />;
    case "workflows-paywall":
      return <WorkflowsPaywallIcon style={{ ...style }} />;
    case "impact-assessment-loading":
      return <ImpactAssessmentLoadingIcon style={{ ...style }} />;
    case "down-arrow":
      return <DownArrow style={{ ...style }} />;
    case "top-arrow":
      return <TopArrow style={{ ...style }} />;
    case "not-started":
      return <NotStartedIcon style={{ ...style }} />;
    case "fairo-ai":
      return <FairoAIIcon style={{ ...style }} />;
    case "potential-risks":
      return <PotentialRisksIcon style={{ ...style }} />;
    case "regulations":
      return <RegulationsIcon style={{ ...style }} />;
    case "suggested-actions":
      return <SuggestedActionsIcon style={{ ...style }} />;
    case "nist-logo":
      return isDarkTheme ? (
        <NistLogoDark style={{ ...style }} />
      ) : (
        <NistLogo style={{ ...style }} />
      );
    case "iso-logo":
      return isDarkTheme ? <IsoLogoDark style={{ ...style }} /> : <IsoLogo style={{ ...style }} />;
    case "euai-logo":
      return <EuaiLogo style={{ ...style }} />;
    case "hitrust-logo":
      return isDarkTheme ? (
        <HITRUSTDark style={{ ...style }} />
      ) : (
        <HITRUSTLight style={{ ...style }} />
      );
    case "vendor-registry":
      return isDarkTheme ? (
        <VendorRegistryDark style={{ ...style }} />
      ) : (
        <VendorRegistry style={{ ...style }} />
      );
    case "use-case-registry":
      return isDarkTheme ? (
        <UseCaseRegistryDark style={{ ...style }} />
      ) : (
        <UseCaseRegistry style={{ ...style }} />
      );
    case "custom-registry":
      return isDarkTheme ? (
        <CustomRegistryDark style={{ ...style }} />
      ) : (
        <CustomRegistry style={{ ...style }} />
      );
    case "canadian-algorithmic":
      return <CanadianAlgorithmicIcon style={{ ...style }} />;
    case "equal-ai-algorithmic":
      return <EqualAIAlgorithmicIcon style={{ ...style }} />;
    case "microsoft-rai":
      return <MicrosoftRaiIcon style={{ ...style }} />;
    case "oced-algorithmic":
      return <OCEDAlgorithmicIcon style={{ ...style }} />;
    case "us-cio-coucil-algorithmic":
      return <USCIOCouncilAlgorithmicIcon style={{ ...style }} />;
    default:
      // below line ensures no variant is missed out
      const _: never = variant;
      return <OrganizationIcon style={style} />;
  }
};

export const NavbarIcon = ({
  variant,
  selected,
  color,
  sx,
  backgroundColor,
  strokeWidth,
}: INavbarIcons) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const iconStyle = {
    width: DEFAULT_ICON_WIDTH,
    height: DEFAULT_ICON_HEIGHT,
    strokeWidth,
    stroke: color
      ? color
      : selected
      ? isDarkMode
        ? theme.palette.custom.white
        : theme.palette.custom.blue
      : theme.palette.custom.gray,

    ...sx,
  };

  return backgroundColor ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={DEFAULT_ICON_WIDTH}
      height={DEFAULT_ICON_HEIGHT}
      borderRadius={DEFAULT_INTERNAL_BORDER_RADIUS}
      sx={{ ...sx, backgroundColor }}
    >
      <Icon
        variant={variant}
        style={{
          strokeWidth: strokeWidth,
          stroke: color ? color : selected ? theme.palette.custom.blue : theme.palette.custom.gray,
          width: "100%",
        }}
        theme={theme}
      />
    </Box>
  ) : (
    <Icon variant={variant} style={iconStyle} theme={theme} />
  );
};
