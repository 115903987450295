import { Skeleton } from "@mui/material";

type RectangularLoaderProps = {
  width?: string;
  maxWidth?: string;
  height?: string;
};

export const RectangularLoader = (props: RectangularLoaderProps) => {
  const { width, maxWidth, height } = props;

  return (
    <Skeleton
      variant="rounded"
      width={width}
      height={height}
      sx={{
        maxWidth: maxWidth,
        borderRadius: "3px",
      }}
    />
  );
};
