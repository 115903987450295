import { Box, Button, MenuItem, Select, useTheme } from "@mui/material";
import { useState } from "react";
import { SmallModal } from "../components/SmallModal";
import { openSnackbar } from "../context/SnackbarContext";
import { useNotifications } from "../hooks/useNotification";
import { BaseFilters, FilterOption, Notification } from "../models/types";
import { IColumnVisibility } from "../molecules/ColumnVisibility";
import { EmptyTable } from "../molecules/EmptyTable";
import { TableTextWithEllipsis } from "../molecules/TableTextWithEllipsis";
import { editNotification, readAll } from "../services/NotificationService";
import { ScreenSmallerThen, defaultDateDisplay } from "../utilities/UIHelper";
import { APITable } from "./APITable";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { navigateToObject } from "../utilities/GenericNavigateHelper";
import { useNavigate } from "react-router-dom";
import { ExecutionTaskModal } from "./modals/ExecutionTaskModal";
import { NotificationsTable } from "./NotificationTable";
import { useOrganizationContext } from "../context/OrganizationContext";

const FILTER_OPTIONS: FilterOption<BaseFilters>[] = [{ label: "Message", value: "message" }];

export const NotificationsModal = (props: { open: boolean; onClose: () => void }) => {
  const { open, onClose } = props;
  const [loading, setLoading] = useState(false);
  const [selectedObject, setSelectedObject] = useState<string | null>(null);
  const [readFilter, setReadFilter] = useState<"All" | "Unread" | "Read">("All");
  const readFilterOptions = ["All", "Unread", "Read"];
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = ScreenSmallerThen();
  const { setReportsOpen, setSelectedTask } = useOrganizationContext();
  const handleReadItem = async (notification: Notification) => {
    try {
      setLoading(true);
      await editNotification(notification.id, { read: !notification.read });
      openSnackbar("Message was updated", "success");
    } catch (error) {
      openSnackbar("Failed to mark as read", "error");
    } finally {
      setLoading(false);
    }
  };
  const columnsVisibility: IColumnVisibility<Notification>[] = [
    {
      field: "message",
      headerName: "Message",
      visible: true,
      renderer: (notification) => (
        <Box
          width="100%"
          display="flex"
          gap="5px"
          alignItems="center"
          flexDirection="row"
          justifyContent="flex-start"
        >
          <Box
            onClick={() => handleReadItem(notification)}
            display="flex"
            alignItems="center"
            sx={{ cursor: "pointer" }}
          >
            <NavbarIcon
              variant={notification.read ? "radio-unchecked" : "radio-checked"}
              sx={{
                height: "18px",
                width: "18px",
                minHeight: "18px",
                minWidth: "18px",
              }}
              color={notification.read ? undefined : theme.palette.custom.blueTypography}
            />
          </Box>
          <TableTextWithEllipsis expanded={true} hideTooltip={true} value={notification.message} />
        </Box>
      ),
    },
    {
      field: "to_object_id",
      headerName: "ID",
      visible: true,
      renderer: (notification) => (
        <TableTextWithEllipsis
          onClick={() => {
            if (!notification.read) {
              handleReadItem(notification);
            }
            navigateToObject(
              navigate,
              notification.to_object_type,
              notification.to_object_id,
              setSelectedTask
            );
            if (notification.to_object_type === "Task") {
              setSelectedObject(notification.to_object_id);
            }
            if (notification.to_object_type === "Report") {
              setReportsOpen(true);
            }
            onClose();
          }}
          value={notification?.value_name ?? ""}
        />
      ),
    },
    {
      field: "to_object_type",
      headerName: "Type",
      visible: true,
      renderer: (notification) => <TableTextWithEllipsis value={notification.to_object_type} />,
    },
    {
      field: "created_on",
      headerName: "Date",
      visible: true,
      renderer: (notification) => (
        <TableTextWithEllipsis value={defaultDateDisplay(notification.created_on) ?? ""} />
      ),
    },
  ];

  const handleReadAll = async () => {
    try {
      setLoading(true);
      await readAll();
      openSnackbar("All messages were updated", "success");
    } catch (error) {
      openSnackbar("Failed to mark all as read", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SmallModal size={"large"} open={open} onClose={onClose} title="Notifications">
        <NotificationsTable />
      </SmallModal>
      {/** @todo Make it generic to differnt modal types or implement navigate + modal open on navigateToObject method */}
      {selectedObject != null && (
        <ExecutionTaskModal
          open={selectedObject !== null}
          onClose={() => setSelectedObject(null)}
          taskId={selectedObject}
        />
      )}
    </>
  );
};
