import {
  DiscriminatedLogicObject,
  EvidenceRequirementFetchOptions,
  EvidenceRequirements,
  EvidenceTestHistory,
  EvidenceTestHistoryFetchOptions,
} from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { httpGetAuthenticated, httpPatchAuthenticated } from "./ApiService";

const evidenceEndpoints = {
  evidenceRequirements: () => `/evidence_requirements`,
  evidenceRequirement: (evidenceId: string) => `/evidence_requirements/${evidenceId}`,
  evidenceEvaluate: (evidenceId: string) => `/evidence_requirements/${evidenceId}/evaluate`,
  evidenceTestHistory: () => `/evidence_test_history`,
};

export const getEvidenceRequirements = (params: EvidenceRequirementFetchOptions) => {
  return httpGetAuthenticated<ListResponse<EvidenceRequirements>>(
    evidenceEndpoints.evidenceRequirements(),
    {
      params,
    }
  );
};

export const getEvidenceRequirement = (evidenceId: string) => {
  return httpGetAuthenticated<EvidenceRequirements>(
    evidenceEndpoints.evidenceRequirement(evidenceId),
    {
      params: {
        fairo_data: true,
      },
    }
  );
};

export const getEvidenceEvaluation = (evidenceId: string) => {
  return httpGetAuthenticated<EvidenceRequirements>(
    evidenceEndpoints.evidenceEvaluate(evidenceId),
    {
      params: {
        fairo_data: true,
      },
    }
  );
};

export const getEvidenceRequirementTestHistory = (params: EvidenceTestHistoryFetchOptions) => {
  return httpGetAuthenticated<ListResponse<EvidenceTestHistory>>(
    evidenceEndpoints.evidenceTestHistory(),
    {
      params,
    }
  );
};

export const editEvidenceRequirement = async (
  evidenceRequirementId: string,
  payload: {
    evidence_logic: DiscriminatedLogicObject;
  }
) => {
  const { data } = await httpPatchAuthenticated(
    evidenceEndpoints.evidenceRequirement(evidenceRequirementId),
    payload
  );
  queryClient.invalidateQueries({
    queryKey: [QueryKey.EvidenceRequirement, evidenceRequirementId],
  });
  return data;
};
