import { Role } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { httpGetAuthenticated, httpPostAuthenticated } from "./ApiService";

const roleEndpoints = {
  role: (roleId: string) => `/roles/${roleId}`,
  add_policy: (roleId: string) => `/roles/${roleId}/add_policy`,
  remove_policy: (roleId: string) => `/roles/${roleId}/remove_policy`,
};

export const getRole = (roleId: string) => {
  return httpGetAuthenticated<Role>(roleEndpoints.role(roleId));
};

export const addPolicyToRole = async (roleId: string, policyId: string) => {
  const { data } = await httpPostAuthenticated<Role>(roleEndpoints.add_policy(roleId), {
    policy_id: policyId,
  });
  queryClient.invalidateQueries({ queryKey: [QueryKey.Policy] });
  return data;
};

export const removePolicyFromRole = async (roleId: string, policyId: string) => {
  const { data } = await httpPostAuthenticated(roleEndpoints.remove_policy(roleId), {
    policy_id: policyId,
  });
  queryClient.invalidateQueries({ queryKey: [QueryKey.Policy] });
  return data;
};
