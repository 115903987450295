import { Box, Stepper, Divider, Step, StepLabel, useTheme } from "@mui/material";
import { SmallModal } from "../../../components/SmallModal";
import { useState } from "react";
import { SelectAssessmentType } from "./SelectAssessmentType";
import { DefineUseCase } from "./DefineUseCase";
import { DefineRoles } from "./DefineRoles";
import { AssignUsers } from "./AssignUsers";
import { openSnackbar } from "../../../context/SnackbarContext";
import { createImpactAssessment } from "../../../services/ImpactAssessmentService";
import { addRegistryItems } from "../../../services/RegistryItemsService";
import {
  AI_USE_CASE_REGISTRY_ID,
  DESCRIPTION_FIELD_ID,
  USE_CASE_OWNER_FIELD_ID,
} from "../../../utilities/UIHelper";

type CreateImpactAssessmentModalProps = {
  /** Should the modal be open? */
  open: boolean;
  /** Callback to close the modal */
  onClose: () => void;
};

export const CreateImpactAssessmentModal = (props: CreateImpactAssessmentModalProps) => {
  const { open, onClose } = props;

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const steps = ["Impact Assessment Type", "Define Use Case", "Define Roles", "Assign Users"];
  const [step, setStep] = useState(0);

  const onContinue = () => {
    setStep(step + 1);
  };

  const onBack = () => {
    setStep(step - 1);
  };

  const [impactAssessmentType, setImpactAssessmentType] = useState<string>("");
  const [useCaseId, setUseCaseId] = useState<string>("");
  const [useCaseName, setUseCaseName] = useState<string>("");
  const [useCaseOwner, setUseCaseOwner] = useState<string>("");
  const [useCaseDescription, setUseCaseDescription] = useState<string>("");
  const [assessmentOwner, setAssessmentOwner] = useState<string>("");
  const [departments, setDepartments] = useState<string[]>([]);
  const [reviewers, setReviewers] = useState<string[]>([]);
  const [assignees, setAssignees] = useState<any[]>([]);

  const handleCreateImpactAssessment = async () => {
    try {
      var useCase = useCaseId;
      if (useCaseId === "") {
        const useCaseCreated = await addRegistryItems({
          name: useCaseName,
          registry: AI_USE_CASE_REGISTRY_ID,
          registry_item_values: [
            {
              registry_field: USE_CASE_OWNER_FIELD_ID,
              value: useCaseOwner,
            },
            {
              registry_field: DESCRIPTION_FIELD_ID,
              value: useCaseDescription,
            },
          ],
        });
        setUseCaseId(useCaseCreated.id);
        useCase = useCaseCreated.id;
      }
      await createImpactAssessment({
        impact_assessment_type: impactAssessmentType,
        use_case: useCase,
        assessment_owner: assessmentOwner,
        related_teams: departments,
        reviewers: reviewers,
        assignees: assignees
          .filter((assignee) => assignee.assignee)
          .map((assignee) => {
            return {
              ref: assignee.ref,
              assignee: assignee.assignee.id,
            };
          }),
      });
    } catch {
      openSnackbar("Failed to create impact assessment", "error");
    } finally {
      onClose();
    }
  };

  return (
    <>
      <SmallModal
        open={open}
        onClose={() => {
          onClose();
          setStep(0);
        }}
        title="Create Impact Assessment"
        size="large"
        customTitle={
          <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
            <Stepper
              activeStep={step}
              connector={
                <Divider
                  sx={{
                    width: "50px",
                    border: "1px solid",
                    borderColor:
                      step === 0
                        ? theme.palette.custom.secondaryBorder
                        : isDarkMode
                        ? theme.palette.custom.white
                        : theme.palette.custom.blue,
                  }}
                />
              }
            >
              {steps.map((label, index) => {
                return (
                  <Step active={index === step} key={label}>
                    <StepLabel color={theme.palette.custom.blueTypography}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        }
      >
        {step === 0 ? (
          <SelectAssessmentType
            onAssessmentTypeSelect={(assessmentType: string) => {
              setImpactAssessmentType(assessmentType);
              onContinue();
            }}
          />
        ) : step === 1 ? (
          <DefineUseCase
            onUseCaseDefine={(data: {
              useCase?: string;
              useCaseName?: string;
              useCaseOwner?: string;
              useCaseDescription?: string;
            }) => {
              if (data.useCase) {
                setUseCaseId(data.useCase);
              }
              if (data.useCaseName) {
                setUseCaseName(data.useCaseName);
              }
              if (data.useCaseOwner) {
                setUseCaseOwner(data.useCaseOwner);
              }
              if (data.useCaseDescription) {
                setUseCaseDescription(data.useCaseDescription);
              }
              onContinue();
            }}
            onBack={() => onBack()}
          />
        ) : step === 2 ? (
          <DefineRoles
            onDefineRoles={(
              assessmentOwner: string,
              departments: string[],
              reviewers: string[]
            ) => {
              setAssessmentOwner(assessmentOwner);
              setDepartments(departments);
              setReviewers(reviewers);
              onContinue();
            }}
            onBack={() => onBack()}
          />
        ) : (
          <AssignUsers
            assignees={assignees}
            setAssignees={(assginees: any) => setAssignees(assginees)}
            onCreate={() => handleCreateImpactAssessment()}
            onBack={() => onBack()}
          />
        )}
      </SmallModal>
    </>
  );
};
