import { Asset } from "../models/types";
import { httpGetAuthenticated } from "./ApiService";

const assetEndpoints = {
  asset: (assetId: string) => `/assets/${assetId}`,
};

export const getAsset = (assetId: string) => {
  return httpGetAuthenticated<Asset>(assetEndpoints.asset(assetId));
};
