import { useQuery, useQueryClient } from "react-query";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { fetchExecutionPlans } from "../hooks/useExecutionPlans";
import { fetchProcessGraphs } from "../hooks/useProcessGraphs";
import { fetchProcessNodes } from "../hooks/useProcessNodes";
import { fetchWorkflows } from "../hooks/useWorkflows";
import { IProcessNode } from "../models/IProcessNode";
import { ProcessGraphIcon, WorkflowIndicatorIcon } from "./AppIcons";
import BreadcrumbsNavigator from "../molecules/BreadcrumbsNavigator";

/** Map<pathname, state> */
let _pathStateMap: Map<
  string,
  {
    workflowId?: string;
    workflowNumber?: number;
    processGraphId?: string;
    processGraphNumber?: number;
    processNodeId?: string;
    executionPlanId?: string;
    executionPlanNumber?: number;
    edit?: boolean;
  }
> = new Map();

/**
 * Breadcrumb navigator for workflow, processes, nodes.
 * Should be used in base workflow page.
 */
const WorkflowNavigator = () => {
  let crumbs: any = [
    {
      // base route always present
      label: "Workflows",
      tooltip: "All Workflows",
      routeTo: "/workflows",
    },
  ];

  const { pathname } = useLocation();
  // '/workflows' is common to all paths so remove first two elements after splitting
  const pathSegments = pathname.split("/").slice(2);
  let workflowSegment, processSegment, nodeSegment;

  if (pathSegments.length > 0) {
    workflowSegment = pathSegments[0];
    crumbs.push({
      label: workflowSegment,
      routeTo: `/workflows/${workflowSegment}`,
      icon: <WorkflowIndicatorIcon marginRight={5} />,
    });
  }

  if (pathSegments.length > 1) {
    processSegment = pathSegments[1];
    if (processSegment !== "execution") {
      crumbs.push({
        label: processSegment,
        routeTo: `/workflows/${workflowSegment}/${processSegment}`,
        icon: <ProcessGraphIcon marginRight={5} />,
      });
    }
  }

  if (pathSegments.length > 2) {
    nodeSegment = pathSegments[2];
    crumbs.push({
      label: nodeSegment,
      routeTo: `/workflows/${workflowSegment}/${processSegment}/${nodeSegment}`,
    });
  }

  return <BreadcrumbsNavigator crumbs={crumbs} />;
};

const navigateToWorkflow = (params: {
  navigate: NavigateFunction;
  workflowId: string;
  workflowNumber: number;
  edit?: boolean;
}) => {
  const { navigate, workflowId, workflowNumber, edit } = params;
  const path = `/workflows/workflow-${workflowNumber}`;
  _pathStateMap.set(path, {
    workflowId,
    edit,
  });

  navigate(path);
};

const navigateToProcessGraph = (params: {
  navigate: NavigateFunction;
  workflowNumber: number;
  processGraphId: string;
  processGraphNumber: number;
  edit?: boolean;
}) => {
  const { navigate, processGraphId, processGraphNumber, workflowNumber, edit } = params;
  const path = `/workflows/workflow-${workflowNumber}/process-${processGraphNumber}`;
  _pathStateMap.set(path, {
    workflowNumber,
    processGraphId,
    processGraphNumber,
    edit,
  });

  navigate(path);
};

const navigateToProcessExecutionPlan = (params: {
  navigate: NavigateFunction;
  workflowNumber: number;
  executionPlanNumber: number;
  processGraphId: string;
  executionPlanId: string;
  edit?: boolean;
}) => {
  const { navigate, workflowNumber, executionPlanNumber, processGraphId, executionPlanId, edit } =
    params;
  const path = `/workflows/workflow-${workflowNumber}/execution/execution-${executionPlanNumber}`;
  _pathStateMap.set(path, {
    workflowNumber,
    executionPlanNumber,
    processGraphId,
    executionPlanId,
    edit,
  });

  navigate(path);
};

const navigateToProcessNode = (params: {
  navigate: NavigateFunction;
  workflowNumber: number;
  processGraphNumber: number;
  processNode: IProcessNode;
}) => {
  const { navigate, processNode, processGraphNumber, workflowNumber } = params;

  const { path } = setProcessNodePathState({
    processGraphNumber,
    processNode,
    workflowNumber,
  });

  navigate(path);
};

const getPathState = (path: string) => {
  return _pathStateMap.get(path) ?? {};
};

const WORKFLOW_NAVIGATOR_KEY = "workflow-navigator";

export const useWorkflowPathState = (path: string) => {
  const { workflowId, edit } = getPathState(path);
  // path is of the form '/workflows/workflow-12'
  const pathParts = path.split("/");
  const workflowPart = pathParts[2];
  const workflowNum = workflowPart.slice("workflow-".length);
  const navigate = useNavigate();

  return useQuery<{
    workflowId: string;
    edit?: boolean;
  }>(
    [WORKFLOW_NAVIGATOR_KEY, path],
    async () => {
      if (workflowId != null) {
        return { workflowId, edit };
      }

      const workflows = await fetchWorkflows({ workflow_num: [workflowNum] });
      const workflow = workflows.results.find((w) => `workflow-${w.workflow_num}` === workflowPart);
      if (!workflow) {
        // Bad URL. redirect to workflows page
        navigate("/workflows");
        return { workflowId: "", edit };
      }

      return { workflowId: workflow.id, edit };
    },
    { suspense: true }
  );
};

export const useProcessGraphPathState = (path: string) => {
  const { workflowNumber, processGraphId, edit } = getPathState(path);
  // path is of the form '/workflows/workflow-12/process-34'
  const pathParts = path.split("/");
  const [workflowPart, graphPart] = pathParts.slice(2);
  const workflowNum = workflowPart.slice("workflow-".length);
  const graphNum = graphPart.slice("process-".length);
  const navigate = useNavigate();

  return useQuery<{
    workflowNumber: number;
    processGraphId: string;
    edit?: boolean;
  }>(
    [WORKFLOW_NAVIGATOR_KEY, path],
    async () => {
      if (workflowNumber != null && processGraphId != null) {
        return { workflowNumber, processGraphId, edit };
      }

      const workflows = await fetchWorkflows({ workflow_num: [workflowNum] });
      const workflow = workflows.results.find((w) => `workflow-${w.workflow_num}` === workflowPart);
      if (!workflow) {
        // Bad URL. redirect to workflows page
        navigate("/workflows");
        throw Error("Unknown workflow in path to process graph");
      }

      const processGraphs = await fetchProcessGraphs({
        workflow: workflow.id,
        process_graph_num: [graphNum],
      });
      const processGraph = processGraphs.results.find(
        (process) => `process-${process.process_graph_num}` === graphPart
      );
      if (processGraph == null) {
        // Bad URL. redirect to workflow page
        navigateToWorkflow({
          navigate,
          workflowId: workflow.id,
          workflowNumber: workflow.workflow_num,
        });
        throw Error("Unknown process graph");
      }

      return { workflowNumber: workflow.workflow_num, processGraphId: processGraph.id, edit };
    },
    { suspense: true }
  );
};

export const useProcessNodePathState = (path: string) => {
  const { workflowNumber, processGraphNumber, processNodeId } = getPathState(path);
  // path is of the form '/workflows/workflow-12/process-34/node-56'
  const pathParts = path.split("/");
  const [workflowPart, graphPart, nodePart] = pathParts.slice(2);
  const workflowNum = workflowPart.slice("workflow-".length);
  const graphNum = graphPart.slice("process-".length);
  const nodeNum = nodePart.slice("node-".length);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useQuery<{
    workflowNumber: number;
    processGraphNumber: number;
    processNodeId: string;
  }>(
    [WORKFLOW_NAVIGATOR_KEY, path],
    async () => {
      if (workflowNumber != null && processGraphNumber != null && processNodeId != null) {
        return { workflowNumber, processGraphNumber, processNodeId };
      }

      const workflows = await fetchWorkflows({ workflow_num: [workflowNum] });
      const workflow = workflows.results.find((w) => `workflow-${w.workflow_num}` === workflowPart);
      if (workflow == null) {
        // Bad URL. redirect to workflows page
        navigate("/workflows");
        throw Error("Unknown workflow in path to process node");
      }

      const processGraphs = await fetchProcessGraphs({
        workflow: workflow.id,
        process_graph_num: [graphNum],
      });
      const processGraph = processGraphs.results.find(
        (process) => `process-${process.process_graph_num}` === graphPart
      );
      if (processGraph == null) {
        // Bad URL. redirect to workflow page
        navigateToWorkflow({
          navigate,
          workflowId: workflow.id,
          workflowNumber: workflow.workflow_num,
        });
        throw Error("Unknown process graph in path to process node");
      }

      const processNodes = await fetchProcessNodes({ process_graph: processGraph.id });
      const processNode = processNodes.results.find(
        (node) => `node-${node.process_node_num}` === nodePart
      );
      if (processNode == null) {
        // Bad URL. redirect to graph page
        navigateToProcessGraph({
          navigate,
          workflowNumber: workflow.workflow_num,
          processGraphNumber: processGraph.process_graph_num,
          processGraphId: processGraph.id,
        });
        throw Error("Unknown process node");
      }

      return {
        workflowNumber: workflow.workflow_num,
        processGraphNumber: processGraph.process_graph_num,
        processNodeId: processNode.id,
      };
    },
    { suspense: true }
  );
};

export const useProcessExecutionPlanPathState = (path: string) => {
  const { executionPlanId, workflowNumber, processGraphId, edit } = getPathState(path);
  // path is of the form '/workflows/workflow-12/execution/execution-34'
  const pathParts = path.split("/");
  const [workflowPart, _, planPart] = pathParts.slice(2);
  const workflowNum = workflowPart.slice("workflow-".length);
  const planNum = planPart.slice("execution-".length);

  const navigate = useNavigate();
  return useQuery<{
    executionPlanId: string;
    workflowNumber: number;
    processGraphId: string;
    edit?: boolean;
  }>(
    [WORKFLOW_NAVIGATOR_KEY, path],
    async () => {
      if (executionPlanId != null && processGraphId != null && workflowNumber != null) {
        return {
          executionPlanId,
          workflowNumber,
          processGraphId,
          edit,
        };
      }

      const workflows = await fetchWorkflows({ workflow_num: [workflowNum] });
      const workflow = workflows.results.find((w) => `workflow-${w.workflow_num}` === workflowPart);
      if (workflow == null) {
        // Bad URL. redirect to workflows page
        navigate("/workflows");
        throw Error("Unknown workflow in path to process execution");
      }

      const executionPlans = await fetchExecutionPlans({
        workflow: workflow.id,
        process_execution_plan_num: [planNum],
      });
      const executionPlan = executionPlans.results.find(
        (plan) => `execution-${plan.process_execution_plan_num}` == planPart
      );
      if (executionPlan == null) {
        // Bad URL. redirect to workflow page
        navigateToWorkflow({
          navigate,
          workflowId: workflow.id,
          workflowNumber: workflow.workflow_num,
        });
        throw Error("Unknown process execution");
      }

      return {
        workflowNumber: workflow.workflow_num,
        executionPlanId: executionPlan.id,
        processGraphId: executionPlan.process_graph,
        edit,
      };
    },
    { suspense: true }
  );
};

const setProcessNodePathState = (params: {
  workflowNumber: number;
  processGraphNumber: number;
  processNode: IProcessNode;
}) => {
  const { processNode, processGraphNumber, workflowNumber } = params;
  const path = `/workflows/workflow-${workflowNumber}/process-${processGraphNumber}/node-${processNode.nodeNumber}`;
  const state = {
    workflowNumber,
    processGraphNumber,
    processNodeId: processNode.id,
  };
  _pathStateMap.set(path, state);

  return {
    path,
    state,
  };
};

export {
  WorkflowNavigator,
  navigateToWorkflow,
  navigateToProcessGraph,
  navigateToProcessNode,
  navigateToProcessExecutionPlan,
  setProcessNodePathState,
};
