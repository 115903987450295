import { Box, Typography } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

type DetailsLoaderProps = {
  title?: string;
};

export const DetailsLoader = (props: DetailsLoaderProps) => {
  const { title } = props;

  return (
    <Box
      sx={{ width: "100%", height: "100%", display: "flex", flex: 1, flexDirection: "column" }}
      gap="10px"
    >
      <RectangularLoader maxWidth="229px" height="30px" />
      <RectangularLoader maxWidth="229px" height="20px" />
      <RectangularLoader maxWidth="879px" height="30px" />
      <RectangularLoader maxWidth="229px" height="20px" />
      <RectangularLoader maxWidth="229px" height="20px" />
      <RectangularLoader maxWidth="879px" height="134px" />
      <Box display="flex" width="100%" justifyContent="space-between" gap="10px">
        <RectangularLoader width="435px" height="30px" />
        <RectangularLoader width="145px" height="30px" />
      </Box>
      <Box display="flex" width="100%" justifyContent="space-between" gap="10px">
        <RectangularLoader width="435px" height="30px" />
        <RectangularLoader width="145px" height="30px" />
      </Box>
    </Box>
  );
};
