import { Typography, useTheme } from "@mui/material";
import { ScreenSmallerThen } from "../../utilities/UIHelper";

interface INavbarTitle {
  label: string;
  padding?: string;
}
const NavbarTitle = ({ label, padding }: INavbarTitle) => {
  const theme = useTheme();
  const isSmallScreen = ScreenSmallerThen();
  return (
    <Typography
      variant="h4"
      sx={{
        display: "flex",
        justifyContent: isSmallScreen ? "center" : "flex-start",
        color: theme.palette.text.primary,
        padding: padding ?? 0,
      }}
    >
      {label}
    </Typography>
  );
};
export default NavbarTitle;
