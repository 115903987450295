import { Breadcrumbs, Tooltip, Box, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { ReactElement } from "react";

type Props = {
  crumbs: {
    label: string;
    routeTo?: string;
    icon?: ReactElement;
  }[];
};

const BreadcrumbsNavigator = ({ crumbs }: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { fontWeight, fontFamily } = theme.typography.h2;
  return (
    <Breadcrumbs
      separator="/"
      sx={{ fontWeight, fontFamily, fontSize: "20px" }}
      aria-label="breadcrumb"
    >
      {crumbs.map(({ label, routeTo, icon }, index) => {
        return routeTo ? (
          <Box onClick={() => navigate(routeTo)}>
            {icon}
            <Typography
              variant="h2"
              sx={{
                fontWeight: 600,
                cursor: "pointer",
                "&:hover": { textDecoration: "underline" },
                textTransform: "capitalize",
              }}
            >
              {label}
            </Typography>
          </Box>
        ) : (
          <Typography variant="h2" sx={{ textTransform: "capitalize" }}>
            {label}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbsNavigator;
