import { Box, Stack, Typography } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

type ApiKeysLoaderProps = {
  title: string;
};

export const ApiKeysLoader = (props: ApiKeysLoaderProps) => {
  const { title } = props;
  return (
    <Stack sx={{ width: "100%", gap: "15px", paddingTop: "50px" }}>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box display="flex" flexDirection="column" gap="15px" width="100%">
          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <Typography variant="h2">{title}</Typography>
            <RectangularLoader width="143px" height="35px" />
          </Box>
          {[...Array(3)].map((_, idx) => (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Stack gap="5px">
                  <RectangularLoader width="300px" height="20px" />
                  <RectangularLoader width="300px" height="20px" />
                  <RectangularLoader width="300px" height="20px" />
                </Stack>
                <Box gap="10px" alignItems="center" justifyContent="center" display="flex">
                  <RectangularLoader width="35px" height="35px" />
                  <RectangularLoader width="35px" height="35px" />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Stack>
  );
};
