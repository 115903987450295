import { Box, Typography, useTheme } from "@mui/material";
import CKEditorField from "../../components/custom-fields/CKEditorField";
import { ShowMore } from "../ShowMore";

type TaskCommentsProps = {
  edit: boolean;
  value: string | null;
  onChange: (value: string) => void;
};

export const TaskComments = (props: TaskCommentsProps) => {
  const { edit, value, onChange } = props;
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap="5px">
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        Comments
      </Typography>
      {edit && <CKEditorField value={value} readOnly={!edit} onChange={onChange} />}
      {!edit && <ShowMore description={value ?? ""} maxChar={250} richText={true} />}
    </Box>
  );
};
