import { useQuery } from "react-query";
import { AssetFetchOptions } from "../models/types";
import { getAsset } from "../services/AssetService";
import { getAssets } from "../services/DataService";
import { getVersionHistory } from "../services/VersionHistoryService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useAssets = (params: AssetFetchOptions) => {
  return useQuery([QueryKey.Asset, params], async () => {
    const { data } = await getAssets(params);
    return data;
  });
};

export const fetchAssets = (params: AssetFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.Asset, params], async () => {
    const { data } = await getAssets(params);
    return data;
  });
};

export const searchAssets = async (searchText: string) => {
  const assets = await fetchAssets({ search: searchText });
  return assets.results;
};

export const searchAssetsWithParams = (params: AssetFetchOptions) => async (searchText: string) => {
  const assets = await fetchAssets({ search: searchText, ...params });
  return assets.results;
};

export const useAsset = (assetId: string, version_num?: number) => {
  const searchForVersionNum = !!version_num;
  const queryKey = searchForVersionNum
    ? [QueryKey.Asset, assetId, version_num]
    : [QueryKey.Asset, assetId];
  const queryFn = searchForVersionNum
    ? async () => {
        const { data } = await getVersionHistory({
          object_id: [assetId],
          version_num: [version_num],
        });
        return JSON.parse(data.results[0].object);
      }
    : async () => {
        if (assetId === "") return null;
        const { data } = await getAsset(assetId);
        return data;
      };
  return useQuery(queryKey, queryFn);
};

export const fetchAsset = (assetId: string) => {
  return queryClient.fetchQuery([QueryKey.Asset, assetId], async () => {
    const { data } = await getAsset(assetId);
    return data;
  });
};
