import axios from "axios";
import { forEach } from "lodash";
import { SemanticSimiliarityItem } from "../models/types";
import { httpPostAuthenticated } from "./ApiService";

const endpoints = {
  query: () => "/demo/semantic_similarity",
};

type SemanticSimilarityResponseFirstObject = {
  rephrased_query: string;
};

type SemanticSimilarityResponseSecondObject = {
  top_documents: {
    document_id: string;
    chunk_ind: number;
    semantic_identifier: string;
    link: string | null;
    blurb: string;
    source_type: string;
    boost: number;
    hidden: boolean;
    metadata: any;
    score: number;
    match_highlights: string[];
    updated_at: string;
    primary_owners: string | null;
    secondary_owners: string | null;
    db_doc_id: number;
  }[];
  rephrased_query: string | null;
  predicted_flow: string;
  predicted_search: string;
  applied_source_filters: string | null;
  applied_time_cutoff: string | null;
  recency_bias_multiplier: number;
};
const proxy = "https://cors-anywhere.herokuapp.com/";
export const getSemanticSimilarity = async (
  intended_use: string,
  description: string
): Promise<SemanticSimiliarityItem[]> => {
  const { data } = await httpPostAuthenticated(endpoints.query(), {
    messages: [
      {
        message: `${intended_use}: ${description}`,
        sender: null,
        role: "user",
      },
    ],
    persona_id: 0,
    prompt_id: null,
    semantic_search: true,
    retrieval_options: {
      run_search: "always",
      real_time: true,
      filters: {
        source_type: null,
        document_set: ["'EU: AI Act (including corrigendum)'"],
        time_cutoff: null,
        tags: [],
      },
      enable_auto_detect_filters: false,
    },
  });

  const response_text = `[${data.replace(/}\s*{/g, "},{")}]`;
  const parsedResponse = JSON.parse(response_text);
  const firstObject: SemanticSimilarityResponseFirstObject = parsedResponse[0];
  const secondObject: SemanticSimilarityResponseSecondObject = parsedResponse[1];
  let items: SemanticSimiliarityItem[] = [];
  forEach(secondObject.top_documents, (doc) => {
    const matched = extractTypesAndNumbers(doc.blurb);
    if (matched) {
      const type = matched.type.toLowerCase();
      items.push({
        blurb: doc.blurb,
        type: type,
        number: matched.number,
        link: `https://artificialintelligenceact.eu/${type}/${matched.number}`,
        intended_use: intended_use,
      });
    }
  });
  return items;
};

type MatchedItem = {
  type: string;
  number: number;
};

function extractTypesAndNumbers(data: string): MatchedItem | null {
  const regex = /(Recital|Article) (\d+)|(ANNEX) ([IVXLCDM]+)\s/g;
  const match = regex.exec(data);
  if (match) {
    if (match[1]) {
      const type = match[1]; // "Recital" or "Section"
      const number = parseInt(match[2], 10); // Convert the number part to an integer
      return { type, number };
    } else if (match[3]) {
      const type = match[3]; // "Recital" or "Section"
      const number = romanToInteger(match[4]); // Convert the number part to an integer
      return { type, number };
    }
  }
  return null;
}

function romanToInteger(roman: string): number {
  const romanValues: { [key: string]: number } = {
    I: 1,
    V: 5,
    X: 10,
    L: 50,
    C: 100,
    D: 500,
    M: 1000,
  };

  let total = 0;
  let current, next;

  for (let i = 0; i < roman.length; i++) {
    current = romanValues[roman[i]];
    next = romanValues[roman[i + 1]];

    if (next && current < next) {
      total -= current; // If the current value is less than the next value, subtract current
    } else {
      total += current; // Otherwise, add the current value
    }
  }

  return total;
}
