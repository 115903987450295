import { useQuery } from "react-query";
import { ExecutionTaskFetchOptions } from "../models/types";
import { getExecutionTasks } from "../services/ExecutionPlanService";
import { QueryKey } from "../state/QueryStore";

export const useExecutionTasks = (params: ExecutionTaskFetchOptions) => {
  return useQuery([QueryKey.ExecutionTask, params], async () => {
    const tasks = await getExecutionTasks(params);
    return tasks.data;
  });
};
