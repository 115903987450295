import { useQuery } from "react-query";
import { getMLFlowModels } from "../services/MLFlowService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useMLFlowModels = (search: string) => {
  return useQuery([QueryKey.MLFlowModel], async () => {
    const { data } = await getMLFlowModels(search);
    if (data.registered_models) return data.registered_models;
    return [];
  });
};

export const fetchMLFlowModels = ({ search }: { search: string }) => {
  return queryClient.fetchQuery([QueryKey.MLFlowModel], async () => {
    const { data } = await getMLFlowModels(search);
    return data;
  });
};

export const searchMLFlowModels = async (searchText: string) => {
  const models = await fetchMLFlowModels({ search: searchText });
  if (models.registered_models) return models.registered_models;
  return [];
};
