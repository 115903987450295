import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { getAuthTokens, refreshAccessToken } from "./AuthService";
import { openSnackbar } from "../context/SnackbarContext";
import config from "../config/config";
import { openPaywall } from "../context/PaywallContext";
import { CustomAxiosError } from "../utilities/ErrorResponseHelper";

const baseUrl = process.env.REACT_APP_BASE_URL;
const skipPaywallRoutes = ["/roles/role"];

enum ApiEndpoint {
  userAuthObtainToken = "/auth/obtain_token_pair",
  userAuthRefreshToken = "/auth/refresh_token",
  userRegister = "/register",
  userRetrieve = "/users",
  organization = "/organizations",
  workflows = "/workflows",
  processGraphs = "/process_graphs",
  processExecutionPlans = "/process_execution_plans",
  processNodes = "/process_nodes",
  processEdges = "/process_edges",
  roles = "/roles",
  customFields = "/custom_fields",
  customFieldValues = "/custom_field_values",
  files = "/files",
  actions = "/actions",
  testCases = "/test_cases",
  assets = "/assets",
  assetTypes = "asset_types",
}

const getServerBaseUrl = () => baseUrl;

const workflowEndpoint = (workflowId: string) => ApiEndpoint.workflows + `/${workflowId}`;

const processGraphEndpoint = (processGraphId: string) =>
  ApiEndpoint.processGraphs + `/${processGraphId}`;

const processNodeEndpoint = (processNodeId: string) =>
  ApiEndpoint.processNodes + `/${processNodeId}`;

const customFieldEndpoint = (customFieldId: string) =>
  ApiEndpoint.customFields + `/${customFieldId}`;

const customFieldValueEndpoint = (customFieldValueId: string) =>
  ApiEndpoint.customFieldValues + `/${customFieldValueId}`;

const fileAttachmentEndpoint = (workflowFileAttachmentId: string) =>
  ApiEndpoint.files + `/${workflowFileAttachmentId}`;

/**Axios instance for public (un-authenticated) calls */
const axiosPublic = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "application/json",
  },
});

const httpGetPublic = (endpoint: ApiEndpoint, data: any) => {
  return axiosPublic.get(endpoint, data);
};

const httpPostPublic = (endpoint: ApiEndpoint, data: any) => {
  return axiosPublic.post(endpoint, data);
};

/**Axios instance for public (un-authenticated) calls */
const axiosAuthenticated = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "application/json",
  },
});

axiosAuthenticated.interceptors.request.use((request) => {
  const accessToken = getAuthTokens()?.accessToken;
  request.headers.setAuthorization(`Bearer ${accessToken}`);
  const method = request?.method?.toLowerCase();
  if ((method === "get" || method === "patch") && request?.params?.fairo_data === undefined) {
    request.params = {
      ...request.params,
      fairo_data: true,
    };
  }
  return request;
});

axiosAuthenticated.interceptors.response.use(
  (request) => request,
  (error) => {
    const SUBSCRIPTION_REQUIRED_MESSAGE1 =
      "Your organization must be subscribed to access this content";
    const SUBSCRIPTION_REQUIRED_MESSAGE2 =
      "Your plan currently don't support this feature, please upgrade it.";
    if (
      (error.response?.data?.detail === SUBSCRIPTION_REQUIRED_MESSAGE1 ||
        error.response?.data?.detail === SUBSCRIPTION_REQUIRED_MESSAGE2) &&
      !skipPaywallRoutes.some((route) => window.location.pathname.includes(route))
    ) {
      openSnackbar("Your current plan does not support this feature.", "error", () => {});
      openPaywall("");
    }

    if (error instanceof axios.AxiosError) {
      const customError = new CustomAxiosError(
        error.message,
        error.config,
        error.code,
        error.request,
        error.response
      );
      return Promise.reject(customError);
    }
    return Promise.reject(error);
  }
);

const mlflowAuthenticated = axios.create({
  baseURL: config.mlflow.baseUrl,
  headers: {
    Accept: "application/json",
  },
});

mlflowAuthenticated.interceptors.request.use((request) => {
  const accessToken = getAuthTokens()?.accessToken;
  request.headers.setAuthorization(`Bearer ${accessToken}`);
  return request;
});

mlflowAuthenticated.interceptors.response.use(
  (request) => request,
  (error) => {
    const SUBSCRIPTION_REQUIRED_MESSAGE =
      "Your organization must be subscribed to access this content";
    if (error.response?.data?.detail === SUBSCRIPTION_REQUIRED_MESSAGE) {
      openSnackbar(SUBSCRIPTION_REQUIRED_MESSAGE, "error", () => {});
    }
    return Promise.reject(error);
  }
);

const refreshAuthLogic = async (failedRequest: any) => {
  // refresh token and set it
  return refreshAccessToken();
};
createAuthRefreshInterceptor(axiosAuthenticated, refreshAuthLogic, {
  statusCodes: [401, 405],
});

createAuthRefreshInterceptor(mlflowAuthenticated, refreshAuthLogic, {
  statusCodes: [401, 405],
});

const httpGetAuthenticated = <T = any>(endpoint: string, data: any = null) => {
  return axiosAuthenticated.get<T>(endpoint, data);
};

const mlflowGetAuthenticated = <T = any>(endpoint: string, data: any = null) => {
  return mlflowAuthenticated.get<T>(endpoint, data);
};

const httpPostAuthenticated = <T = any>(
  endpoint: string,
  data: any = null,
  config: any = undefined
) => {
  return axiosAuthenticated.post<T>(endpoint, data, config);
};

const httpPatchAuthenticated = (endpoint: string, data: any = null) => {
  return axiosAuthenticated.patch(endpoint, data);
};

const httpDeleteAuthenticated = (endpoint: string, data: any = null) => {
  return axiosAuthenticated.delete(endpoint, data);
};

const httpFormPostAuthenticated = (endpoint: string, formData: FormData) => {
  return axiosAuthenticated.post(endpoint, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export {
  httpGetPublic,
  httpPostPublic,
  httpGetAuthenticated,
  httpPostAuthenticated,
  httpPatchAuthenticated,
  httpDeleteAuthenticated,
  httpFormPostAuthenticated,
  getServerBaseUrl,
  ApiEndpoint,
  workflowEndpoint,
  processGraphEndpoint,
  processNodeEndpoint,
  customFieldEndpoint,
  customFieldValueEndpoint,
  fileAttachmentEndpoint,
  mlflowGetAuthenticated,
};
