import { Box, Typography, useTheme } from "@mui/material";
import { SearchableDropdown } from "../../components/SearchableDropdown";

type SelectTaskStatusProps = {
  edit: boolean;
  value: string | null;
  onChange: (value: string | null) => void;
};
export const SelectTaskStatus = (props: SelectTaskStatusProps) => {
  const { edit, value, onChange } = props;
  const theme = useTheme();
  const themePalette = theme.palette.custom;
  const handleStatusColor = (status: string) => {
    switch (status) {
      case "Completed":
        return themePalette.greenTypography;
      case "Pending":
        return themePalette.redTypography;
      default:
        return themePalette.secondaryTypography;
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={edit ? "column" : "row"}
      gap="10px"
      alignItems={edit ? "flex-start" : "center"}
    >
      <Typography variant="h3" minWidth="115px" color={themePalette.secondaryTypography}>
        Status
      </Typography>
      {edit && (
        <SearchableDropdown<string>
          label="Status"
          required
          error={false}
          value={value}
          onChange={(newField) => onChange(newField)}
          getOptions={(search: string) =>
            ["Pending", "Completed"].filter((option) =>
              option.toLowerCase().includes(search.toLowerCase())
            )
          }
          isOptionEqualToValue={(a, b) => a === b}
          getOptionLabel={(option) => option}
          getSummaryDescription={(option) => [option, ""]}
        />
      )}
      {!edit && (
        <Typography color={handleStatusColor(value || "Pending")} variant="body2">
          {value}
        </Typography>
      )}
    </Box>
  );
};
