import { PartialRegistryItemValue, RegistryItem } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const registryItemsEndpoints = {
  registryItems: () => "/registry_items",
  registryItem: (registryItemId: string) => `/registry_items/${registryItemId}`,
};

export const getRegistryItems = (params: any) => {
  return httpGetAuthenticated<ListResponse<RegistryItem>>(registryItemsEndpoints.registryItems(), {
    params: {
      ...params,
      fairo_data: true,
    },
  });
};

export const getRegistryItem = (itemId: string) => {
  return httpGetAuthenticated<RegistryItem>(registryItemsEndpoints.registryItem(itemId), {
    params: {
      fairo_data: true,
    },
  });
};

export const addRegistryItems = async (payload: {
  name: string;
  registry: string;
  registry_item_values: PartialRegistryItemValue[];
}) => {
  const { data } = await httpPostAuthenticated<RegistryItem>(
    registryItemsEndpoints.registryItems(),
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.RegistryItem] });
  return data;
};

export const editRegistryItems = async (
  registryItemId: string,
  payload: {
    registry: string;
    registry_item_values: PartialRegistryItemValue[];
  }
) => {
  const { data } = await httpPatchAuthenticated(
    registryItemsEndpoints.registryItem(registryItemId),
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.RegistryItem] });
  return data;
};

export const deleteRegistryItem = async (registryItemId: string) => {
  const resp = await httpDeleteAuthenticated(registryItemsEndpoints.registryItem(registryItemId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.RegistryItem, registryItemId] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.RegistryItem] });
  return resp;
};
