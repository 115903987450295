import { EvidenceRequirements } from "../models/types";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { httpGetAuthenticated } from "./ApiService";

const evidenceMappingEndpoints = {
  evidenceMappings: () => `/evidence_requirements`,
};

export const getEvidenceMappings = (params: any) => {
  return httpGetAuthenticated<ListResponse<EvidenceRequirements>>(
    evidenceMappingEndpoints.evidenceMappings(),
    {
      params,
    }
  );
};
