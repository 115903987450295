import {
  Box,
  Button,
  Divider,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { AddMultipleObjectModal } from "../../../components/AddObjectModal";
import { CreateDepartmentModal } from "../../../components/CreateDepartmentModal";
import { ImportCSV } from "../../../components/ImportCSV";
import { InviteUserModal } from "../../../components/InviteUserModal";
import { SmallModal } from "../../../components/SmallModal";
import { openSnackbar } from "../../../context/SnackbarContext";
import { useAuth } from "../../../hooks/useAuth";
import { searchRoles, useRoles } from "../../../hooks/useRoles";
import { useUsers } from "../../../hooks/useUsers";
import { BaseFilters, FilterOption, User } from "../../../models/types";
import BadgeList from "../../../molecules/BadgeList";
import { IColumnVisibility } from "../../../molecules/ColumnVisibility";
import { EmptyTable } from "../../../molecules/EmptyTable";
import { TableIconButton } from "../../../molecules/TableIconButton";
import { TableTextWithEllipsis } from "../../../molecules/TableTextWithEllipsis";
import { addUserToRole } from "../../../services/DataService";
import { uploadCSV } from "../../../services/UserSettingsService";
import { CustomAxiosError } from "../../../utilities/ErrorResponseHelper";
import { APITable } from "../../APITable";
import { LoadingButton } from "@mui/lab";

export const InviteUsersWithRole = () => {
  const theme = useTheme();
  const [inviteUserOpen, setInviteUserOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [openImportCSV, setOpenImportCSV] = useState(false);
  const [addRoleOpen, setAddRoleOpen] = useState(false);
  const [openCreateDepartment, setOpenCreateDepartment] = useState(false);
  const { user: loggedInUser, organization } = useAuth();
  const isAdmin = loggedInUser?.permissions === "Admin";

  const RolesDisplay = (props: { user: User }) => {
    const { user } = props;
    return user?.roles?.length === 0 ? (
      <Button
        variant="text"
        onClick={() => {
          setAddRoleOpen(true);
          setSelectedUserId(user.id);
        }}
      >
        Add Role
      </Button>
    ) : (
      <BadgeList values={user?.roles?.map((role) => role.name) ?? []} />
    );
  };

  const columns: IColumnVisibility<User>[] = [
    {
      field: "name",
      headerName: "Name",
      visible: true,
      renderer: (user) => (
        <TableIconButton
          variant="user"
          backgroundColor={theme.palette.custom.secondaryBackground}
          label={user.name}
          onClick={() => {
            setSelectedUserId(user.id);
            setInviteUserOpen(true);
          }}
        />
      ),
    },
    { field: "email", headerName: "Email", visible: true },
    {
      field: "department",
      headerName: "Department",
      visible: true,
      renderer: (user) => <TableTextWithEllipsis value={user?.department_name?.name ?? ""} />,
    },
    {
      field: "job_title",
      headerName: "Job Title",
      visible: true,
      renderer: (user) => <TableTextWithEllipsis value={user?.job_title ?? ""} />,
    },
    {
      field: "manager_name",
      headerName: "Manager",
      visible: true,
      renderer: (user) => <TableTextWithEllipsis value={user?.manager_name?.name ?? ""} />,
    },
    {
      field: "id",
      headerName: "Role",
      visible: true,
      renderer: (user) => <RolesDisplay user={user} />,
    },
  ];

  const FILTER_OPTIONS: FilterOption<BaseFilters>[] = [
    { value: "name", label: "Name" },
    { value: "email", label: "Email" },
  ];

  return (
    <Box display="flex" flexDirection="column" gap="10px" alignItems="center">
      <Typography variant="h2">Invite Additional Users</Typography>
      <Typography variant="body2">
        Invite users and assign each a role. 10MB max file size for CSV
      </Typography>
      <APITable
        useGetData={useUsers}
        queryParams={{ api_key: false }}
        columnsVisibility={columns}
        tableSize="small"
        filterOptions={FILTER_OPTIONS}
        secondComponent={
          isAdmin ? (
            <Box display="flex" alignItems="center" flexDirection="row" flexWrap="wrap" gap="10px">
              <Button variant="text" onClick={() => setOpenImportCSV(true)}>
                Import CSV
              </Button>
              <Button onClick={() => setInviteUserOpen(true)}>Add</Button>
            </Box>
          ) : (
            <></>
          )
        }
        emptyTableComponent={
          <EmptyTable
            variant="role"
            label="Users"
            description={"Invite new users to join your organization\nand grow together!"}
            actionLabel="Invite New User"
            action={() => {}}
          />
        }
      />
      <InviteUserModal
        open={inviteUserOpen}
        onClose={() => {
          setInviteUserOpen(false);
          setSelectedUserId(null);
        }}
        openCreateDepartment={() => setOpenCreateDepartment(true)}
        userId={selectedUserId}
      />
      <CreateDepartmentModal
        open={openCreateDepartment}
        onClose={() => setOpenCreateDepartment(false)}
      />
      <ImportCSV
        open={openImportCSV}
        onClose={() => setOpenImportCSV(false)}
        addAttachment={async (attachment: File) => {
          if (organization) {
            await uploadCSV(organization?.id, attachment);
          }
        }}
      />
      <AddMultipleObjectModal
        open={addRoleOpen}
        onClose={() => {
          setSelectedUserId(null);
          setAddRoleOpen(false);
        }}
        onAdd={async (roleId: string[]) => {
          try {
            if (selectedUserId) {
              await Promise.all(
                roleId.map(async (id) => {
                  await addUserToRole(selectedUserId, id);
                })
              );
              // @TODO: add dispatch intercom event here
              // if (intercomEventName) {
              //   sendIntercomEvent(intercomEventName);
              // }
            }
            openSnackbar(`Added role to user`, "success");
          } catch (error) {
            if (error instanceof CustomAxiosError) {
              error.showAuditLogSnackbar(`Error adding role to user`);
            }
          }
        }}
        searchObjects={(search: string) => searchRoles(search)}
        objectName="Role"
        useGetData={useRoles}
        nameOptionKey="name"
        descriptionOptionKey="description"
      />
    </Box>
  );
};
