import { Box } from "@mui/material";
import { ScreenSmallerThen } from "../../utilities/UIHelper";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

type ProcessGraphLoaderProps = {
  height?: string;
  padding?: string;
};

export const ProcessGraphLoader = (props: ProcessGraphLoaderProps) => {
  const { height = "100%", padding = "10px" } = props;
  const isSmallScreen = ScreenSmallerThen("1100px");
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          padding: padding,
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <RectangularLoader
            maxWidth={isSmallScreen ? "100%" : "280px"}
            width="100%"
            height="35px"
          />
          <RectangularLoader width="117px" height="35px" />
        </Box>
        <RectangularLoader maxWidth="100%" height={height} />
      </Box>
    </>
  );
};
