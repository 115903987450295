import { Box, Typography, Select, MenuItem, useTheme } from "@mui/material";
import NavbarArrow from "../atoms/navbar/Arrow";

type TablePaginationProps = {
  /** The current page number. Starts from 1 */
  page: number;
  /** Number of rows to display per page */
  rowsPerPage: number;
  /** Total number of rows present */
  totalCount: number;
  /** Callback to change the number of rows per page */
  setRowsPerPage: (value: number) => void;
  /** Callback to change the page number */
  setPage: (newPageNumber: number) => void;
};

export const TablePagination = ({
  page,
  rowsPerPage,
  setRowsPerPage,
  setPage,
  totalCount,
}: TablePaginationProps) => {
  const totalPages = Math.ceil(totalCount / rowsPerPage);
  const theme = useTheme();
  const changeRowsPerPage = (newRowsPerPage: number) => {
    const newTotalPages = Math.ceil(totalCount / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    // Prevent the page number going out of bounds
    if (page > newTotalPages) {
      setPage(Math.max(1, newTotalPages));
    }
  };

  // hide self when there are no pages
  if (totalPages === 0) {
    return null;
  }

  const totalCountLabel = totalCount > 1 ? `/ ${totalCount} results` : ` / 1 result`;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Select
        value={rowsPerPage}
        sx={{ padding: 0, height: "35px", borderRadius: "3px" }}
        onChange={(event) => changeRowsPerPage(event.target.value as number)}
      >
        <MenuItem value={5}>Rows: 5</MenuItem>
        <MenuItem value={10}>Rows: 10</MenuItem>
        <MenuItem value={20}>Rows: 20</MenuItem>
      </Select>
      <Typography variant="body2" sx={{ paddingX: "10px" }} color={theme.palette.custom.gray}>
        {page} of {totalPages}
        {totalCountLabel}
      </Typography>
      <Box gap="10px" sx={{ display: "flex", flexDirection: "row" }}>
        <NavbarArrow disabled={page <= 1} direction="left" onClick={() => setPage(page - 1)} />
        <NavbarArrow
          disabled={page >= totalPages}
          direction="right"
          onClick={() => setPage(page + 1)}
        />
      </Box>
    </Box>
  );
};
