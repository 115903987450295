import { Box, Typography } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

type ChildrenBoxLoaderProps = {
  title?: string;
};

export const ChildrenBoxLoader = (props: ChildrenBoxLoaderProps) => {
  const { title } = props;

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" gap="5px" alignItems="center">
          {title ? (
            <Typography variant="h2">{title}</Typography>
          ) : (
            <RectangularLoader width="100px" height="30px" />
          )}
          <RectangularLoader width="30px" height="30px" />
        </Box>
        <RectangularLoader width="100px" height="35px" />
      </Box>
      <RectangularLoader width="100%" height="35px" />
      <RectangularLoader width="100%" height="35px" />
      <RectangularLoader width="100%" height="35px" />
    </>
  );
};
