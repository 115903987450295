import { JiraIssue, searchJiraIssues } from "../services/OrganizationService";
import { useAuth } from "../hooks/useAuth";
import { INewLinkedItem, LinkedItem } from "../models/IWorkflow";
import { SearchableDropdown } from "./SearchableDropdown";
import { Box, Typography, useTheme } from "@mui/material";
type JiraIssueSelectionProps = {
  values: LinkedItem[];
  setValues: React.Dispatch<React.SetStateAction<LinkedItem[]>>;
};

const adaptJiraIssue = (issue: JiraIssue): INewLinkedItem => {
  return {
    external_id: `${issue.id}`,
    created_by_webhook: false,
    integration_type: "JIRA",
    custom_fields: {
      summary: issue.summary,
      summaryText: issue.summaryText,
      key: issue.key,
    },
  };
};

export const JiraIssueSelection = (props: JiraIssueSelectionProps) => {
  const { values, setValues } = props;
  const theme = useTheme();
  const searchIssues = async (searchText: string) => {
    const res = await searchJiraIssues(searchText);
    const jiraIssues: LinkedItem[] = await Promise.all(
      res.data.map((issue: JiraIssue) => adaptJiraIssue(issue))
    );
    return jiraIssues;
  };
  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        Jira Issues
      </Typography>
      <SearchableDropdown
        multiple
        value={values}
        onChange={setValues}
        getOptions={searchIssues}
        getOptionLabel={(item) => item.custom_fields?.summaryText}
        isOptionEqualToValue={(a, b) => a.external_id === b.external_id}
        getSummaryDescription={(item) => [item.custom_fields?.summaryText, item.custom_fields?.key]}
      />
    </Box>
  );
};
