import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { ScreenSmallerThen } from "../../utilities/UIHelper";
import { ApiKeysLoader } from "../../organisms/skeleton-loaders/ApiKeysLoader";

export const UserAccessKeysLoader = () => {
  const theme = useTheme();
  const isSmallScreen = ScreenSmallerThen();
  const pageSize = isSmallScreen ? "unset" : `calc(80vh - 56px)`;
  return (
    <Box sx={{ maxWidth: "100%", height: pageSize, overflow: "visible" }}>
      <Box height="100%">
        <Box
          paddingBottom={"0px"}
          borderRadius="6px"
          border="1px solid"
          minHeight="100%"
          maxHeight="100%"
          overflow="auto"
          borderColor={theme.palette.custom.secondaryBorder}
          bgcolor={theme.palette.custom.secondaryBackground}
          sx={{ borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}
        >
          <Grid container columns={12} padding={2} display="flex" justifyContent="center">
            <Grid item xs={12} md={8} lg={6}>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <ApiKeysLoader title="Access Keys" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
