import { useQuery } from "react-query";
import { RegistryItemsFetchOptions } from "../models/types";
import { getRegistryItem, getRegistryItems } from "../services/RegistryItemsService";
import queryClient, { QueryKey } from "../state/QueryStore";
import { getVersionHistory } from "../services/VersionHistoryService";
import { AI_USE_CASE_REGISTRY_ID } from "../utilities/UIHelper";

export const useRegistryItems = (params: RegistryItemsFetchOptions) => {
  return useQuery([QueryKey.RegistryItem, params], async () => {
    const { data } = await getRegistryItems(params);
    return data;
  });
};

export const useRegistryItem = (itemId: string, version_num?: number) => {
  const searchForVersionNum = !!version_num;
  const queryKey = searchForVersionNum
    ? [QueryKey.RegistryItem, itemId, version_num]
    : [QueryKey.RegistryItem, itemId];
  const queryFn = searchForVersionNum
    ? async () => {
        const { data } = await getVersionHistory({
          object_id: [itemId],
          version_num: [version_num],
        });
        return JSON.parse(data.results[0].object);
      }
    : async () => {
        if (itemId === "") return null;
        const { data } = await getRegistryItem(itemId);
        return data;
      };
  return useQuery(queryKey, queryFn);
};

export const fetchRegistryItems = (params: RegistryItemsFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.RegistryItem, params], async () => {
    const { data } = await getRegistryItems(params);
    return data;
  });
};

export const fetchRegistryItem = (itemId: string) => {
  return queryClient.fetchQuery([QueryKey.RegistryItem, itemId], async () => {
    if (itemId === "") return null;
    const { data } = await getRegistryItem(itemId);
    return data;
  });
};

export const searchRegistryItems = async (searchText: string) => {
  const items = await fetchRegistryItems({ search: searchText });
  return items.results;
};

export const searchAIUseCaseRegistryItems = async (searchText: string) => {
  const items = await fetchRegistryItems({
    registry: [AI_USE_CASE_REGISTRY_ID],
    search: searchText,
  });
  return items.results;
};
