import { Box, Button, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GithubItemSelection } from "../../../components/GithubItemSelection";
import { navigateToImpactAssessmentReview } from "../../../components/ImpactAssessmentNavigator";
import { JiraIssueSelection } from "../../../components/JiraIssueSelection";
import { useOrganizationContext } from "../../../context/OrganizationContext";
import { openSnackbar } from "../../../context/SnackbarContext";
import { useExecutionTask } from "../../../hooks/useExecutionTask";
import { LinkedItem } from "../../../models/IWorkflow";
import { TaskComments } from "../../../molecules/tasks/TaskComments";
import { editExecutionTask } from "../../../services/ExecutionPlanService";
import { getImpactAssessment } from "../../../services/ImpactAssessmentService";
import { getQuestionnaire } from "../../../services/QuestionnaireService";
import { CustomAxiosError } from "../../../utilities/ErrorResponseHelper";
import { navigateToObject } from "../../../utilities/GenericNavigateHelper";
import { stripTrailingSpaces } from "../../../utilities/common";
import { SelectTaskStatus } from "../../impact-assessment/SelectTaskStatus";
import { ModalLoader } from "../../skeleton-loaders/ModalLoader";
import { SelectAssignee } from "../../tasks/SelectAssignee";

const LabelAndValue = ({
  label,
  value,
  onClick,
  color,
}: {
  label: string;
  value: string;
  onClick?: () => void;
  color?: string;
}) => {
  const theme = useTheme();
  const valueColor = color
    ? color
    : onClick
    ? theme.palette.custom.blueTypography
    : theme.palette.custom.secondaryTypography;
  return (
    <Box display="flex" flexDirection="row" gap="10px">
      <Typography minWidth="115px" variant="h3" color={theme.palette.custom.secondaryTypography}>
        {label}
      </Typography>
      <Typography
        onClick={() => onClick && onClick()}
        variant="body2"
        sx={{
          cursor: onClick ? "pointer" : "unset",
          textDecoration: onClick ? "underline" : "unset",
          "&:hover": {
            textDecoration: onClick ? "none" : "unset",
          },
        }}
        color={valueColor}
      >
        {value}
      </Typography>
    </Box>
  );
};

export const BaseTaskModal = (props: {
  taskId: string;
  edit?: boolean;
  setEdit: (value: boolean) => void;
  onClose: () => void;
}) => {
  const { taskId, edit = false, setEdit } = props;
  const { data: task, isLoading } = useExecutionTask(taskId);
  const { setSelectedTask, setSelectedQuestionnaire, setSelectedImpactAssessment } =
    useOrganizationContext();
  const navigate = useNavigate();
  const theme = useTheme();

  const [assignedUser, setAssignedUser] = useState<string | null>("");
  const [comments, setComments] = useState<string>("");
  const [status, setStatus] = useState<string | null>("Pending");

  const isJiraOrGithub =
    task?.action_type &&
    (task.action_type === "External-Jira" || task.action_type === "External-Github");
  const isJira = task?.action_type === "External-Jira";
  const isGithub = task?.action_type === "External-Github";
  const isCompleted = task?.status === "Completed";

  const [integrationValues, setIntegrationValues] = useState<LinkedItem[]>([]);

  const isQuestionnaireReviewTask = task?.task_type === "Questionnaire Review Task";
  const isQuestionReviewTask = task?.task_type === "Question Review Task";
  useEffect(() => {
    if (task) {
      setAssignedUser(task.assignee);
      setComments(task?.comments ?? "");
      setStatus(task.status as "Pending" | "Completed");
      setIntegrationValues(task.linked_items ?? []);
    }
  }, [task]);

  const handleUpdate = async () => {
    try {
      if (status !== "Pending" && status !== "Completed") {
        openSnackbar("Please select a valid status", "error");
        return;
      }
      const commentsFormatted = stripTrailingSpaces(comments);
      await editExecutionTask(taskId, {
        status: status,
        comments: commentsFormatted,
        assignee: assignedUser,
        custom_fields: {
          ...task?.custom_fields,
        },
        linked_items: integrationValues,
      });
      openSnackbar("Task was sucessfaully updated", "success");
      setEdit(false);
    } catch (error) {
      if (error instanceof CustomAxiosError) {
        error.showAuditLogSnackbar("Failed to save task, please try again");
      }
    }
  };

  const handleNavigateToObject = (type: "action" | "parent") => {
    if (task) {
      if (type === "action" && task.action) {
        navigateToObject(navigate, "Action", task.action, setSelectedTask);
      } else {
        navigateToObject(navigate, task.parent_type, task.parent_object_id, setSelectedTask);
      }
      setSelectedTask(null);
    }
  };
  return isLoading || !task ? (
    <ModalLoader />
  ) : (
    <Box display="flex" flexDirection="column" gap={"5px"}>
      {task?.parent_object?.name && (
        <LabelAndValue label="Name" value={task?.parent_object?.name} />
      )}
      <LabelAndValue label="Type" value={task.task_type} />
      {task?.parent_object_id && (
        <LabelAndValue
          onClick={() => handleNavigateToObject("parent")}
          label="Related Object"
          value={`${task?.parent_type}-${task?.parent_object?.value}`}
        />
      )}
      {task?.due_date && <LabelAndValue label="Due Date" value={task.due_date} />}
      {task?.action && (
        <LabelAndValue
          onClick={() => handleNavigateToObject("action")}
          label="Action"
          value={`Action-${task?.action_num}`}
        />
      )}
      <SelectTaskStatus
        edit={isQuestionnaireReviewTask ? false : edit}
        value={status}
        onChange={setStatus}
      />
      <SelectAssignee
        edit={edit}
        value={assignedUser}
        onChange={setAssignedUser}
        assignee_name={task?.assignee_name}
      />
      {!isJiraOrGithub && <TaskComments edit={edit} value={comments} onChange={setComments} />}
      {isJira && edit && (
        <JiraIssueSelection values={integrationValues} setValues={setIntegrationValues} />
      )}
      {isGithub && edit && (
        <GithubItemSelection values={integrationValues} setValues={setIntegrationValues} />
      )}
      {edit && !isCompleted && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          gap="10px"
        >
          <Button variant="contained" onClick={() => handleUpdate()} fullWidth={false}>
            Save
          </Button>
        </Box>
      )}
      {!edit &&
        (task.task_type === "Questionnaire Task" || isQuestionReviewTask) &&
        task.status !== "Completed" &&
        task?.metadata?.impact_assessment && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
            gap="10px"
          >
            <Button
              variant="contained"
              onClick={() => {
                setSelectedQuestionnaire(task.parent_object_id);
                setSelectedImpactAssessment(task?.metadata?.impact_assessment ?? null);
              }}
              fullWidth={false}
            >
              Launch
            </Button>
          </Box>
        )}
      {!edit &&
        task.task_type === "Questionnaire Review Task" &&
        task.status !== "Completed" &&
        task?.metadata?.impact_assessment && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
            gap="10px"
          >
            <Button
              variant="contained"
              onClick={async () => {
                if (task?.metadata?.impact_assessment) {
                  const { data: impactAssessment } = await getImpactAssessment(
                    task.metadata.impact_assessment
                  );
                  const { data: questionnaire } = await getQuestionnaire(task.parent_object_id);
                  navigateToImpactAssessmentReview({
                    navigate,
                    impactAssessmentId: impactAssessment.id,
                    impactAssessmentNumber: impactAssessment.impact_assessment_num,
                    questionnaireNumber: questionnaire?.questionnaire_num ?? 0,
                    questionnaireId: questionnaire?.id,
                  });
                  props.onClose();
                }
              }}
              fullWidth={false}
            >
              Launch
            </Button>
          </Box>
        )}
    </Box>
  );
};
