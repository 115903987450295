import { Box, useTheme } from "@mui/material";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { PageDetailLayout } from "../../components/layout/PageDetailLayout";
import { ElementMapping, EvidenceRequirements } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { ChildrenBoxLoader } from "../../organisms/skeleton-loaders/ChildrenBoxLoader";
import { DetailsLoader } from "../../organisms/skeleton-loaders/DetailsLoader";

export const LibraryDetailsLoader = () => {
  const theme = useTheme();

  const mappedElementsColumnVisibility: IColumnVisibility<ElementMapping>[] = [
    {
      field: "to_object_name",
      headerName: "Name",
      visible: true,
    },
    {
      field: "to_object_type",
      headerName: "Type",
      visible: true,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
    },
  ];

  const mappedEvidenceColumnVisibility: IColumnVisibility<EvidenceRequirements>[] = [
    {
      field: "summary",
      headerName: "Name",
      visible: true,
    },
    {
      field: "id",
      headerName: "ID",
      visible: true,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
    },
    {
      field: "status",
      headerName: "Status",
      visible: true,
    },
    {
      field: "checklist_num",
      headerName: "Checklist",
      visible: true,
    },
  ];

  const fillBlankSpace = true;

  return (
    <PageDetailLayout
      leftComponent={
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
          <SecondaryBox>
            <DetailsLoader />
          </SecondaryBox>
          <SecondaryBox>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <RectangularLoader width="200px" height="34px" />
              <RectangularLoader width="64px" height="34px" />
            </Box>
          </SecondaryBox>
          <Box
            sx={{
              maxWidth: "100%",
              width: "100%",
              height: fillBlankSpace ? "100%" : "unset",
              overflow: "visible",
            }}
          >
            <Box height={fillBlankSpace ? "100%" : "unset"}>
              <Box
                paddingBottom={"0px"}
                borderRadius="6px"
                border="1px solid"
                minHeight="100%"
                maxHeight="100%"
                overflow="auto"
                borderColor={theme.palette.custom.secondaryBorder}
                bgcolor={theme.palette.custom.secondaryBackground}
                sx={{ borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}
              >
                <APITableLoader
                  columnsVisibility={mappedElementsColumnVisibility}
                  tableSize="small"
                  secondComponent={true}
                  tableSearch={true}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: "100%",
              width: "100%",
              height: fillBlankSpace ? "100%" : "unset",
              overflow: "visible",
            }}
          >
            <Box height={fillBlankSpace ? "100%" : "unset"}>
              <Box
                paddingBottom={"0px"}
                borderRadius="6px"
                border="1px solid"
                minHeight="100%"
                maxHeight="100%"
                overflow="auto"
                borderColor={theme.palette.custom.secondaryBorder}
                bgcolor={theme.palette.custom.secondaryBackground}
                sx={{ borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}
              >
                <APITableLoader
                  columnsVisibility={mappedEvidenceColumnVisibility}
                  tableSize="small"
                  secondComponent={true}
                  tableSearch={true}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      }
      rightComponent={
        <Box display="flex" flexDirection="column" gap="20px">
          {[...Array(3)].map((_, idx) => (
            <SecondaryBox>
              <Box display="flex" width="100%" flexDirection="column" gap="10px">
                <ChildrenBoxLoader />
              </Box>
            </SecondaryBox>
          ))}
        </Box>
      }
    />
  );
};
