import { Avatar, Box, CircularProgress, Typography, useTheme } from "@mui/material";
import React from "react";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { useUsers } from "../services/UserSettingsService";

type Props = {
  /** user's name ex: john snow */
  userId?: string;
  name?: string;
  createdByFairo?: boolean;
  onClick?: () => void;
  orientation?: "horizontal" | "vertical";
  nameTypo?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "subtitle1"
    | "body1"
    | "button"
    | "body2"
    | "small"
    | "navbar"
    | "navbarSelected"
    | "logo"
    | "code";
};

const UserTag = ({
  userId = "",
  name,
  createdByFairo = false,
  nameTypo = "body2",
  onClick,
  orientation = "horizontal",
}: Props) => {
  const { data: user, isLoading } = useUsers(userId);
  const theme = useTheme();
  const isHorizontal = orientation === "horizontal";
  return !name && !userId ? null : isLoading ? (
    <CircularProgress size={15} />
  ) : (
    <Box
      display="flex"
      alignItems="center"
      flexDirection={isHorizontal ? "row" : "column"}
      gap="8px"
      sx={{ cursor: onClick ? "pointer" : "auto" }}
      onClick={onClick}
    >
      {createdByFairo && (
        <NavbarIcon
          variant="created-by-fairo"
          color="none"
          sx={{ width: "20px", height: "20px", minHeight: "20px", minWidth: "20px" }}
        />
      )}
      {!createdByFairo && (
        <NavbarIcon
          variant="user"
          color="none"
          sx={{ width: "20px", height: "20px", minHeight: "20px", minWidth: "20px" }}
        />
      )}
      {/* {!createdByFairo && <UserTagAvatar name={name} />} */}
      <Typography
        variant={nameTypo}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: onClick
            ? theme.palette.custom.blueTypography
            : theme.palette.custom.secondaryTypography,
          textDecoration: onClick ? "underline" : "none",
          "&:hover": {
            textDecoration: "none",
          },
        }}
      >
        {user ? user.name : name}
      </Typography>
    </Box>
  );
};
/** @TODO when adding user profile image functionality update this function */
const UserTagAvatar = ({ name }: { name: string }) => {
  const theme = useTheme();
  return (
    <Avatar
      sx={{
        height: 20,
        width: 20,
      }}
      alt={name}
      src={
        theme.palette.mode === "dark"
          ? "/assets/images/user-avatar-dark.svg"
          : "/assets/images/user-avatar.svg"
      }
    ></Avatar>
  );
};

export default UserTag;
export { UserTagAvatar };
