import { Box, Typography, useTheme } from "@mui/material";
import { UserCustomField } from "../../components/CustomFieldForm";
import { searchUsers } from "../../hooks/useUsers";
import { useUsers } from "../../services/UserSettingsService";
import UserTag from "../../components/UserTag";

type SelectAssigneeProps = {
  edit: boolean;
  value: string | null;
  onChange: (value: string) => void;
  assignee_name?: string | null;
};

export const SelectAssignee = (props: SelectAssigneeProps) => {
  const { edit, value, onChange, assignee_name } = props;
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection={edit ? "column" : "row"}
      gap="10px"
      alignItems={edit ? "flex-start" : "center"}
    >
      <Typography variant="h3" minWidth="115px" color={theme.palette.custom.secondaryTypography}>
        Assignee
      </Typography>
      {edit && (
        <UserCustomField
          value={value}
          onChange={onChange}
          searchUsers={searchUsers}
          useUser={useUsers}
          error={false}
          errorMessage=""
        />
      )}
      {!edit && assignee_name && <UserTag name={assignee_name ?? ""} />}
    </Box>
  );
};
