import { useQuery } from "react-query";
import { getRegistries, getRegistry } from "../services/RegistryService";
import queryClient, { QueryKey } from "../state/QueryStore";
import { RegistryFetchOptions } from "../models/types";
import { getVersionHistory } from "../services/VersionHistoryService";

export const useRegistries = (params: RegistryFetchOptions) => {
  return useQuery([QueryKey.Registry, params], async () => {
    const { data } = await getRegistries(params);
    return data;
  });
};

export const useRegistry = (
  registryId: string,
  params: { fairo_data: boolean },
  version_num?: number
) => {
  const searchForVersionNum = !!version_num;
  const queryKey = searchForVersionNum
    ? [QueryKey.Registry, registryId, version_num]
    : [QueryKey.Registry, registryId];
  const queryFn = searchForVersionNum
    ? async () => {
        const { data } = await getVersionHistory({
          object_id: [registryId],
          version_num: [version_num],
        });
        return JSON.parse(data.results[0].object);
      }
    : async () => {
        const { data } = await getRegistry(registryId, params);
        return data;
      };
  return useQuery(queryKey, queryFn);
};

export const fetchRegistries = (params: RegistryFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.Registry, params], async () => {
    const { data } = await getRegistries(params);
    return data;
  });
};

export const fetchRegistry = (registryId: string, params: { fairo_data: boolean }) => {
  return queryClient.fetchQuery([QueryKey.Registry, registryId], async () => {
    const { data } = await getRegistry(registryId, params);
    return data;
  });
};

export const searchRegistries = async (searchText: string) => {
  const registries = await fetchRegistries({ search: searchText });
  return registries.results;
};
