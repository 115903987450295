import { Stack, Typography } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

type ModalLoaderProps = {
  title?: string;
};

export const ModalLoader = (props: ModalLoaderProps) => {
  const { title } = props;
  return (
    <Stack gap="10px">
      {title && (
        <Typography variant="h2" fontWeight={600}>
          {title}
        </Typography>
      )}
      <RectangularLoader maxWidth="242px" height="17px" />
      <RectangularLoader maxWidth="100%" height="34px" />
      <RectangularLoader maxWidth="242px" height="17px" />
      <RectangularLoader maxWidth="100%" height="34px" />
      <RectangularLoader maxWidth="242px" height="17px" />
      <RectangularLoader maxWidth="100%" height="90px" />
      <RectangularLoader maxWidth="100%" height="34px" />
    </Stack>
  );
};
