import { ProcessGraphIcon } from "../components/AppIcons";
import { processGraphHooks } from "../state/QueryStore";
import { IGetListQueryParams } from "../state/QueryStoreHelper";
import AppStrings from "../strings";
import {
  IObjectCommonFields,
  ObjectDetailViewModel,
  ObjectListViewModel,
  parseObjectCommonFields,
} from "./IObjectCommonFields";

class ProcessGraphDetailViewModel extends ObjectDetailViewModel {
  process_graph_num: number;
  created_by: object;

  constructor(rawData: any) {
    const objectNumber = rawData.process_graph_num;
    super(rawData, objectNumber);
    this.process_graph_num = rawData.process_graph_num;
    this.created_by = rawData.created_by;
  }

  get objectIndicatorName() {
    return AppStrings.processGraph.indicatorLabel;
  }
  get objectIndicatorIcon() {
    return <></>;
  }

  get objectName() {
    return AppStrings.processGraph.name;
  }

  get objectHooks() {
    return processGraphHooks as any;
  }
}

interface IProcessGraph extends IObjectCommonFields {
  id: string;
  summary: string;
  description: string;
  processNumber: number;
}

interface ICreateProcessGraphRequestBody extends IUpdateProcessGraphRequestBody {
  workflow: string;
}

interface IUpdateProcessGraphRequestBody {
  summary: string;
  description: string;
}

interface IGetProcessGraphListRequestBody extends IGetListQueryParams {
  workflow_id: string;
}

const parseProcessGraphResult = (data: any): IProcessGraph => {
  return {
    id: data.id,
    summary: data.summary ?? "",
    description: data.description ?? "",
    processNumber: data.process_graph_num,
    ...parseObjectCommonFields(data),
  };
};

const parseCreateProcessGraphResult = (data: any): IProcessGraph => {
  return parseGetProcessGraphResult(data.id, data);
};

const parseGetProcessGraphResult = (id: string, data: any): IProcessGraph => {
  return parseProcessGraphResult(data);
};

export type {
  IProcessGraph,
  ICreateProcessGraphRequestBody,
  IUpdateProcessGraphRequestBody,
  IGetProcessGraphListRequestBody,
};
export {
  ProcessGraphDetailViewModel as ProcessGraphListViewModel,
  ProcessGraphDetailViewModel,
  parseProcessGraphResult,
  parseCreateProcessGraphResult,
  parseGetProcessGraphResult,
};
