import { Box, useTheme } from "@mui/material";
import {
  Action,
  Asset,
  AuditLog,
  ComplianceElement,
  Metric,
  Policy,
  Registry,
  Role,
  TestCase,
  VersionHistory,
  Workflow,
} from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { ScreenSmallerThen } from "../../utilities/UIHelper";

type ListViewLoaderProps = {
  type?: string;
  paddingTop?: boolean;
  bigFixedHeight?: string;
  columnsVisibility?: IColumnVisibility<any>[];
  tableSize?: "big" | "small";
};

export const ListViewLoader = (props: ListViewLoaderProps) => {
  const {
    type,
    paddingTop = true,
    bigFixedHeight = "80vh",
    columnsVisibility,
    tableSize = "big",
  } = props;
  const theme = useTheme();

  const assetColumnsVisibility: IColumnVisibility<Asset>[] = [
    {
      field: "asset_num",
      headerName: "Asset",
      visible: true,
    },
    { field: "type_name", headerName: "Asset Type", visible: true },
    { field: "name", headerName: "Name", visible: true },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
    },
  ];

  const workflowColumnsVisibility: IColumnVisibility<Workflow>[] = [
    {
      field: "workflow_num",
      headerName: "Workflow",
      visible: true,
    },
    { field: "summary", headerName: "Summary", visible: true },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
    },
  ];

  const actionColumnsVisibility: IColumnVisibility<Action>[] = [
    {
      field: "action_num",
      headerName: "Action",
      visible: true,
    },
    { field: "name", headerName: "Name", visible: true },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
    },
  ];

  const roleColumnsVisibility: IColumnVisibility<Role>[] = [
    {
      field: "role_num",
      headerName: "Role",
      visible: true,
    },
    { field: "name", headerName: "Name", visible: true },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
    },
  ];

  const policyColumnsVisibility: IColumnVisibility<Policy>[] = [
    {
      field: "policy_num",
      headerName: "Policy",
      visible: true,
    },
    {
      field: "description",
      headerName: "Description",
      visible: true,
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
    },
    {
      field: "id",
      headerName: "Remove",
      visible: true,
    },
  ];

  const registryColumnsVisibility: IColumnVisibility<Registry>[] = [
    {
      field: "name",
      headerName: "Name",
      visible: true,
    },
    {
      field: "id",
      headerName: "ID",
      visible: true,
    },
    {
      field: "registry_type_name",
      headerName: "Type",
      visible: true,
    },
    {
      field: "registry_fields",
      headerName: "Registered Items",
      visible: true,
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
    },
  ];

  const libraryColumnsVisibility: IColumnVisibility<ComplianceElement>[] = [
    {
      field: "name",
      headerName: "Name",
      visible: true,
    },
    {
      field: "external_id",
      headerName: "ID",
      visible: true,
    },
    {
      field: "element_type_name",
      headerName: "Type",
      visible: true,
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
    },
  ];

  const testCaseColumnsVisibility: IColumnVisibility<TestCase>[] = [
    {
      field: "test_case_num",
      headerName: "Test Case",
      visible: true,
    },
    { field: "name", headerName: "Name", visible: true },
    {
      field: "required_parameters",
      headerName: "Required Paramters",
      visible: true,
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
    },
  ];

  const metricColumnsVisibility: IColumnVisibility<Metric>[] = [
    {
      field: "name",
      headerName: "Metric",
      visible: true,
    },
    {
      field: "category",
      headerName: "Category",
      visible: true,
    },
    {
      field: "required_parameters",
      headerName: "Required Paramters",
      visible: true,
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
    },
  ];

  const auditLogColumnsVisibility: IColumnVisibility<AuditLog>[] = [
    {
      field: "updated_on",
      headerName: "Timestamp (UTC)",
      visible: true,
    },
    {
      field: "action",
      headerName: "Action",
      visible: true,
    },
    {
      field: "method",
      headerName: "Method",
      visible: true,
    },
    {
      field: "request_id",
      headerName: "Request ID",
      visible: true,
    },
    {
      field: "updated_by",
      headerName: "User",
      visible: true,
    },
    {
      field: "status",
      headerName: "Status",
      visible: true,
    },
    {
      field: "status_code",
      headerName: "Status Code",
      visible: true,
    },
  ];

  const versionHistoryColumnsVisibility: IColumnVisibility<VersionHistory>[] = [
    {
      field: "version_num",
      headerName: "Version ID",
      visible: true,
    },
    {
      field: "tag",
      headerName: "Version Name",
      visible: true,
    },
    {
      field: "tag",
      headerName: "Tag ID",
      visible: true,
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
    },
    {
      field: "id",
      headerName: "Version Tag",
      visible: true,
    },
  ];

  const fillBlankSpace = tableSize === "big";
  const isSmallScreen = ScreenSmallerThen("1100px");
  const bigFixedHeightSize = isSmallScreen
    ? "100%"
    : tableSize === "big"
    ? bigFixedHeight
    : "unset";

  return (
    <Box paddingTop={paddingTop ? "15px" : ""} paddingBottom="80px">
      <Box
        sx={{
          maxWidth: "100%",
          width: "100%",
          height: fillBlankSpace ? "100%" : "unset",
          overflow: "visible",
        }}
      >
        <Box height={bigFixedHeight ? bigFixedHeightSize : fillBlankSpace ? "100%" : "unset"}>
          <Box
            paddingBottom={"0px"}
            borderRadius="6px"
            border="1px solid"
            minHeight="100%"
            maxHeight="100%"
            overflow="auto"
            borderColor={theme.palette.custom.secondaryBorder}
            bgcolor={theme.palette.custom.secondaryBackground}
            sx={
              !columnsVisibility
                ? { borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }
                : { borderTopRightRadius: "6px", borderTopLeftRadius: "6px" }
            }
          >
            <APITableLoader
              columnsVisibility={
                type === "asset"
                  ? assetColumnsVisibility
                  : type === "workflow"
                  ? workflowColumnsVisibility
                  : type === "action"
                  ? actionColumnsVisibility
                  : type === "role"
                  ? roleColumnsVisibility
                  : type === "policy"
                  ? policyColumnsVisibility
                  : type === "registry"
                  ? registryColumnsVisibility
                  : type === "library"
                  ? libraryColumnsVisibility
                  : type === "testcase"
                  ? testCaseColumnsVisibility
                  : type === "metric"
                  ? metricColumnsVisibility
                  : type === "auditlog"
                  ? auditLogColumnsVisibility
                  : type === "version"
                  ? versionHistoryColumnsVisibility
                  : columnsVisibility
                  ? columnsVisibility
                  : []
              }
              tableSize={tableSize}
              secondComponent={true}
              tableSearch={true}
              filtersVisibility={false}
              dateFilters={false}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
