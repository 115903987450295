import { Box, useTheme } from "@mui/material";
import {
  DEFAULT_ICON_HEIGHT,
  DEFAULT_ICON_WIDTH,
  DEFAULT_INTERNAL_BORDER_RADIUS,
  ScreenSmallerThen,
} from "../../utilities/UIHelper";
import { INavbarIconVariant, NavbarIcon } from "../navbar/Icon";
import { NavbarLabel } from "../navbar/Label";

interface INavbarItem {
  label: string;
  variant: INavbarIconVariant;
  selected?: boolean;
  labelVisible?: boolean;
  fullWidth?: boolean;
  iconColor?: string;
  onClick?: () => void;
  iconSx?: {
    width?: string;
    height?: string;
    padding?: string;
  };
}

export const NavbarItem = ({
  label,
  variant,
  selected = false,
  labelVisible,
  fullWidth,
  iconColor,
  onClick,
  iconSx,
}: INavbarItem) => {
  const theme = useTheme();
  const isSmallScreen = ScreenSmallerThen();
  const externalBoxStyle = {
    "&: hover": {
      backgroundColor: theme.palette.custom.grayHover,
      borderRadius: DEFAULT_INTERNAL_BORDER_RADIUS,
    },
    borderRadius: DEFAULT_INTERNAL_BORDER_RADIUS,
    backgroundColor: selected ? theme.palette.custom.navButtonBackground : "background.paper",
    display: fullWidth ? "flex" : "inline-block",
    cursor: "pointer",
    justifyContent: isSmallScreen ? "center" : "flex-start",
    transition: "width 0.5s ease",
  };

  const internalBoxStyle = {
    "&: hover": {
      backgroundColor: theme.palette.custom.grayHover,
      borderRadius: DEFAULT_INTERNAL_BORDER_RADIUS,
    },
    cursor: "pointer",
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: labelVisible ? "flex-start" : "center",
    flexDirection: "row",
    transition: "width 0.5s ease",
    maxWidth: "120px",
    textOverflow: "ellipsis",
    overflow: "hidden",
  };

  const iconBoxStyle = {
    width: DEFAULT_ICON_WIDTH,
    height: DEFAULT_ICON_HEIGHT,
  };

  return (
    <Box sx={externalBoxStyle} onClick={() => (onClick ? onClick() : {})}>
      <Box sx={internalBoxStyle}>
        <Box sx={iconBoxStyle}>
          <NavbarIcon variant={variant} selected={selected} color={iconColor} sx={{ ...iconSx }} />
        </Box>
        {labelVisible && <NavbarLabel selected={selected} label={label} />}
      </Box>
    </Box>
  );
};
