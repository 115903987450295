import { Resource, SupportedElementMappingModels } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
} from "./ApiService";

const resourceEndpoints = {
  resources: () => "/resources",
  resource: (resourceId: string) => `/resources/${resourceId}`,
  sync: (resourceId: string) => `/resources/${resourceId}/sync`,
};

export const getResources = (params: any) => {
  return httpGetAuthenticated<ListResponse<Resource>>(resourceEndpoints.resources(), {
    params,
  });
};

export const getResource = (resourceId: string) => {
  return httpGetAuthenticated<Resource>(resourceEndpoints.resource(resourceId));
};

export const editResource = async (
  resourceId: string,
  payload: {
    mapped_resource: string;
    mapped_resource_type: SupportedElementMappingModels;
  }
) => {
  const { data } = await httpPatchAuthenticated(resourceEndpoints.resource(resourceId), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Resource, resourceId] });
  return data;
};

export const deleteResource = async (resourceId: string) => {
  const resp = await httpDeleteAuthenticated(resourceEndpoints.resource(resourceId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.Resource] });
  return resp;
};

export const syncResource = async (resourceId: string) => {
  const resp = await httpGetAuthenticated(resourceEndpoints.sync(resourceId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.Resource, resourceId] });
  return resp;
};
