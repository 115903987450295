import { Box, Button, MenuItem, Select, useTheme } from "@mui/material";
import { useState } from "react";
import { SmallModal } from "../components/SmallModal";
import { openSnackbar } from "../context/SnackbarContext";
import { useNotifications } from "../hooks/useNotification";
import { BaseFilters, FilterOption, Notification } from "../models/types";
import { IColumnVisibility } from "../molecules/ColumnVisibility";
import { EmptyTable } from "../molecules/EmptyTable";
import { TableTextWithEllipsis } from "../molecules/TableTextWithEllipsis";
import { editNotification, readAll } from "../services/NotificationService";
import { ScreenSmallerThen, defaultDateDisplay } from "../utilities/UIHelper";
import { APITable } from "./APITable";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { navigateToObject } from "../utilities/GenericNavigateHelper";
import { useNavigate } from "react-router-dom";
import { ExecutionTaskModal } from "./modals/ExecutionTaskModal";
import { useOrganizationContext } from "../context/OrganizationContext";

const FILTER_OPTIONS: FilterOption<BaseFilters>[] = [{ label: "Message", value: "message" }];

type NotificationsTableProps = {
  title?: string;
  filterOption?: boolean;
  bigFixedHeight?: string;
  titleInline?: boolean;
  sx?: {
    borderTopRightRadius?: string;
    borderTopLeftRadius?: string;
    borderBottomLeftRadius?: string;
    borderBottomRightRadius?: string;
  };
  tableSize?: "small" | "big";
  onClose?: () => void;
};

export const NotificationsTable = (props: NotificationsTableProps) => {
  const {
    title,
    filterOption = true,
    bigFixedHeight,
    titleInline = false,
    sx,
    tableSize = "small",
    onClose,
  } = props;
  const [loading, setLoading] = useState(false);
  const [selectedObject, setSelectedObject] = useState<string | null>(null);
  const [readFilter, setReadFilter] = useState<"All" | "Unread" | "Read">("Unread");
  const readFilterOptions = ["All", "Unread", "Read"];
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = ScreenSmallerThen();
  const { setReportsOpen, setSelectedTask } = useOrganizationContext();
  const handleReadItem = async (notification: Notification) => {
    try {
      setLoading(true);
      await editNotification(notification.id, { read: !notification.read });
      openSnackbar("Message was updated", "success");
    } catch (error) {
      openSnackbar("Failed to mark as read", "error");
    } finally {
      setLoading(false);
    }
  };
  const columnsVisibility: IColumnVisibility<Notification>[] = [
    {
      field: "message",
      headerName: "Message",
      visible: true,
      renderer: (notification) => (
        <Box
          width="100%"
          display="flex"
          gap="5px"
          alignItems="center"
          flexDirection="row"
          justifyContent="flex-start"
        >
          <Box
            onClick={() => handleReadItem(notification)}
            display="flex"
            alignItems="center"
            sx={{ cursor: "pointer" }}
          >
            <NavbarIcon
              variant={notification.read ? "radio-unchecked" : "radio-checked"}
              sx={{
                height: "18px",
                width: "18px",
                minHeight: "18px",
                minWidth: "18px",
              }}
              color={notification.read ? undefined : theme.palette.custom.blueTypography}
            />
          </Box>
          <TableTextWithEllipsis expanded={true} hideTooltip={true} value={notification.message} />
        </Box>
      ),
    },
    {
      field: "to_object_id",
      headerName: "ID",
      visible: true,
      renderer: (notification) => (
        <TableTextWithEllipsis
          onClick={() => {
            if (!notification.read) {
              handleReadItem(notification);
            }
            navigateToObject(
              navigate,
              notification.to_object_type,
              notification.to_object_id,
              setSelectedTask
            );
            if (onClose) {
              onClose();
            }
            if (notification.to_object_type === "Report") {
              setReportsOpen(true);
            }
          }}
          value={notification?.value_name ?? ""}
        />
      ),
    },
    {
      field: "to_object_type",
      headerName: "Type",
      visible: true,
      renderer: (notification) => <TableTextWithEllipsis value={notification.to_object_type} />,
    },
    {
      field: "created_on",
      headerName: "Date",
      visible: true,
      renderer: (notification) => (
        <TableTextWithEllipsis value={defaultDateDisplay(notification.created_on) ?? ""} />
      ),
    },
  ];

  const handleReadAll = async () => {
    try {
      setLoading(true);
      await readAll();
      openSnackbar("All messages were updated", "success");
    } catch (error) {
      openSnackbar("Failed to mark all as read", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <APITable
        useGetData={useNotifications}
        columnsVisibility={columnsVisibility}
        queryParams={{
          ...(readFilter !== "All" && {
            "read[]": readFilter === "Read" ? "True" : "False",
          }),
        }}
        smallEmptyHeight={true}
        filterOptions={filterOption ? FILTER_OPTIONS : undefined}
        emptyTableComponent={
          <EmptyTable
            variant="empty-box"
            label="No notifications"
            description={`New notifications will appear here.`}
          />
        }
        secondComponent={
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexGrow: 1,
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Select
              onChange={(e) => setReadFilter(e.target.value as any)}
              fullWidth
              size="small"
              sx={{
                maxWidth: isSmallScreen ? "100%" : "175px",
              }}
              value={readFilter ?? "All"}
            >
              {readFilterOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            <Button variant="text" onClick={() => handleReadAll()} disabled={loading}>
              Mark all as read
            </Button>
          </Box>
        }
        tableSize={tableSize}
        title={title}
        bigFixedHeight={bigFixedHeight}
        titleInline={titleInline}
        sx={sx}
      />
      {/** @todo Make it generic to differnt modal types or implement navigate + modal open on navigateToObject method */}
    </>
  );
};
