import { Box, Button, Typography, useTheme } from "@mui/material";
import { INavbarIconVariant, NavbarIcon } from "../atoms/navbar/Icon";

interface IEmptyTable {
  variant?: INavbarIconVariant;
  label?: string;
  description?: string;
  action?: () => void;
  actionLabel?: string;
  disabled?: boolean;
}

export const EmptyTable = ({
  variant,
  label,
  description,
  action,
  actionLabel,
  disabled = false,
}: IEmptyTable) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      {variant && (
        <NavbarIcon
          variant={variant}
          color={theme.palette.custom.grayTypography}
          sx={{ width: "64px", height: "64px" }}
        />
      )}
      {label && (
        <Typography
          variant="h2"
          sx={{ color: theme.palette.custom.grayTypography, paddingTop: "10px" }}
        >
          {label}
        </Typography>
      )}
      {description && (
        <Typography
          textAlign="center"
          whiteSpace="pre-wrap"
          variant="body2"
          sx={{ color: theme.palette.custom.grayTypography, paddingTop: "10px" }}
        >
          {description}
        </Typography>
      )}
      {action && actionLabel && (
        <Button sx={{ marginTop: "10px" }} onClick={() => action()} disabled={disabled}>
          {actionLabel}
        </Button>
      )}
    </Box>
  );
};
