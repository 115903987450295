import { useQuery } from "react-query";
import { ProcessGraphFetchOptions } from "../models/types";
import { getProcessGraph, getProcessGraphs } from "../services/ExecutionPlanService";
import queryClient, { QueryKey } from "../state/QueryStore";
import { getVersionHistory } from "../services/VersionHistoryService";

export const useProcessGraphs = (params: ProcessGraphFetchOptions, returnEmpty = false) => {
  return useQuery([QueryKey.ProcessGraph, params], async () => {
    if (returnEmpty)
      return {
        results: [],
        count: 0,
        next: null,
        previous: null,
      };
    const { data } = await getProcessGraphs(params);
    return data;
  });
};

export const fetchProcessGraphs = (params: ProcessGraphFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.ProcessGraph, params], async () => {
    const { data } = await getProcessGraphs(params);
    return data;
  });
};

export const useProcessGraph = (processId: string, version_num?: number) => {
  const searchForVersionNum = !!version_num;
  const queryKey = searchForVersionNum
    ? [QueryKey.ProcessGraph, processId, version_num]
    : [QueryKey.ProcessGraph, processId];
  const queryFn = searchForVersionNum
    ? async () => {
        const { data } = await getVersionHistory({
          object_id: [processId],
          version_num: [version_num],
        });
        return JSON.parse(data.results[0].object);
      }
    : async () => {
        if (processId === "") return null;
        const { data } = await getProcessGraph(processId);
        return data;
      };
  return useQuery(queryKey, queryFn);
};

export const searchProcessGraphs = async (searchText: string, workflowId?: string) => {
  const processGraphs = await fetchProcessGraphs({
    workflow: workflowId ?? "",
    search: searchText,
  });
  return processGraphs.results;
};
