import { ImpactAssessmentType } from "../models/types";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { httpGetAuthenticated } from "./ApiService";

const impactAssessmentTypeEndpoints = {
  impactAssessmentTypes: () => "/impact_assessment_types",
  impactAssessmentType: (impactAssessmentTypeId: string) =>
    `/impact_assessment_types/${impactAssessmentTypeId}`,
};

export const getImpactAssessmentTypes = (params: any) => {
  return httpGetAuthenticated<ListResponse<ImpactAssessmentType>>(
    impactAssessmentTypeEndpoints.impactAssessmentTypes(),
    {
      params: {
        ...params,
        fairo_data: true,
      },
    }
  );
};

export const getImpactAssessmentType = (impactAssessmentTypeId: string) => {
  return httpGetAuthenticated<ImpactAssessmentType>(
    impactAssessmentTypeEndpoints.impactAssessmentType(impactAssessmentTypeId),
    {
      params: {
        fairo_data: true,
      },
    }
  );
};
