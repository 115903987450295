import { LoadingButton } from "@mui/lab";
import { Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { SmallModal } from "../../../components/SmallModal";
import { openSnackbar } from "../../../context/SnackbarContext";
import { useAuth } from "../../../hooks/useAuth";
import { addReport } from "../../../services/ReportService";
import { CustomInputValidation } from "../../CustomInputValidation";

export const GenerateReportModal = (props: { open: boolean; onClose: () => void }) => {
  const { open, onClose } = props;
  const theme = useTheme();
  const innerTextColor = theme.palette.custom.secondaryTypography;
  const [frameworks, setFrameworks] = useState<string[]>([]);
  const [users, setUsers] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useAuth();
  useEffect(() => {
    setFrameworks([]);
    setUsers(user ? [user.id] : []);
  }, [open]);

  const generateReport = async () => {
    try {
      setLoading(true);
      await addReport({
        report_type: "Overview",
        users,
        related_objects: frameworks.map((framework) => ({
          object_id: framework,
          object_type: "Framework",
        })),
      });
      setLoading(false);
      openSnackbar(
        "Report successfully requested, we will send a notification when it's ready",
        "success"
      );
      onClose();
    } catch (error) {
      openSnackbar("Failed to generate report", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <SmallModal size={"small"} open={open} onClose={onClose} title="Generate Report">
      <Stack gap="10px">
        <Typography variant="body2" color={theme.palette.custom.grayTypography}>
          All reports generated are accessible on the organization page or via email to the specific
          users selected.
        </Typography>
        <Typography variant="h3" color={innerTextColor}>
          General Report{frameworks.length ? ` (${frameworks.length})` : ""}
        </Typography>
        <CustomInputValidation
          value={frameworks}
          onChange={setFrameworks}
          required={true}
          valueError={false}
          errorMessage={"Please select at least one framework"}
          field_type="Framework list"
        />
        <Stack gap="5px">
          <Typography variant="h3" color={innerTextColor}>
            Receiving Users
          </Typography>
          <Typography variant="body2" color={theme.palette.custom.grayTypography}>
            These users will be notified when the report is ready.
          </Typography>
        </Stack>
        <CustomInputValidation
          value={users}
          onChange={setUsers}
          required={true}
          valueError={false}
          errorMessage={"Please select at least one user"}
          field_type="User list"
        />
        <LoadingButton
          disabled={!users.length || !frameworks.length}
          variant="contained"
          loading={loading}
          onClick={() => generateReport()}
        >
          Generate
        </LoadingButton>
      </Stack>
    </SmallModal>
  );
};
