import axios from "axios";
import { parseObjectCustomFields } from "../models/IObjectCustomField";
import { parseObjectFileAttachments } from "../models/IObjectFileAttachment";
import { LinkedItem } from "../models/IWorkflow";
import {
  ExecutionItemFetchOptions,
  ExecutionPlanFetchOptions,
  ExecutionTaskFetchOptions,
  ProcessGraph,
  ProcessGraphFetchOptions,
  ProcessNode,
  ProcessNodeFetchOptions,
  Workflow,
  WorkflowFetchOptions,
} from "../models/types";
import { ExecutionItem, ExecutionPlan, ExecutionTask } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const executionPlanEndpoints = {
  workflows: () => "/workflows",
  workflow: (workflowId: string) => `/workflows/${workflowId}`,
  processGraphs: () => "/process_graphs",
  processGraph: (graphId: string) => `/process_graphs/${graphId}`,
  processNodes: () => `/process_nodes`,
  processNode: (nodeId: string) => `/process_nodes/${nodeId}`,
  executionPlans: () => "/process_execution_plans",
  executionPlan: (planId: string) => `/process_execution_plans/${planId}`,
  executionItems: () => "/process_execution_items",
  executionItem: (itemId: string) => `/process_execution_items/${itemId}`,
  executionTasks: () => "/tasks",
  executionTask: (taskId: string) => `/tasks/${taskId}`,
  nodeAddAction: (nodeId: string) => `/process_nodes/${nodeId}/add_action`,
  nodeRemoveAction: (nodeId: string) => `/process_nodes/${nodeId}/remove_action`,
  nodeAddTestCase: (nodeId: string) => `/process_nodes/${nodeId}/add_test_case`,
  nodeRemoveTestCase: (nodeId: string) => `/process_nodes/${nodeId}/remove_test_case`,
  nodeAddAsset: (nodeId: string) => `/process_nodes/${nodeId}/add_asset`,
  nodeRemoveAsset: (nodeId: string) => `/process_nodes/${nodeId}/remove_asset`,
};

export const addActionToNode = async (nodeId: string, actionId: string) => {
  const resp = await httpPostAuthenticated(executionPlanEndpoints.nodeAddAction(nodeId), {
    action_id: actionId,
  });
  queryClient.invalidateQueries({ queryKey: [QueryKey.Action] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.ProcessNode] });

  return resp;
};

export const removeActionToNode = async (nodeId: string, actionId: string) => {
  const resp = await httpPostAuthenticated(executionPlanEndpoints.nodeRemoveAction(nodeId), {
    action_id: actionId,
  });
  queryClient.invalidateQueries({ queryKey: [QueryKey.Action] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.ProcessNode] });

  return resp;
};

export const addTestCaseToNode = async (nodeId: string, testCaseId: string) => {
  const resp = await httpPostAuthenticated(executionPlanEndpoints.nodeAddTestCase(nodeId), {
    test_case_id: testCaseId,
  });
  queryClient.invalidateQueries({ queryKey: [QueryKey.TestCase] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.ProcessNode] });

  return resp;
};

export const removeTestCaseToNode = async (nodeId: string, testCaseId: string) => {
  const resp = await httpPostAuthenticated(executionPlanEndpoints.nodeRemoveTestCase(nodeId), {
    test_case_id: testCaseId,
  });
  queryClient.invalidateQueries({ queryKey: [QueryKey.TestCase] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.ProcessNode] });

  return resp;
};

export const addAssetToNode = async (nodeId: string, assetId: string) => {
  const resp = await httpPostAuthenticated(executionPlanEndpoints.nodeAddAsset(nodeId), {
    asset_id: assetId,
  });
  queryClient.invalidateQueries({ queryKey: [QueryKey.Asset] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.ProcessNode] });

  return resp;
};

export const removeAssetToNode = async (nodeId: string, assetId: string) => {
  const resp = await httpPostAuthenticated(executionPlanEndpoints.nodeRemoveAsset(nodeId), {
    asset_id: assetId,
  });
  queryClient.invalidateQueries({ queryKey: [QueryKey.Asset] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.ProcessNode] });

  return resp;
};

export const getWorkflows = (params: WorkflowFetchOptions) => {
  return httpGetAuthenticated<ListResponse<Workflow>>(executionPlanEndpoints.workflows(), {
    params,
  });
};

export const getWorkflow = (workflowId: string) => {
  return httpGetAuthenticated<Workflow>(executionPlanEndpoints.workflow(workflowId));
};

export const addWorkflow = async (workflowData: { summary: string; description?: string }) => {
  const resp = await httpPostAuthenticated<Workflow>(
    executionPlanEndpoints.workflows(),
    workflowData
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.Workflow] });
  return resp;
};

export const editWorkflow = async (
  workflowId: string,
  workflowData: {
    summary: string;
    description?: string;
  }
) => {
  const resp = await httpPatchAuthenticated(
    executionPlanEndpoints.workflow(workflowId),
    workflowData
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.Workflow] });
  return resp;
};

export const deleteWorkflow = async (workflowId: string) => {
  const resp = await httpDeleteAuthenticated(executionPlanEndpoints.workflow(workflowId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.Workflow] });
  return resp;
};

export const getProcessGraphs = (params: ProcessGraphFetchOptions) => {
  return httpGetAuthenticated<ListResponse<ProcessGraph>>(executionPlanEndpoints.processGraphs(), {
    params,
  });
};

export const getProcessGraph = (processGraphId: string) => {
  return httpGetAuthenticated<ProcessGraph>(executionPlanEndpoints.processGraph(processGraphId));
};

export const addProcessGraph = async (graphData: {
  workflow: string;
  summary: string;
  description?: string;
}) => {
  const resp = await httpPostAuthenticated<ProcessGraph>(
    executionPlanEndpoints.processGraphs(),
    graphData
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.ProcessGraph] });
  return resp;
};

export const editProcessGraph = async (
  planId: string,
  planData: {
    summary: string;
    description?: string;
  }
) => {
  const resp = await httpPatchAuthenticated(executionPlanEndpoints.processGraph(planId), planData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.ProcessGraph] });
  return resp;
};

export const deleteProcessGraph = async (graphId: string) => {
  const resp = await httpDeleteAuthenticated(executionPlanEndpoints.processGraph(graphId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.ProcessGraph] });
  return resp;
};

export const getProcessNodes = (params: ProcessNodeFetchOptions) => {
  return httpGetAuthenticated<ListResponse<ProcessNode>>(executionPlanEndpoints.processNodes(), {
    params,
  });
};

export const deleteProcessNode = async (nodeId: string) => {
  const resp = await httpDeleteAuthenticated(executionPlanEndpoints.processNode(nodeId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.ProcessNode] });
  return resp;
};

export const addProcessNode = async (nodeData: {
  process_graph: string;
  summary: string;
  description?: string;
}) => {
  const resp = await httpPostAuthenticated<ProcessNode>(
    executionPlanEndpoints.processNodes(),
    nodeData
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.ProcessNode] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.processNodesGet] });
  return resp.data;
};

export const getProcessNode = async (nodeId: string) => {
  return httpGetAuthenticated<ProcessNode>(executionPlanEndpoints.processNode(nodeId));
};

export const editProcessNode = async (
  nodeId: string,
  nodeData: {
    summary: string;
    description?: string;
  }
) => {
  const resp = await httpPatchAuthenticated(executionPlanEndpoints.processNode(nodeId), nodeData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.ProcessNode, nodeId] });
  return resp;
};

export const getExecutionPlans = (params: ExecutionPlanFetchOptions) => {
  return httpGetAuthenticated<ListResponse<ExecutionPlan>>(
    executionPlanEndpoints.executionPlans(),
    {
      params,
      transformResponse: ([] as any[]).concat(axios.defaults.transformResponse, (data: any) => {
        data.results?.forEach((datum: any) => {
          datum.customFields = parseObjectCustomFields(datum.custom_fields);
          datum.fileAttachments = parseObjectFileAttachments(datum.file_attachments);
        });
        return data;
      }),
    }
  );
};

export const getExecutionPlan = (executionPlanId: string) => {
  return httpGetAuthenticated<ExecutionPlan>(
    executionPlanEndpoints.executionPlan(executionPlanId),
    {
      transformResponse: ([] as any[]).concat(axios.defaults.transformResponse, (data: any) => {
        data.customFields = parseObjectCustomFields(data.custom_fields);
        data.fileAttachments = parseObjectFileAttachments(data.file_attachments);
        return data;
      }),
    }
  );
};

export const addExecutionPlan = async (workflow: string, plan_data: any) => {
  const resp = await httpPostAuthenticated<ExecutionPlan>(
    executionPlanEndpoints.executionPlans(),
    plan_data,
    {
      params: { workflow },
    }
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.ExecutionPlan] });
  return resp;
};

export const editExecutionPlan = async (planId: string, planData: any) => {
  const resp = await httpPatchAuthenticated(executionPlanEndpoints.executionPlan(planId), planData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.ExecutionPlan] });
  return resp;
};

export const deleteExecutionPlan = async (planId: string) => {
  const resp = await httpDeleteAuthenticated(executionPlanEndpoints.executionPlan(planId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.ExecutionPlan] });
  return resp;
};

type ItemData = {
  status?: string;
  start_date?: Date;
  due_date?: Date;
  completed_date?: Date;
  process_node: string;
  process_execution_plan: string;
  assignee?: string;
  collaborators?: string[];
};

export const getExecutionItems = (params: ExecutionItemFetchOptions) => {
  return httpGetAuthenticated<ListResponse<ExecutionItem>>(
    executionPlanEndpoints.executionItems(),
    { params }
  );
};

export const getExecutionItem = (executionItemId: string) => {
  return httpGetAuthenticated<ExecutionItem>(executionPlanEndpoints.executionItem(executionItemId));
};

export const editExecutionItem = async (itemId: string, itemData: any) => {
  const resp = await httpPatchAuthenticated(executionPlanEndpoints.executionItem(itemId), itemData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.ExecutionItem, itemId] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.ExecutionItem] });
  return resp;
};

export const addExecutionItem = async (itemData: ItemData) => {
  const resp = await httpPostAuthenticated(executionPlanEndpoints.executionItems(), itemData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.ExecutionItem] });
  return resp;
};

export const deleteExecutionItem = async (itemId: string) => {
  const resp = await httpDeleteAuthenticated(executionPlanEndpoints.executionItem(itemId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.ExecutionItem] });
  return resp;
};

export const getExecutionTasks = (params: ExecutionTaskFetchOptions) => {
  return httpGetAuthenticated<ListResponse<ExecutionTask>>(
    executionPlanEndpoints.executionTasks(),
    {
      params,
    }
  );
};

export const getExecutionTask = (taskId: string) => {
  return httpGetAuthenticated<ExecutionTask>(executionPlanEndpoints.executionTask(taskId));
};

export const editExecutionTask = async (
  taskId: string,
  data: {
    comments?: string;
    assignee?: string | null;
    status?: "Pending" | "Completed";
    custom_fields?: { [key: string]: any };
    linked_items?: LinkedItem[];
  }
) => {
  const resp = await httpPatchAuthenticated(executionPlanEndpoints.executionTask(taskId), data);
  queryClient.invalidateQueries({ queryKey: [QueryKey.ExecutionTask] });
  return resp;
};

export const addExecutionTask = async (taskData: any) => {
  const resp = await httpPostAuthenticated(executionPlanEndpoints.executionTasks(), taskData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.ExecutionTask] });
  return resp;
};

export const deleteExecutionTask = async (taskId: string) => {
  const resp = await httpDeleteAuthenticated(executionPlanEndpoints.executionTask(taskId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.ExecutionTask] });
  return resp;
};
