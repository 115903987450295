import { Box, useTheme } from "@mui/material";
import { ScreenSmallerThen } from "../utilities/UIHelper";
import NavbarBox from "../atoms/navbar/Box";
import React, { useState } from "react";
import NavbarArrow from "../atoms/navbar/Arrow";

interface IExpandableBoxProps {
  children: React.ReactElement;
  expanded: boolean;
  onExpand: () => void;
}

const ExpandableBox = ({ children, expanded, onExpand }: IExpandableBoxProps) => {
  const theme = useTheme();
  const isSmallScreen = ScreenSmallerThen();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        zIndex: 1300,
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "-ms-overflow-style": "none" /* IE and Edge */,
        "scrollbar-width": "none" /* Firefox */,
        overflowX: "visible",
        minWidth: isSmallScreen ? "100%" : expanded ? "145px" : "72px",
      }}
    >
      <NavbarBox expanded={expanded}>{children}</NavbarBox>
      {!isSmallScreen && (
        <Box sx={{ paddingTop: "74px", width: "24px", marginLeft: "-12px", zIndex: 1999 }}>
          <NavbarArrow direction={expanded ? "left" : "right"} onClick={() => onExpand()} />
        </Box>
      )}
    </Box>
  );
};

export default ExpandableBox;
