import { Box, Typography } from "@mui/material";
import { NavbarIcon } from "./Icon";

interface IFairoLogoProps {
  labelVisible?: boolean;
  width: string;
}

const FairoLogo = ({ labelVisible, width }: IFairoLogoProps) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <NavbarIcon variant="fairo-logo" color="none" sx={{ width: "27px", height: "33px" }} />
      {labelVisible && (
        <Typography variant="logo" sx={{ paddingLeft: "7px" }}>
          Fairo
        </Typography>
      )}
    </Box>
  );
};

export default FairoLogo;
