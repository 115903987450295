import { CreateReportProps, Report, ReportFetchOptions } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { httpGetAuthenticated, httpPostAuthenticated } from "./ApiService";

const reportEndpoints = {
  reports: () => "/reports",
  report: (reportId: string) => `/reports/${reportId}`,
  reportReadAll: () => "/reports/read_all",
};

export const getReport = (reportId: string) => {
  return httpGetAuthenticated<Report>(reportEndpoints.report(reportId));
};

export const getReports = (params: ReportFetchOptions) => {
  return httpGetAuthenticated<ListResponse<Report>>(reportEndpoints.reports(), {
    params,
  });
};

export const readAll = async () => {
  const { data } = await httpGetAuthenticated<{ message: string }>(reportEndpoints.reportReadAll());
  queryClient.invalidateQueries({ queryKey: [QueryKey.Report] });
  return data;
};

export const addReport = async (payload: CreateReportProps) => {
  const { data } = await httpPostAuthenticated<Report>(reportEndpoints.reports(), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Report] });
  return data;
};
