import { ImpactAssessment, ImpactAssessmentFetchOptions } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./ApiService";

const impactAssessmentEndpoints = {
  impactAssessments: () => "/impact_assessments",
  impactAssessment: (impactAssessmentId: string) => `/impact_assessments/${impactAssessmentId}`,
  review: (impactAssessmentId: string) => `/impact_assessments/${impactAssessmentId}/review`,
};

export const getImpactAssessments = (params: ImpactAssessmentFetchOptions) => {
  return httpGetAuthenticated<ListResponse<ImpactAssessment>>(
    impactAssessmentEndpoints.impactAssessments(),
    {
      params: {
        ...params,
        fairo_data: true,
      },
    }
  );
};

export const getImpactAssessment = (impactAssessmentId: string) => {
  return httpGetAuthenticated<ImpactAssessment>(
    impactAssessmentEndpoints.impactAssessment(impactAssessmentId),
    {
      params: {
        fairo_data: true,
      },
    }
  );
};

export const createImpactAssessmentReview = (
  impactAssessmentId: string,
  payload: {
    responses: {
      field_id: string;
      response_id: string;
    }[];
    questionnaire: string;
  }
) => {
  return httpPostAuthenticated(impactAssessmentEndpoints.review(impactAssessmentId), payload);
};

export const editImpactAssessmentMetadata = (
  impactAssessmentId: string,
  payload: { metadata?: any; sections?: any }
) => {
  const response = httpPatchAuthenticated(
    impactAssessmentEndpoints.impactAssessment(impactAssessmentId),
    payload
  );
  queryClient.invalidateQueries([QueryKey.ImpactAssessment, impactAssessmentId]);
  return response;
};

export const createImpactAssessment = async (payload: any) => {
  const { data } = await httpPostAuthenticated(
    impactAssessmentEndpoints.impactAssessments(),
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.ImpactAssessment] });
  return data;
};
