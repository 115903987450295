import { GithubItem, searchGithubItems } from "../services/OrganizationService";
import { useAuth } from "../hooks/useAuth";
import { INewLinkedItem, LinkedItem } from "../models/IWorkflow";
import { SearchableDropdown } from "./SearchableDropdown";
import { Box, Typography, useTheme } from "@mui/material";
type GithubItemSelectionProps = {
  values: LinkedItem[];
  setValues: React.Dispatch<React.SetStateAction<LinkedItem[]>>;
};

export const GithubItemSelection = (props: GithubItemSelectionProps) => {
  const { values, setValues } = props;
  const { organization } = useAuth();
  const theme = useTheme();
  const adaptGithubItem = (item: GithubItem): INewLinkedItem => {
    return {
      external_id: `${item.id}`,
      created_by_webhook: false,
      integration_type: "GITHUB",
      custom_fields: {
        event: !!item.pull_request ? "pull_request" : "issue",
        summary: item.title,
        summaryText: item.body,
        number: item.number,
        url: item.html_url,
      },
    };
  };

  const searchItems = async (searchText: string) => {
    const res = await searchGithubItems(organization!.id, searchText);
    const githubResponse: LinkedItem[] = await Promise.all(
      res.data.map((item: GithubItem) => adaptGithubItem(item))
    );
    return githubResponse;
  };

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        Github Issues
      </Typography>
      <SearchableDropdown
        multiple
        value={values}
        onChange={setValues}
        getOptions={searchItems}
        getOptionLabel={(item) => item.custom_fields?.summaryText}
        isOptionEqualToValue={(a, b) => a.external_id === b.external_id}
        getSummaryDescription={(item) => [
          `${item.custom_fields?.summary} (${
            item.custom_fields?.event === "pull_request" ? "PR" : "ISSUE"
          }-${item.custom_fields?.number})`,
          item.custom_fields?.summaryText,
        ]}
      />
    </Box>
  );
};
