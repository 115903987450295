import { ICustomField, CustomFieldType } from "./ICustomField";
import { CustomFieldValue } from "./types";

interface ICustomFieldValue extends ICustomField {
  valueId: string;
}

interface IObjectCustomFieldString extends ICustomFieldValue {
  type: "String";
  value: string;
}
interface IObjectCustomFieldDate extends ICustomFieldValue {
  type: "Date";
  value: Date;
}
interface IObjectCustomFieldNumber extends ICustomFieldValue {
  type: "Number";
  value: number;
}
interface IObjectCustomFieldJSON extends ICustomFieldValue {
  type: "JSON";
  value: string;
}
interface IObjectCustomFieldUser extends ICustomFieldValue {
  type: "User";
  /**user id */
  value: string;
}

type ObjectCustomField =
  | IObjectCustomFieldString
  | IObjectCustomFieldDate
  | IObjectCustomFieldNumber
  | IObjectCustomFieldJSON
  | IObjectCustomFieldUser;

interface ICreateObjectCustomFieldRequestBody {
  /**field_id */
  custom_field: string;
  value: any;
}

interface IUpdateObjectCustomFieldRequestBody {
  value: any;
}

/**Parses custom field list of object (workflow, process, etc) */
const parseObjectCustomFields = (data: any[] | undefined): CustomFieldValue[] => {
  if (!data) return [];

  return data.map((e) => {
    const type = e.field_type;
    return {
      id: e.id,
      field_id: e.field_id,
      field_name: e.field_name,
      description: e.description,
      field_type: e.field_type,
      value: parseFieldValue(type, e.value),
    };
  });
};

const parseFieldValue = (type: CustomFieldType, value: any): any => {
  switch (type) {
    case "String":
      return value as string;
    case "JSON":
      return value as string;
    case "Number":
      return value as number;
    case "Date":
      return new Date(value);
    case "User":
      return value as string;
    default:
      throw "not implemented";
  }
};

export type {
  ObjectCustomField,
  ICreateObjectCustomFieldRequestBody,
  IUpdateObjectCustomFieldRequestBody,
};
export { parseObjectCustomFields };
