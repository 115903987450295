import { Box, useTheme } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { OrganizationOverviewLoader } from "../../organisms/skeleton-loaders/OrganizationOverviewLoader";

export const OrganizationPageLoader = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        border: 0,
      }}
    >
      <Box
        sx={{
          borderBottom: "0px",
          paddingY: "10px",
          borderColor: "divider",
          borderWidth: "1px",
          borderRadius: "6px",
          borderStyle: "solid",
          backgroundColor: theme.palette.background.paper,
          marginTop: "15px",
          overflowY: "auto",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", paddingX: "10px" }}>
          <Box
            sx={{
              minHeight: "35px",
              display: "flex",
              gap: "10px",
            }}
          >
            <RectangularLoader height="35px" width="108px" />
            <RectangularLoader height="35px" width="108px" />
            <RectangularLoader height="35px" width="108px" />
            <RectangularLoader height="35px" width="108px" />
          </Box>
          <RectangularLoader height="35px" width="143px" />
        </Box>
      </Box>
      <OrganizationOverviewLoader />
    </Box>
  );
};
