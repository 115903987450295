import AppStrings from "../strings";
import { ObjectDetailViewModel, ObjectListViewModel } from "./IObjectCommonFields";

interface IProcessEdge {
  id: string;
  fromProcesNodeId: string;
  toProcessNodeId: string;
  summary: string;
}

class ProcessEdgeListViewModel extends ObjectListViewModel {
  fromProcesNodeId: string;
  toProcessNodeId: string;
  constructor(rawData: any) {
    const objectNumber = rawData.process_node_num;
    super(rawData, objectNumber);

    this.fromProcesNodeId = rawData.process_node_from;
    this.toProcessNodeId = rawData.process_node_to;
  }

  get objectIndicatorName() {
    return AppStrings.processEdge.indicatorLabel;
  }
  get objectIndicatorIcon() {
    return <div />;
  }

  get objectName() {
    return AppStrings.processEdge.name;
  }

  get objectHooks() {
    return {} as any;
  }
}

class ProcessEdgeDetailViewModel extends ObjectDetailViewModel {
  constructor(rawData: any) {
    const objectNumber = rawData.process_node_num;
    super(rawData, objectNumber);
  }

  get objectIndicatorName() {
    return AppStrings.processEdge.indicatorLabel;
  }
  get objectIndicatorIcon() {
    return <div />;
  }

  get objectName() {
    return AppStrings.processEdge.name;
  }

  get objectHooks() {
    return {} as any;
  }
}

interface ICreateProcessEdgeRequestBody extends IUpdateProcessEdgeRequestBody {
  process_node_from: string;
  process_node_to: string;
  summary: string;
}

interface IUpdateProcessEdgeRequestBody {
  summary: string;
}

const parseCreateProcessEdgeResult = (data: any): IProcessEdge => {
  return {
    id: data.id,
    fromProcesNodeId: data.process_node_from,
    toProcessNodeId: data.process_node_to,
    summary: data.summary ?? "",
  };
};

export type { IProcessEdge, ICreateProcessEdgeRequestBody, IUpdateProcessEdgeRequestBody };
export { ProcessEdgeListViewModel, ProcessEdgeDetailViewModel, parseCreateProcessEdgeResult };
