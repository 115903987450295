import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSubscription } from "../../context/SubscriptionContext";
import { openSnackbar } from "../../context/SnackbarContext";
import { AxiosError } from "axios";
import { ConfirmationModal } from "../ConfirmationModal";

interface IProps {
  isOpen: boolean;
  handleModal: Function;
}

export const CancelPlanModal = (Props: IProps) => {
  const { isOpen, handleModal } = Props;
  const { cancel } = useSubscription();
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const openConfirmation = () => {
    setIsConfirmationOpen(true);
  };

  const closeConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const cancelSubscription = async () => {
    setIsCancelling(true);
    try {
      await cancel();
    } catch (e) {
      const errorMessage = (e as AxiosError<{ detail: string }>)?.response?.data?.detail;
      openSnackbar(errorMessage ?? "Failed to cancel subscription", "error");
    } finally {
      setIsCancelling(false);
      handleModal(false);
    }
  };

  return (
    <div>
      <ConfirmationModal
        open={isOpen}
        onClose={() => {
          handleModal(false);
        }}
        title="Cancel Plan"
        description="Are you sure you want to cancel your Core plan? Once you cancel your plan you will lose all the benefits."
        acceptText="Yes, cancel my plan"
        rejectText="No, I want to continue with my plan"
        onAccept={openConfirmation}
        onReject={() => {
          handleModal(false);
        }}
      />

      <ConfirmationModal
        open={isConfirmationOpen}
        onClose={closeConfirmation}
        title="Confirm Cancellation"
        description="Are you sure you want to proceed with the cancellation?"
        acceptText="Yes, Cancel Plan"
        rejectText="No, Keep Plan"
        onAccept={cancelSubscription}
        onReject={closeConfirmation}
      />
    </div>
  );
};
