import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavbarItem } from "../atoms/filter/NavbarItem";
import CloseNavbarButton from "../atoms/navbar/Close";
import { INavbarIconVariant } from "../atoms/navbar/Icon";
import FairoLogo from "../atoms/navbar/Logo";
import NavbarTitle from "../atoms/navbar/Title";
import config from "../config/config";
import { useSubscription } from "../context/SubscriptionContext";
import { useAppThemeContext } from "../context/ThemeContext";
import { useAuth } from "../hooks/useAuth";
import ExpandableBox from "../molecules/ExpandableBox";
import MobileNavbar from "../molecules/MobileNavbar";
import { ScreenSmallerThen } from "../utilities/UIHelper";

const ColumnOrientedBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }} gap="5px">
      {children}
    </Box>
  );
};

const CenteredBox = ({ children }: { children: React.ReactNode }) => {
  return <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>{children}</Box>;
};

const LogoBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "0px 0 30px 0",
      }}
    >
      {children}
    </Box>
  );
};

interface IMenuItemProps {
  variant: INavbarIconVariant;
  label: string;
  route: string;
  isEnterpriseFeature: boolean;
}

const toolKitItems: IMenuItemProps[] = [
  {
    variant: "compliance",
    label: "Oversight",
    route: "/oversight",
    isEnterpriseFeature: false,
  },
  {
    variant: "role",
    label: "Roles",
    route: "/roles",
    isEnterpriseFeature: false,
  },
  {
    variant: "workflow",
    label: "Workflows",
    route: "/workflows",
    isEnterpriseFeature: true,
  },
  {
    variant: "test",
    label: "Test Suite",
    route: "/tests",
    isEnterpriseFeature: true,
  },
  {
    variant: "asset",
    label: "Assets",
    route: "/assets",
    isEnterpriseFeature: true,
  },
  {
    variant: "action",
    label: "Actions",
    route: "/actions",
    isEnterpriseFeature: false,
  },
  {
    variant: "resources",
    label: "Resources",
    route: "/resources",
    isEnterpriseFeature: true,
  },
];

interface INavbarProps {
  userDisplayName: string;
  organizationName: string;
  hasOrganization: boolean;
  handleTasksModal: () => void;
  userHasActiveTasks?: boolean;
  showFeaturePaywall: (route: string) => void;
}

const Navbar = ({
  userDisplayName,
  organizationName,
  hasOrganization,
  userHasActiveTasks,
  handleTasksModal,
  showFeaturePaywall,
}: INavbarProps) => {
  const [menuMobileOpen, setMenuMobileOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const { navbarExpanded, setNavbarExpanded } = useAppThemeContext();
  const { signOut } = useAuth();
  const { toggleTheme } = useAppThemeContext();
  const isDarkMode = theme.palette.mode === "dark";
  const expandedPaddingProps = navbarExpanded ? "15px 6px" : "24px 6px";
  const isSmallScreen = ScreenSmallerThen();
  const { pathname } = useLocation();
  useEffect(() => {
    if (menuMobileOpen) setNavbarExpanded(true);
  }, [menuMobileOpen]);

  const handleNavigate = (route: string) => {
    navigate(route);
    setMenuMobileOpen(false);
  };

  const {
    createSubscription,
    updateSubscription,
    subscriptions,
    currentPlan,
    isCancelled,
    isUpdating,
    plans,
  } = useSubscription();
  const isMLFlowSubscribed =
    currentPlan?.name === "Fairo Premium" ||
    currentPlan?.name === "Fairo Trial" ||
    currentPlan?.name === "Fairo Enterprise";
  const isEnterprise = currentPlan?.name === "Fairo Enterprise";
  return (
    <>
      {((isSmallScreen && menuMobileOpen) || !isSmallScreen) && (
        <ExpandableBox
          expanded={navbarExpanded}
          onExpand={() => setNavbarExpanded(!navbarExpanded)}
        >
          <>
            <ColumnOrientedBox>
              <LogoBox>
                <FairoLogo labelVisible={navbarExpanded} width={navbarExpanded ? "87px" : "27px"} />
              </LogoBox>
              <ColumnOrientedBox>
                <NavbarTitle
                  label={navbarExpanded ? "Organization" : ""}
                  padding={expandedPaddingProps}
                />
                <NavbarItem
                  label={organizationName}
                  variant="organization"
                  onClick={() => handleNavigate("/organization")}
                  selected={pathname.includes("/organization")}
                  labelVisible={navbarExpanded}
                  fullWidth={true}
                />
              </ColumnOrientedBox>
              <ColumnOrientedBox>
                <NavbarTitle
                  label={navbarExpanded ? "Toolkit" : ""}
                  padding={expandedPaddingProps}
                />
                {toolKitItems.map((item) => (
                  <NavbarItem
                    label={item.label}
                    variant={item.variant}
                    selected={pathname.includes(item.route)}
                    onClick={() => {
                      if (item.isEnterpriseFeature && !isEnterprise) {
                        showFeaturePaywall(item.route.replace("/", ""));
                        return;
                      }
                      handleNavigate(item.route);
                    }}
                    labelVisible={navbarExpanded}
                    fullWidth={true}
                  />
                ))}
              </ColumnOrientedBox>
            </ColumnOrientedBox>
            <ColumnOrientedBox>
              {navbarExpanded && <NavbarTitle label={"More"} padding={expandedPaddingProps} />}
              <NavbarItem
                label="MLFlow"
                variant="mlflow"
                labelVisible={navbarExpanded}
                onClick={
                  isEnterprise
                    ? () => {
                        window.open(`${config.mlflow.baseUrl}/mlflow-static`, "_blank");
                      }
                    : () => {
                        showFeaturePaywall("mlflow");
                      }
                }
                fullWidth={true}
              />
              <NavbarItem
                label={isDarkMode ? "Light mode" : "Dark mode"}
                variant={isDarkMode ? "light-theme" : "dark-theme"}
                labelVisible={navbarExpanded}
                onClick={() => toggleTheme()}
                fullWidth={true}
              />
              <NavbarItem
                label="Notifications"
                variant={userHasActiveTasks ? "notification_alert" : "notification"}
                labelVisible={navbarExpanded}
                onClick={() => handleTasksModal()}
                fullWidth={true}
              />
              <NavbarItem
                iconColor="none"
                label={userDisplayName}
                onClick={() => handleNavigate("/user/settings")}
                variant="user"
                selected={pathname.includes("/user/settings")}
                labelVisible={navbarExpanded}
                fullWidth={true}
                iconSx={{ padding: "6px" }}
              />
              <NavbarItem
                label="Logout"
                variant="signout"
                labelVisible={navbarExpanded}
                onClick={() => {
                  signOut();
                  handleNavigate("/login");
                }}
                fullWidth={true}
              />
              {isSmallScreen && (
                <CenteredBox>
                  <CloseNavbarButton onClick={() => setMenuMobileOpen(!menuMobileOpen)} />
                </CenteredBox>
              )}
            </ColumnOrientedBox>
          </>
        </ExpandableBox>
      )}
      {isSmallScreen && !menuMobileOpen && (
        <MobileNavbar expanded={true} onExpand={() => setMenuMobileOpen(!menuMobileOpen)} />
      )}
    </>
  );
};

export default Navbar;
