import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, CardActions, Divider, Typography, useTheme } from "@mui/material";
import { ComponentType, useState } from "react";
import { Awaitable } from "../utilities/common";
type PlanCardProps = {
  /** Whether the plan is active */
  isActive?: boolean;
  /** Title of the plan */
  title: string;
  /** Subtitle of the plan */
  subtitle: string;
  /** Summary text for the plan */
  summary: string[];
  /** Text to display on the CTA button */
  buttonText: string;
  /** Callback when button is clicked */
  onClick: () => Awaitable<void>;
  /** Whether to disable the button */
  disabled?: boolean;
};

export const PlanCard = (props: PlanCardProps) => {
  const { isActive, title, subtitle, summary, buttonText, onClick, disabled } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const clickCallback = async () => {
    setLoading(true);
    try {
      await onClick();
    } finally {
      setLoading(false);
    }
  };
  const theme = useTheme();
  const borderColor = isActive
    ? theme.palette.custom.primaryBorder
    : theme.palette.custom.secondaryBorder;

  const backgroundColor = isActive
    ? theme.palette.custom.cardBackgroundActive
    : theme.palette.custom.cardBackgroundInactive;
  const price = subtitle.split("/")[0];
  // check if price don't contain numbers
  const isEnterprise = !/\d/.test(price);
  return (
    <Box
      display="flex"
      width="325px"
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="column"
      borderRadius="6px"
      border={`1px solid ${borderColor}`}
      padding="15px"
      gap="15px"
      sx={{ backgroundColor: backgroundColor }}
    >
      <Typography variant="h2">
        {title}
        {isActive ? ` - (Active Plan)` : null}
      </Typography>
      {!isEnterprise && (
        <Typography variant="h2" color={theme.palette.custom.blue}>
          {`${price}/`}
          <span style={{ color: theme.palette.custom.primaryTypography }}>User</span>
        </Typography>
      )}
      {isEnterprise && (
        <Typography variant="h2" color={theme.palette.custom.blue}>
          {price}
        </Typography>
      )}
      {/* <Typography variant="body2">{description}</Typography> */}
      <Divider
        sx={{ height: "1px", width: "100%", borderColor: theme.palette.custom.secondaryBorder }}
      />
      <Typography variant="h3">What's Included:</Typography>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        gap="15px"
      >
        <Box display="flex" flexDirection="column" alignItems="center" gap="15px">
          {summary.map((text, idx) => (
            <Typography key={idx} variant="body2" textAlign="center">
              <span style={{ color: theme.palette.custom.blue }}>•</span>&nbsp;{text}
            </Typography>
          ))}
        </Box>

        <Button
          color="primary"
          disabled={loading || isActive}
          fullWidth={true}
          onClick={() => clickCallback()}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};
