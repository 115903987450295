import { Registry, RegistryType } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const registryEndpoints = {
  registries: () => "/registries",
  registry: (registryId: string) => `/registries/${registryId}`,
  add_field: (registryId: string) => `/registries/${registryId}/add_field?fairo_data=true`,
  remove_field: (registryId: string) => `/registries/${registryId}/remove_field?fairo_data=true`,
};

export const getRegistry = (registryId: string, params: { fairo_data: boolean }) => {
  return httpGetAuthenticated<Registry>(registryEndpoints.registry(registryId), {
    params: {
      ...params,
      fairo_data: true,
    },
  });
};

export const getRegistries = (params: any) => {
  return httpGetAuthenticated<ListResponse<Registry>>(registryEndpoints.registries(), {
    params: {
      ...params,
      fairo_data: true,
    },
  });
};

export const addRegistry = async (payload: {
  name: string;
  description?: string;
  registry_type: string;
  registry_fields?: string[];
}) => {
  const { data } = await httpPostAuthenticated<Registry>(registryEndpoints.registries(), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Registry] });
  return data;
};

export const editRegistry = async (
  registryId: string,
  payload: {
    name?: string;
    description?: string;
    registry_type?: string;
    registry_items?: string[];
    registry_fields?: string[];
  }
) => {
  const { data } = await httpPatchAuthenticated(registryEndpoints.registry(registryId), payload);
  await queryClient.invalidateQueries({ queryKey: [QueryKey.Registry, registryId] });
  return data;
};

export const addRegistryFieldToRegistry = async (
  registryId: string,
  payload: {
    registry_field_id: string;
  }
) => {
  const { data } = await httpPostAuthenticated(registryEndpoints.add_field(registryId), payload);
  await queryClient.invalidateQueries({ queryKey: [QueryKey.Registry, registryId] });
  return data;
};

export const removeRegistryFieldFromRegistry = async (
  registryId: string,
  payload: {
    registry_field_id: string;
  }
) => {
  const { data } = await httpPostAuthenticated(registryEndpoints.remove_field(registryId), payload);
  await queryClient.invalidateQueries({ queryKey: [QueryKey.Registry, registryId] });
  return data;
};

export const deleteRegistry = async (registryId: string) => {
  const resp = await httpDeleteAuthenticated(registryEndpoints.registry(registryId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.Registry] });
  return resp;
};
