import { useQuery } from "react-query";
import { EvidenceRequirementFetchOptions } from "../models/types";
import {
  getEvidenceRequirement,
  getEvidenceRequirements,
} from "../services/EvidenceRequirementsService";
import { getVersionHistory } from "../services/VersionHistoryService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useEvidenceRequirements = (params: EvidenceRequirementFetchOptions) => {
  const queryKey = [QueryKey.EvidenceRequirement, params];
  return useQuery(queryKey, async () => {
    const { data } = await getEvidenceRequirements(params);
    return data;
  });
};

export const useEvidenceRequirement = (evidenceId: string, params?: { version_num?: number }) => {
  const searchForVersionNum = !!params?.version_num;
  const queryKey = searchForVersionNum
    ? [QueryKey.EvidenceRequirement, evidenceId, params.version_num]
    : [QueryKey.EvidenceRequirement, evidenceId];
  const queryFn = searchForVersionNum
    ? async () => {
        const { data } = await getVersionHistory({
          object_id: [evidenceId],
          version_num: [params.version_num],
        });
        return JSON.parse(data.results[0].object);
      }
    : async () => {
        const { data } = await getEvidenceRequirement(evidenceId);
        return data;
      };
  return useQuery(queryKey, queryFn);
};

export const fetchEvidenceRequirements = (params: EvidenceRequirementFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.EvidenceRequirement, params], async () => {
    const { data } = await getEvidenceRequirements(params);
    return data;
  });
};

export const fetchEvidenceRequirement = (evidenceId: string) => {
  return queryClient.fetchQuery([QueryKey.EvidenceRequirement, evidenceId], async () => {
    const { data } = await getEvidenceRequirement(evidenceId);
    return data;
  });
};

export const searchEvidenceRequirements = async (searchText: string) => {
  const evidences = await fetchEvidenceRequirements({ search: searchText });
  return evidences.results;
};
