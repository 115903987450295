import { Box, Button, Typography, useTheme } from "@mui/material";
import { StringCustomField } from "../../components/CustomFieldForm";
import { Table } from "../../components/Table";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { useState } from "react";
import { NameAndDescriptionCustomFieldModal } from "../modals/custom-fields/NameAndDescriptionCustomFieldModal";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { openSnackbar } from "../../context/SnackbarContext";
import DeleteIconButton from "../../components/DeleteIconButton";
import { EmptyTable } from "../../molecules/EmptyTable";

type NameAndDescriptionFieldProps = {
  value: string | null;
  onChange: (newValue: string | null) => void;
  required?: boolean;
  valueError: boolean;
  errorMessage: string;
};

type NameAndDescriptionListFieldProps = {
  value: string[] | null;
  onChange: (newValue: string[] | null) => void;
  required?: boolean;
  valueError: boolean;
  errorMessage: string;
  showAddButton: boolean;
  modalTitle: string;
  tableDescription?: React.ReactNode;
};
/**
 *
 * @param NameAndDescriptionFieldProps
 * This for will handle the name and description fields outputing value as a stringified JSON object
 * with the keys name and description
 * This was designed to allow users create intended users that will be stored as question answers
 * But the component can be reused where ever it is needed
 * @returns
 */
export const NameAndDescriptionField = (props: NameAndDescriptionFieldProps) => {
  const { value, onChange, required, valueError, errorMessage } = props;
  const theme = useTheme();

  const handleChangeName = (newValue: string) => {
    const oldValue = JSON.parse(value || "{}");
    const newValueJson = { ...oldValue, name: newValue };
    onChange(JSON.stringify(newValueJson));
  };

  const handleChangeDescription = (newValue: string) => {
    const oldValue = JSON.parse(value || "{}");
    const newValueJson = { ...oldValue, description: newValue };
    onChange(JSON.stringify(newValueJson));
  };

  const getValue = (key: string) => {
    const valueJson = JSON.parse(value || "{}");
    return valueJson[key];
  };

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        Name
      </Typography>
      <StringCustomField
        required={required}
        value={getValue("name")}
        onChange={handleChangeName}
        error={valueError}
        errorMessage={errorMessage}
      />
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        Description
      </Typography>
      <StringCustomField
        rows={3}
        required={required}
        value={getValue("description")}
        onChange={handleChangeDescription}
        error={valueError}
        errorMessage={errorMessage}
      />
    </Box>
  );
};

/**
 *
 * @param NameAndDescriptionListFieldProps
 * This for will handle the name and description fields outputing value as a stringified JSON object
 * with the keys name and description
 * This was designed to allow users create intended users that will be stored as question answers
 * But the component can be reused where ever it is needed
 * @returns
 */

type NameAndDescriptionObj = {
  id: string;
  name: string;
  description: string;
};
export const NameAndDescriptionListField = (props: NameAndDescriptionListFieldProps) => {
  const { value, onChange, showAddButton, modalTitle, tableDescription } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedForEdit, setSelectedForEdit] = useState<NameAndDescriptionObj | null>(null);
  const theme = useTheme();
  const columnVisibility: IColumnVisibility<{ id: string; name: string; description: string }>[] = [
    {
      field: "name",
      headerName: "Name",
      visible: true,
      renderer: (row) => (
        <TableTextWithEllipsis
          onClick={() => {
            setSelectedForEdit(row);
            setModalOpen(true);
          }}
          value={row.name}
        />
      ),
    },
    {
      field: "description",
      headerName: "Description",
      visible: true,
      renderer: (row) => <TableTextWithEllipsis value={row.description} />,
    },
    {
      field: "id",
      headerName: "",
      visible: true,
      renderer: (row) => (
        <Box display="flex" justifyContent="center">
          <DeleteIconButton
            deleteConfirmationDialogTitle="Remove option"
            deleteConfirmationButtonDescription="Remove"
            deleteConfirmationDialogDescription={`Are you sure you want to remove ${row.name}?`}
            onDeleteConfirm={() => {
              const newValueJson = [...(value || [])];
              newValueJson.splice(parseInt(row.id), 1);
              onChange(newValueJson);
            }}
            filledButton={false}
          />
        </Box>
      ),
    },
  ];

  const onAdd = (newValue: string) => {
    try {
      const isUpdate = selectedForEdit !== null;
      if (isUpdate) {
        const newValueJson = [...(value || [])];
        newValueJson[parseInt(selectedForEdit.id)] = newValue;
        onChange(newValueJson);
      } else {
        const newValueJson = [...(value || []), newValue];
        onChange(newValueJson);
      }
    } catch (error) {
      openSnackbar("Failed to set name and description", "error");
    } finally {
      setSelectedForEdit(null);
    }
  };

  const rows = value?.map((item, index) => {
    const parsedItem = JSON.parse(item);
    return {
      id: index.toString(),
      name: parsedItem?.name,
      description: parsedItem?.description,
    };
  });

  return (
    <Box display="flex" gap="10px" width="100%" maxWidth="100%" flexDirection="column">
      {showAddButton && (
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          {tableDescription}
          <Button onClick={() => setModalOpen(true)} variant="contained">
            Add
          </Button>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth="100%"
        borderRadius="6px"
        border="1px solid"
        borderTop={0}
        overflow="auto"
        borderColor={theme.palette.custom.secondaryBorder}
      >
        <Table
          borderTopRadius="6px"
          smallEmptyHeight={true}
          columnsVisibility={columnVisibility}
          isLoading={false}
          rows={rows || []}
          emptyTableComponent={
            <EmptyTable
              label="No options added yet"
              description="Add an option"
              action={() => {
                setModalOpen(true);
              }}
            />
          }
        />
      </Box>
      <NameAndDescriptionCustomFieldModal
        selectedForEdit={selectedForEdit}
        open={modalOpen}
        onClose={() => {
          setSelectedForEdit(null);
          setModalOpen(false);
        }}
        onAdd={onAdd}
        title={modalTitle}
      />
    </Box>
  );
};
