import { useQuery } from "react-query";
import { ReportFetchOptions } from "../models/types";
import { getReport, getReports } from "../services/ReportService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useReports = (params: ReportFetchOptions) => {
  return useQuery([QueryKey.Report, params], async () => {
    const { data } = await getReports(params);
    return data;
  });
};

export const useReport = (notificationId: string) => {
  return useQuery([QueryKey.Report], async () => {
    const { data } = await getReport(notificationId);
    return data;
  });
};

export const fetchReports = (params: ReportFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.Report, params], async () => {
    const { data } = await getReports(params);
    return data;
  });
};

export const searchReport = async (searchText: string) => {
  const tests = await fetchReports({ search: searchText });
  return tests.results;
};
