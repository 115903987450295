import { Box, useTheme } from "@mui/material";
import { PageDetailLayout } from "../../components/layout/PageDetailLayout";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { DetailsLoader } from "../../organisms/skeleton-loaders/DetailsLoader";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { ChildrenBoxLoader } from "../../organisms/skeleton-loaders/ChildrenBoxLoader";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { ExecutionItem, ExecutionTask } from "../../models/types";
import PrimaryBox from "../../atoms/box/PrimaryBox";
import { ProcessGraphLoader } from "../../organisms/skeleton-loaders/ProcessGraphLoader";

export const ExecutionPlanDetailsLoader = () => {
  const theme = useTheme();

  const itemsColumnsVisibility: IColumnVisibility<ExecutionItem>[] = [
    {
      field: "process_execution_item_num",
      headerName: "Item ID",
      visible: true,
    },
    {
      field: "due_date",
      headerName: "Due Date",
      visible: true,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      visible: true,
    },
    {
      field: "completed_date",
      headerName: "Completed Date",
      visible: true,
    },
    {
      field: "status",
      headerName: "Status",
      visible: true,
    },
    {
      field: "assignee",
      headerName: "Assignee",
      visible: true,
    },
    {
      field: "collaborators",
      headerName: "Collaborators",
      visible: true,
    },
  ];

  const tasksColumnsVisibility: IColumnVisibility<ExecutionTask>[] = [
    {
      field: "action_type",
      headerName: "Type",
      visible: true,
    },
    {
      field: "task_num",
      headerName: "Task",
      visible: true,
    },
    {
      field: "parent_object",
      headerName: "Execution",
      visible: true,
    },
    {
      field: "action",
      headerName: "Action",
      visible: true,
    },
    {
      field: "due_date",
      headerName: "Due Date",
      visible: true,
    },
  ];

  const fillBlankSpace = true;

  return (
    <PageDetailLayout
      leftComponent={
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
          <SecondaryBox>
            <DetailsLoader />
          </SecondaryBox>
          <SecondaryBox>
            <Box>
              <ProcessGraphLoader height="30vh" padding="0px" />
            </Box>
          </SecondaryBox>
          <SecondaryBox>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <RectangularLoader width="200px" height="34px" />
              <RectangularLoader width="64px" height="34px" />
            </Box>
          </SecondaryBox>
          <Box
            sx={{
              maxWidth: "100%",
              width: "100%",
              height: fillBlankSpace ? "100%" : "unset",
              overflow: "visible",
            }}
          >
            <Box height={fillBlankSpace ? "100%" : "unset"}>
              <Box
                paddingBottom={"0px"}
                borderRadius="6px"
                border="1px solid"
                minHeight="100%"
                maxHeight="100%"
                overflow="auto"
                borderColor={theme.palette.custom.secondaryBorder}
                bgcolor={theme.palette.custom.secondaryBackground}
                sx={{ borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}
              >
                <APITableLoader
                  columnsVisibility={itemsColumnsVisibility}
                  tableSize="small"
                  secondComponent={true}
                  tableSearch={true}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: "100%",
              width: "100%",
              height: fillBlankSpace ? "100%" : "unset",
              overflow: "visible",
            }}
          >
            <Box height={fillBlankSpace ? "100%" : "unset"}>
              <Box
                paddingBottom={"0px"}
                borderRadius="6px"
                border="1px solid"
                minHeight="100%"
                maxHeight="100%"
                overflow="auto"
                borderColor={theme.palette.custom.secondaryBorder}
                bgcolor={theme.palette.custom.secondaryBackground}
                sx={{ borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}
              >
                <APITableLoader
                  columnsVisibility={tasksColumnsVisibility}
                  tableSize="small"
                  secondComponent={true}
                  tableSearch={true}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      }
      rightComponent={
        <Box display="flex" flexDirection="column" gap="20px">
          {[...Array(4)].map((_, idx) => (
            <SecondaryBox>
              <Box display="flex" width="100%" flexDirection="column" gap="10px">
                <ChildrenBoxLoader />
              </Box>
            </SecondaryBox>
          ))}
        </Box>
      }
    />
  );
};
