import { format, parseISO } from "date-fns";
import { processExecutionPlanHooks } from "../state/QueryStore";
import { IGetListQueryParams } from "../state/QueryStoreHelper";
import AppStrings from "../strings";
import { IObjectCommonFields, ObjectDetailViewModel } from "./IObjectCommonFields";
import { CustomFieldValue, FileAttachment } from "./types";

class ProcessExecutionPlanDetailViewModel extends ObjectDetailViewModel {
  process_execution_plan_num: number;
  process_graph_num: number;
  start_date: string;
  due_date: string;
  completed_date: string;
  status: string;
  workflow: string;
  process_graph: string;
  created_by: object;

  constructor(rawData: any) {
    const objectNumber = rawData.process_graph_num;
    super(rawData, objectNumber);
    this.process_execution_plan_num = rawData.process_execution_plan_num;
    this.process_graph_num = rawData.process_graph_num;
    this.workflow = rawData.workflow;
    this.start_date = rawData.start_date
      ? format(parseISO(rawData.start_date), "MMM dd, yyyy")
      : "";
    this.due_date = rawData.due_date ? format(parseISO(rawData.due_date), "MMM dd, yyyy") : "";
    this.completed_date = rawData.completed_date
      ? format(parseISO(rawData.completed_date), "MMM dd, yyyy")
      : "";
    this.status = rawData.status;
    this.process_graph = rawData.process_graph;
    this.created_by = rawData.created_by;
  }

  get objectIndicatorName() {
    return AppStrings.processExecutionPlan.indicatorLabel;
  }
  get objectIndicatorIcon() {
    return <></>;
  }

  get objectName() {
    return AppStrings.processExecutionPlan.name;
  }

  get objectHooks() {
    return processExecutionPlanHooks as any;
  }
}

interface IProcessExecutionPlan extends IObjectCommonFields {
  id: string;
  process_graph_num: number;
  process_execution_plan_num: number;
  status: "Not-Started" | "IN_PROGRESS" | "COMPLETED";
  start_date: string | null;
  due_date: string | null;
  completed_date: string | null;
  generate_task_on_item_start: boolean;
  generate_task_after_start_date: boolean;
  generate_task_on_upstream_completion: boolean;
  customFields: CustomFieldValue[];
  fileAttachments: FileAttachment[];
  created_on: string;
  updated_on: string;
  organization: string;
  workflow: string;
  process_graph: string;
  assignee: string | null;
  assignee_name: string | null;
  created_by: string;
  updated_by: string;
  collaborators: string[];
}

interface ICreateProcessExecutionPlanRequestBody extends IUpdateProcessExecutionPlanRequestBody {
  process_graph: string;
  autogenerate_execution_items: any;
  start_date: string;
  due_date: string;
  inherit_custom_fields: any;
  generate_task_on_item_start: any;
  generate_task_after_start_date: any;
  generate_task_on_upstream_completion: any;
}

interface IUpdateProcessExecutionPlanRequestBody {
  summary: string;
  description: string;
}

interface IGetProcessExecutionPlanListRequestBody extends IGetListQueryParams {
  workflow_id: string;
}

const parseProcessExecutionPlanResult = (data: any): IProcessExecutionPlan => {
  return data;
};

const parseCreateProcessExecutionPlanResult = (data: any): IProcessExecutionPlan => {
  return parseGetProcessExecutionPlanResult(data.id, data);
};

const parseGetProcessExecutionPlanResult = (id: string, data: any): IProcessExecutionPlan => {
  return parseProcessExecutionPlanResult(data);
};

export {
  ProcessExecutionPlanDetailViewModel,
  ProcessExecutionPlanDetailViewModel as ProcessExecutionPlanListViewModel,
  parseCreateProcessExecutionPlanResult,
  parseGetProcessExecutionPlanResult,
  parseProcessExecutionPlanResult,
};
export type {
  ICreateProcessExecutionPlanRequestBody,
  IGetProcessExecutionPlanListRequestBody,
  IProcessExecutionPlan,
  IUpdateProcessExecutionPlanRequestBody,
};
