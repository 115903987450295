import { useQuery } from "react-query";
import { QueryKey } from "../state/QueryStore";
import { useAuth } from "./useAuth";
import { getJiraAppIntegrationInfo } from "../services/PluginService";

export const useJiraIntegration = () => {
  const { organization } = useAuth();
  return useQuery(
    [QueryKey.jiraIntegrationGet, organization?.id],
    async () => {
      const resp = await getJiraAppIntegrationInfo();
      return resp.data;
    },
    {
      enabled: organization != null,
    }
  );
};
