import { NavigateFunction, useNavigate } from "react-router-dom";
import { getFinalNumberFromPath, getLastPathByRegex } from "../utilities/UIHelper";
import { useQuery } from "react-query";
import { fetchActions } from "../hooks/useActions";

const ACTION_NAVIGATOR_KEY = "action-navigator";
export const useActionPathState = (locationState: any, path: string) => {
  const actionId = locationState?.actionId;
  const edit = locationState?.edit;
  const lastPath = getLastPathByRegex(path);
  const number = getFinalNumberFromPath(lastPath);
  const navigate = useNavigate();
  return useQuery<{
    actionId: string;
    edit: boolean;
  }>(
    [ACTION_NAVIGATOR_KEY, path],
    async () => {
      if (actionId != null) {
        return { actionId, edit };
      }

      const list = await fetchActions({ action_num: [number] });
      const obj = list.results.find((o) => o.action_num === Number(number));
      if (!obj) {
        navigate("/actions");
        return { actionId: null, edit };
      }

      return { actionId: obj.id, edit };
    },
    { suspense: true }
  );
};

const navigateToAction = (params: {
  navigate: NavigateFunction;
  actionId: string;
  actionNumber: number;
  edit?: boolean;
}) => {
  const { actionId, actionNumber, edit, navigate } = params;
  navigate(`/actions/action-${actionNumber}`, {
    state: {
      actionId,
      edit,
    },
  });
};

export { navigateToAction };
