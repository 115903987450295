import { Questionnaire, QuestionnaireFetchOptions, TypeformForm } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./ApiService";

const questionnaireEndpoints = {
  questionnaires: () => "/fairo_questionnaires",
  questionnaire: (id: string) => `/fairo_questionnaires/${id}`,
  typeformForms: () => "fairo_questionnaires/forms",
  add_registry: (id: string) => `/fairo_questionnaires/${id}/add_registry`,
  remove_registry: (id: string) => `/fairo_questionnaires/${id}/remove_registry`,
  responses: (id: string) => `/fairo_questionnaires/${id}/responses`,
};

export const getQuestionnaires = async (params: QuestionnaireFetchOptions) => {
  return httpGetAuthenticated<ListResponse<Questionnaire>>(
    questionnaireEndpoints.questionnaires(),
    {
      params,
    }
  );
};

export const getQuestionnaire = async (id: string) => {
  return httpGetAuthenticated<Questionnaire>(questionnaireEndpoints.questionnaire(id));
};

export const addQuestionnaire = async (payload: { form_id: string; form_name: string }) => {
  const { data } = await httpPostAuthenticated<Questionnaire>(
    questionnaireEndpoints.questionnaires(),
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.Questionnaire] });
  return data;
};

export const getTypeformForms = async (params: any) => {
  const { data } = await httpGetAuthenticated<TypeformForm>(
    questionnaireEndpoints.typeformForms(),
    {
      params,
    }
  );
  return data;
};

export const addRegistryToQuestionnaire = async (questionnaireId: string, registryId: string) => {
  const { data } = await httpPostAuthenticated<Questionnaire>(
    questionnaireEndpoints.add_registry(questionnaireId),
    {
      registry_id: registryId,
    }
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.Questionnaire, questionnaireId] });
  return data;
};

export const removeRegistryFromQuestionnaire = async (
  questionnaireId: string,
  registryId: string
) => {
  const { data } = await httpPostAuthenticated(
    questionnaireEndpoints.remove_registry(questionnaireId),
    {
      registry_id: registryId,
    }
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.Questionnaire, questionnaireId] });
  return data;
};

export const getQuestionnaireResponses = async (questionnaireId: string) => {
  return httpGetAuthenticated(questionnaireEndpoints.responses(questionnaireId));
};

export const editQuestionnaire = async (id: string, payload: { status: string }) => {
  const { data } = await httpPatchAuthenticated(questionnaireEndpoints.questionnaire(id), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Questionnaire, id] });
  return data;
};

export const addQuestionnaireResponse = async (questionnaireId: string, payload: any) => {};
