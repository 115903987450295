import { Box, useTheme } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

type CompliancePageLoaderProps = {};

export const CompliancePageLoader = (props: CompliancePageLoaderProps) => {
  const theme = useTheme();

  return (
    <>
      <Box
        display="flex"
        width="100%"
        marginTop="15px"
        border="1px solid"
        borderBottom={0}
        borderColor={theme.palette.custom.secondaryBorder}
        bgcolor={theme.palette.custom.secondaryBackground}
        flexDirection="column"
        sx={{
          backgroundColor: "background.paper",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
        }}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          padding="10px"
          bgcolor={theme.palette.custom.secondaryBackground}
          sx={{ borderTopLeftRadius: "6px", borderTopRightRadius: "6px" }}
        >
          <Box minHeight="35px" display="flex" gap="10px">
            <RectangularLoader height="35px" width="108px" />
            <RectangularLoader height="35px" width="108px" />
            <RectangularLoader height="35px" width="108px" />
          </Box>
          <RectangularLoader height="35px" width="143px" />
        </Box>
      </Box>
      <Box paddingBottom="80px">
        <Box sx={{ maxWidth: "100%", height: "100%", overflow: "visible" }}>
          <Box height={`calc(80vh - 56px)`}>
            <Box
              paddingBottom={"0px"}
              borderRadius="6px"
              border="1px solid"
              minHeight="100%"
              maxHeight="100%"
              overflow="auto"
              borderColor={theme.palette.custom.secondaryBorder}
              bgcolor={theme.palette.custom.secondaryBackground}
              sx={{ borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  justifyContent: "center",
                  paddingTop: "15px",
                  rowGap: "23px",
                  paddingBottom: "50px",
                  columnGap: "50px",
                }}
              >
                {[...Array(5)].map((_, idx) => (
                  <RectangularLoader key={idx} width="220px" height="263px" />
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
