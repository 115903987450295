import config from "../config/config";
import { Organization, User } from "../models/types";
import { getUserHash } from "./AuthService";

export const updateIntercomLoggedOut = () => {
  window.Intercom("boot", {
    api_base: config.intercom.baseUrl,
    app_id: config.intercom.appId,
  });
  window.Intercom("update");
};

export const updateIntercomLoggedIn = (user: User, organization: Organization) => {
  window.Intercom("boot", {
    api_base: config.intercom.baseUrl,
    app_id: config.intercom.appId,
    name: user.name,
    email: user.email,
    user_hash: getUserHash(),
    user_id: user.id,
    company: {
      id: organization.id,
      name: organization.name,
    },
  });
  window.Intercom("update");
};

export const launchChecklist = (checklistNum: number) => {
  window.Intercom("startChecklist", checklistNum);
};

export const shutdownIntercom = () => {
  window.Intercom("shutdown");
};

export const getHelp = (requestId: string) => {
  window.Intercom(
    `Hello, I need assistance with audit log request ID- ${requestId}. Could you please provide me with more details or guidance on this?`
  );
};

export const openGuide = (guideChecklistNum: number) => {
  window.Intercom("startChecklist", guideChecklistNum);
};

export const moreInfo = () => {
  window.Intercom("");
};

export const sendIntercomEvent = (eventName: string) => {
  window.Intercom("trackEvent", eventName);
};

export const openIntercom = () => {
  window.Intercom("show");
};
