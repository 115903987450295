import { useEffect } from "react";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { useRegistryItem, useRegistryItems } from "../../hooks/useRegistryItems";
import { FieldHandlerProps, RegistryItem } from "../../models/types";
import { Awaitable } from "../../utilities/common";

export const RegistryItemCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<RegistryItem[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required } = props;
  const { data: RegistryItem } = useRegistryItem(value || "");
  useEffect(() => {
    if (RegistryItem && setObjectAttributes) {
      setObjectAttributes(RegistryItem ?? {});
    }
  }, [RegistryItem]);
  return (
    <SearchableDropdown<RegistryItem>
      label="RegistryItem"
      required={required}
      error={error}
      value={RegistryItem || null}
      onChange={(newValue) => (newValue != null ? onChange(newValue.id) : onChange(null))}
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [
        obj.name ?? "-",
        `Type: ${obj.registry_name.type_name.name} Registry`,
      ]}
    />
  );
};

export const RegistryItemListCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<RegistryItem[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required } = props;
  const { data: items } = useRegistryItems({ id: value });
  useEffect(() => {
    if (items && items.results && items.results.length && setObjectAttributes) {
      setObjectAttributes(items.results[0] ?? {});
    }
  }, [items]);
  return (
    <SearchableDropdown<RegistryItem>
      label="RegistryItem"
      required={required}
      error={error}
      multiple={true}
      value={value.length === 0 ? [] : items?.results ?? []}
      onChange={(newValue) => newValue != null && onChange(newValue.map((item) => item.id))}
      getOptionLabel={(obj) => obj.name ?? "-"}
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [
        obj.name ?? "-",
        `Type: ${obj.registry_name.type_name.name} Registry`,
      ]}
    />
  );
};
