import { useQuery } from "react-query";
import { getWorkflow } from "../services/ExecutionPlanService";
import queryClient, { QueryKey } from "../state/QueryStore";
import { getVersionHistory } from "../services/VersionHistoryService";

export const useWorkflow = (workflowId: string, version_num?: number) => {
  const searchForVersionNum = !!version_num;
  const queryKey = searchForVersionNum
    ? [QueryKey.Workflow, workflowId, version_num]
    : [QueryKey.Workflow, workflowId];
  const queryFn = searchForVersionNum
    ? async () => {
        const { data } = await getVersionHistory({
          object_id: [workflowId],
          version_num: [version_num],
        });
        return JSON.parse(data.results[0].object);
      }
    : async () => {
        const { data } = await getWorkflow(workflowId);
        return data;
      };
  return useQuery(queryKey, queryFn);
};

export const fetchWorkflow = (workflowId: string) => {
  return queryClient.fetchQuery([QueryKey.Workflow, workflowId], async () => {
    const { data } = await getWorkflow(workflowId);
    return data;
  });
};
