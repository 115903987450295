import { Box, Stack, Typography, useTheme } from "@mui/material";
import { SmallModal } from "./SmallModal";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { useState } from "react";
import CustomCalendar from "../molecules/CustomCalendar";
import { IDateFilter } from "../molecules/DateFilter";

type CustomDateSelectionProps = {
  open: boolean;
  onClose: () => void;
  onUpdate: (dateFilter: IDateFilter) => void;
};

export const CustomDateSelectionModal = (props: CustomDateSelectionProps) => {
  const { open, onClose, onUpdate } = props;

  const theme = useTheme();
  const innerTextColor = theme.palette.text.secondary;

  const [customCreatedOnFrom, setCustomCreatedOnFrom] = useState<string>(
    moment().subtract(5, "days").subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss")
  );
  const [customCreatedOnTo, setCustomCreatedOnTo] = useState<string>(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );

  return (
    <SmallModal open={open} onClose={() => onClose()} title="Custom Time Range">
      <Stack gap="10px">
        <Typography variant="h3" color={innerTextColor}>
          Start
        </Typography>
        <CustomCalendar
          value={customCreatedOnFrom}
          onChange={setCustomCreatedOnFrom}
          timePicker={true}
        />
        <Typography variant="h3" color={innerTextColor}>
          End
        </Typography>
        <CustomCalendar
          value={customCreatedOnTo}
          onChange={setCustomCreatedOnTo}
          timePicker={true}
        />
        <LoadingButton
          variant="contained"
          fullWidth
          onClick={() => {
            onUpdate({
              name: "Custom",
              created_on_from: moment(customCreatedOnFrom).utc().format("YYYY-MM-DD HH:mm:ss"),
              created_on_to: moment(customCreatedOnTo).utc().format("YYYY-MM-DD HH:mm:ss"),
              visible: true,
            });
            onClose();
          }}
        >
          Update
        </LoadingButton>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography
            variant="button"
            sx={{
              textDecoration: "underline",
              color: theme.palette.custom.redTypography,
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => onClose()}
          >
            Cancel
          </Typography>
        </Box>
      </Stack>
    </SmallModal>
  );
};
