import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { NavbarIcon } from "../../../atoms/navbar/Icon";
import { SearchableDropdown } from "../../../components/SearchableDropdown";
import { searchUsers } from "../../../hooks/useUsers";
import { User } from "../../../models/types";
import { QuestionnaireAssignment } from "./QuestionnaireAssignment";

type SectionAssignmentProps = {
  section: any;
  assignees: {
    ref: string;
    assignee: User | null;
  }[];
  setAssignees: (assginees: any) => void;
};

export const SectionAssignment = (props: SectionAssignmentProps) => {
  const { section, assignees, setAssignees } = props;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const [expanded, setExpanded] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(
    assignees.find((assignee) => assignee.ref === section.section_num)?.assignee ?? null
  );

  useEffect(() => {
    const questionnaireRefs = section.questionnaires?.map(
      (questionnaire: any) => questionnaire.ref
    );
    const questionnaireAssignees = section.questionnaires?.map((questionnaire: any) => ({
      ref: questionnaire.ref,
      assignee: selectedUser,
    }));
    setAssignees([
      ...assignees.filter(
        (assignee) =>
          !questionnaireRefs.includes(assignee.ref) && assignee.ref !== section.section_num
      ),
      ...questionnaireAssignees,
      { ref: section.section_num, assignee: selectedUser },
    ]);
  }, [selectedUser]);

  return (
    <Box
      paddingX="5px"
      paddingY="10px"
      display="flex"
      flexDirection="column"
      gap="10px"
      borderRadius="3px"
      border={`1px solid ${theme.palette.custom.secondaryBorder}`}
    >
      <Box display="flex" gap="10px" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">{`Section-${section.section_num}: ${section.name}`}</Typography>
        <Box display="flex" gap="10px">
          <Box width="263px">
            <SearchableDropdown<User>
              label="Select or Search"
              value={selectedUser}
              onChange={setSelectedUser}
              getOptionLabel={(user) => user.name}
              getOptions={searchUsers}
              isOptionEqualToValue={(a, b) => a.id === b.id}
              getSummaryDescription={(user) => [user.name, user.username]}
            />
          </Box>
          <IconButton onClick={() => setExpanded(!expanded)}>
            <NavbarIcon
              variant={expanded ? "chevron-up" : "chevron-down"}
              sx={{
                width: "16",
                height: "13",
              }}
              color={
                isDarkMode
                  ? theme.palette.custom.whiteTypography
                  : theme.palette.custom.blueTypography
              }
            />
          </IconButton>
        </Box>
      </Box>
      {expanded && (
        <Box display="flex" flexDirection="column" gap="10px">
          {section.questionnaires?.map((questionnaire: any) => (
            <QuestionnaireAssignment
              questionnaire={questionnaire}
              sectionAssignedUser={selectedUser}
              assignees={assignees}
              setAssignees={setAssignees}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
