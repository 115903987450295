import { useQuery } from "react-query";
import { ProcessNodeFetchOptions } from "../models/types";
import { getProcessNode, getProcessNodes } from "../services/ExecutionPlanService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useProcessNodes = (params: ProcessNodeFetchOptions) => {
  return useQuery([QueryKey.ProcessNode, params], async () => {
    const { data } = await getProcessNodes(params);
    return data;
  });
};

export const fetchProcessNodes = (params: ProcessNodeFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.ProcessNode, params], async () => {
    const { data } = await getProcessNodes(params);
    return data;
  });
};

export const searchProcessNodes = async (searchText: string, processGraphId?: string) => {
  const { results } = await fetchProcessNodes({
    search: searchText,
    process_graph: processGraphId,
  });
  return results;
};

export const useProcessNode = (nodeId: string) => {
  return useQuery([QueryKey.ProcessNode, nodeId], async () => {
    const { data } = await getProcessNode(nodeId);
    return data;
  });
};

export const fetchProcessNode = (nodeId: string) => {
  return queryClient.fetchQuery([QueryKey.ProcessNode, nodeId], async () => {
    const { data } = await getProcessNode(nodeId);
    return data;
  });
};
