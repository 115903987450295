import { Box, Typography, useTheme } from "@mui/material";
import { Department, User } from "../../../models/types";
import { useState } from "react";
import { SearchableDropdown } from "../../../components/SearchableDropdown";
import { searchUsers, useUsers } from "../../../hooks/useUsers";
import { searchDepartments, useDepartments } from "../../../hooks/useDepartments";
import { LoadingButton } from "@mui/lab";

type DefineRolesProps = {
  onDefineRoles: (assessmentOwner: string, departments: string[], reviewers: string[]) => void;
  onBack: () => void;
};

export const DefineRoles = (props: DefineRolesProps) => {
  const { onDefineRoles, onBack } = props;
  const theme = useTheme();
  const textColor = theme.palette.custom.secondaryTypography;

  const [loading, setLoading] = useState(false);

  const [assessmentOwner, setAssessmentOwner] = useState<User | null>(null);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [reviewers, setReviewers] = useState<User[]>([]);

  const { data: reviewersUsers } = useUsers({ id: reviewers.map((reviewer) => reviewer.id) });
  const { data: departmentDetails } = useDepartments({
    id: departments.map((department) => department.id),
  });

  const handleNext = () => {
    setLoading(true);
    try {
      onDefineRoles(
        assessmentOwner?.id ?? "",
        departments.map((department) => department.id),
        reviewers.map((reviewer) => reviewer.id)
      );
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="10px" justifyContent="space-between">
      <Box display="flex" flexDirection="column" gap="10px" width="100%">
        <Typography variant="h1" textAlign="center">
          Define Roles
        </Typography>
        <Typography variant="body2" textAlign="center">
          Add name, description and select the desired use case type.
        </Typography>
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="h3" color={textColor}>
            Assessment Owner
          </Typography>
          <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna.
          </Typography>
          <SearchableDropdown<User>
            label="Select or Search"
            value={assessmentOwner}
            onChange={setAssessmentOwner}
            getOptionLabel={(user) => user.name}
            getOptions={searchUsers}
            isOptionEqualToValue={(a, b) => a.id === b.id}
            getSummaryDescription={(user) => [user.name, user.username]}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="h3" color={textColor}>
            Related Teams
          </Typography>
          <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna.
          </Typography>
          <SearchableDropdown<Department>
            label="Select or Search"
            multiple={true}
            value={departments.length === 0 ? [] : departmentDetails?.results ?? []}
            onChange={(newDepartment) => newDepartment != null && setDepartments(newDepartment)}
            getOptionLabel={(department) => department.name}
            getOptions={searchDepartments}
            isOptionEqualToValue={(a, b) => a.id === b.id}
            getSummaryDescription={(department) => [department.name, ""]}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography variant="h3" color={textColor}>
            Reviewers
          </Typography>
          <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna.
          </Typography>
          <SearchableDropdown<User>
            label="Select or Search"
            multiple={true}
            value={reviewers.length === 0 ? [] : reviewersUsers?.results ?? []}
            onChange={(newUser) => newUser != null && setReviewers(newUser)}
            getOptionLabel={(department) => department.name}
            getOptions={searchUsers}
            isOptionEqualToValue={(a, b) => a.id === b.id}
            getSummaryDescription={(user) => [user.name, user.username]}
          />
        </Box>
      </Box>
      <Box width="100%" display="flex" gap="10px" justifyContent="space-between">
        <LoadingButton onClick={() => onBack()}>Go back</LoadingButton>
        <LoadingButton variant="contained" onClick={() => handleNext()} loading={loading}>
          Next
        </LoadingButton>
      </Box>
    </Box>
  );
};
