import { useEffect } from "react";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { useProcessGraph, useProcessGraphs } from "../../hooks/useProcessGraphs";
import { FieldHandlerProps, ProcessGraph } from "../../models/types";
import { Awaitable } from "../../utilities/common";

export const ProcessGraphCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string, workflowId: string) => Awaitable<ProcessGraph[]>;
    workflowId: string;
    setObjectAttributes?: (obj: any) => void;
    setSelectedWorkflow: (workflowId: string | null) => void;
    disabled?: boolean;
  }
) => {
  const {
    value,
    workflowId,
    search,
    onChange,
    error,
    setObjectAttributes,
    setSelectedWorkflow,
    disabled,
    required,
  } = props;
  const { data: processGraph, isLoading } = useProcessGraph(value || "");
  useEffect(() => {
    if (processGraph) {
      setSelectedWorkflow(processGraph.workflow);
      if (setObjectAttributes) {
        setObjectAttributes(processGraph ?? {});
      }
    }
  }, [processGraph]);
  return (
    <SearchableDropdown<ProcessGraph>
      label="Process Graph"
      required={required}
      error={error}
      value={processGraph || null}
      onChange={(newValue) => (newValue != null ? onChange(newValue.id) : onChange(null))}
      getOptions={async (searchText) => await search(searchText, workflowId)}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [obj.summary, obj.description ?? ""]}
      disabled={disabled || isLoading}
    />
  );
};

export const ProcessGraphListCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string, workflowId: string) => Awaitable<ProcessGraph[]>;
    workflowId: string;
    setObjectAttributes?: (obj: any) => void;
    setSelectedWorkflow: (workflowId: string | null) => void;
    disabled?: boolean;
  }
) => {
  const {
    value,
    workflowId,
    search,
    onChange,
    error,
    setObjectAttributes,
    required,
    setSelectedWorkflow,
    disabled,
  } = props;
  const { data: items, isLoading } = useProcessGraphs(
    { id: value, workflow: workflowId },
    value.length === 0
  );
  useEffect(() => {
    if (items && items.results && items.results.length) {
      setSelectedWorkflow(items.results[0].workflow);
      if (setObjectAttributes) {
        setObjectAttributes(items.results[0] ?? {});
      }
    }
  }, [items]);
  return (
    <SearchableDropdown<ProcessGraph>
      label="Process Graph"
      required={required}
      disabled={disabled || isLoading}
      error={error}
      multiple={true}
      value={value.length === 0 ? [] : items?.results ?? []}
      onChange={(newValue) => newValue != null && onChange(newValue.map((item) => item.id))}
      getOptionLabel={(obj) => obj.summary}
      getOptions={async (searchText) => await search(searchText, workflowId)}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [obj.summary, obj?.description ?? ""]}
    />
  );
};
