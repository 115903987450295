import { Box, Typography } from "@mui/material";
import { isArray } from "lodash";
import { useEffect, useState } from "react";
import {
  AssetCustomField,
  AssetListCustomField,
  BooleanCustomField,
  DateCustomField,
  JSONCustomField,
  MLFlowModelCustomField,
  NumberCustomField,
  RatingLevelCustomField,
  StringCustomField,
  UserCustomField,
  UserListCustomField,
} from "../components/CustomFieldForm";
import { SearchableDropdown } from "../components/SearchableDropdown";
import { searchActions } from "../hooks/useActions";
import {
  searchAssets as searchAssetsHook,
  useAsset as useAssetHook,
  useAssets,
} from "../hooks/useAssets";
import {
  searchComplianceElements,
  useComplianceElement,
  useComplianceElements,
} from "../hooks/useComplianceElements";
import { searchDepartments, useDepartment, useDepartments } from "../hooks/useDepartments";
import { searchEvidenceMappings, useEvidenceMappings } from "../hooks/useEvidenceMapping";
import { useEvidenceRequirement } from "../hooks/useEvidenceRequirements";
import { searchExecutionTasks, useExecutionTask } from "../hooks/useExecutionTask";
import { useExecutionTasks } from "../hooks/useExecutionTasks";
import { useFile } from "../hooks/useFiles";
import { searchFrameworks, useFramework, useFrameworks } from "../hooks/useFramework";
import { searchImpactAssessments, useImpactAssessment } from "../hooks/useImpactAssessment";
import { searchMLFlowModels } from "../hooks/useMLFlow";
import { searchMetrics } from "../hooks/useMetrics";
import { searchParameters, useParameter, useParameters } from "../hooks/useParameters";
import { searchPlugins, usePlugin, usePluginsPaginated } from "../hooks/usePlugins";
import { searchPolicies, usePolicies, usePolicy } from "../hooks/usePolicies";
import { searchProcessGraphs } from "../hooks/useProcessGraphs";
import { searchQuestionnaires, useQuestionnaire } from "../hooks/useQuestionnaires";
import { searchReferences, useReference, useReferences } from "../hooks/useReferences";
import { searchRegistries, useRegistries, useRegistry } from "../hooks/useRegistry";
import { searchRegistryItems } from "../hooks/useRegistryItems";
import { searchResources, useResource, useResources } from "../hooks/useResources";
import { searchRoles } from "../hooks/useRoles";
import { searchTestCases } from "../hooks/useTestCases";
import { searchTestRun } from "../hooks/useTestRuns";
import { searchUsers as searchUsersHook, useUsers as useUsersHook } from "../hooks/useUsers";
import { searchWorkflows } from "../hooks/useWorkflows";
import {
  Asset,
  ComplianceFramework,
  CustomFieldType,
  RegistryFieldType,
  User,
  UserFetchOptions,
} from "../models/types";
import {
  ActionCustomField,
  ActionListCustomField,
} from "../molecules/custom-fields/ActionCustomField";
import {
  MetricCustomField,
  MetricListCustomField,
} from "../molecules/custom-fields/MetricCustomField";
import {
  ProcessGraphCustomField,
  ProcessGraphListCustomField,
} from "../molecules/custom-fields/ProcessGraphCustomField";
import {
  RegistryItemCustomField,
  RegistryItemListCustomField,
} from "../molecules/custom-fields/RegistryItemCustomField";
import { RoleCustomField, RoleListCustomField } from "../molecules/custom-fields/RoleCustomField";
import {
  TestCaseCustomField,
  TestCaseListCustomField,
} from "../molecules/custom-fields/TestCaseCustomField";
import {
  TestRunCustomField,
  TestRunListCustomField,
} from "../molecules/custom-fields/TestRunCustomField";
import {
  WorkflowCustomField,
  WorkflowListCustomField,
} from "../molecules/custom-fields/WorkflowCustomField";
import { useUsers as useUserHook } from "../services/UserSettingsService";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { Awaitable } from "../utilities/common";
import { ImageCustomField } from "./AttachmentCustomField";
import { GenericCustomField, GenericListCustomField } from "./GenericCustomField";
import { YesOrNoSelectField } from "./custom-fields/BooleanSelect";
import { ExternalLinkListField } from "./custom-fields/ExternalLink";
import { IAStakeHolderListField } from "./custom-fields/IAStakeHolder";
import {
  NameAndDescriptionField,
  NameAndDescriptionListField,
} from "./custom-fields/NameAndDescription";
interface ICustomInputValidationProps {
  value: any;
  valueError: boolean;
  errorMessage: string;
  field_type: CustomFieldType | RegistryFieldType | null;
  field_type_options?: RegistryFieldType[];
  edit_field_type?: RegistryFieldType | null;
  /** Hook to fetch the data for a userId */
  useUser?: (userId: string | null) => { data?: User; isLoading: boolean };
  /** Hook to fetch the data for many userId */
  useUsers?: (params: UserFetchOptions) => { data?: ListResponse<User>; isLoading: boolean };
  /** Callback to search for users */
  searchUsers?: (searchText: string) => Awaitable<User[]>;
  /** Hook to fetch the asset */
  useAsset?: (assetId: string) => { data?: Asset; isLoading: boolean };
  /** Callback to search for assets */
  searchAssets?: (searchText: string) => Awaitable<Asset[]>;
  onChange: (value: any, field_type?: string) => void;
  /** Additional values, used for number with unit */
  additionalValue?: any;
  additionalValueError?: boolean;
  additionalValueErrorMessage?: string;
  additionalValueOnChange?: (value: any) => void;
  setObjectAttributes?: (obj: any) => void;
  required?: boolean;
  customModalTitle?: string;
  customExtraComponent?: React.ReactNode;
}

export const CustomInputValidation = (props: ICustomInputValidationProps) => {
  const {
    value,
    onChange,
    field_type,
    field_type_options,
    edit_field_type,
    useUsers = useUsersHook,
    valueError,
    errorMessage,
    useUser = useUserHook,
    searchUsers = searchUsersHook,
    useAsset = useAssetHook,
    searchAssets = searchAssetsHook,
    additionalValue = "",
    additionalValueError = false,
    additionalValueErrorMessage = "",
    additionalValueOnChange,
    setObjectAttributes,
    required,
    customModalTitle = "",
    customExtraComponent,
  } = props;
  const hasAdditionalValue = !!additionalValueOnChange;
  const [selectedWorkflow, setSelectedWorkflow] = useState<string | null>(null);
  const [selectedFieldType, setSelectedFieldType] = useState<RegistryFieldType>(
    field_type_options ? field_type_options[0] : "String"
  );

  const fieldTypeOptionsExistsAndIsGreaterThanOne =
    field_type_options && isArray(field_type_options) && field_type_options.length > 1;
  const fieldTypeOptionsExistsAndIsSingleOption =
    field_type_options && isArray(field_type_options) && field_type_options.length === 1;

  useEffect(() => {
    if (edit_field_type) setSelectedFieldType(edit_field_type);
  }, [edit_field_type]);

  return (
    <>
      {(() => {
        switch (field_type) {
          case "String":
            return (
              <StringCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "MLFlow Data":
            return (
              <JSONCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Number":
            return (
              <NumberCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "JSON":
            return (
              <JSONCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Date":
            return (
              <DateCustomField
                required={required}
                value={value}
                allowEmpty={!required}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "User":
            return (
              <UserCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                value={value}
                useUser={useUser}
                searchUsers={searchUsers}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Asset":
            return (
              <AssetCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                value={value}
                useAsset={useAsset}
                searchAssets={searchAssets}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Boolean":
            return (
              <BooleanCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );

          case "Rating Level":
            return (
              <RatingLevelCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "MLFlow Model":
            return (
              <MLFlowModelCustomField
                required={required}
                searchModels={searchMLFlowModels}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Number with Unit":
            return hasAdditionalValue ? (
              <Box display="flex" alignItems="center" gap="10px">
                <NumberCustomField
                  required={required}
                  value={value}
                  onChange={onChange}
                  error={valueError}
                  errorMessage={errorMessage}
                />
                <StringCustomField
                  label="Unit"
                  required={required}
                  value={additionalValue}
                  onChange={additionalValueOnChange}
                  error={additionalValueError}
                  errorMessage={additionalValueErrorMessage}
                />
              </Box>
            ) : (
              <Typography>Missing additional value</Typography>
            );
          case "User list":
            return (
              <UserListCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                useUsers={useUsers}
                value={value}
                searchUsers={searchUsers}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Asset list":
            return (
              <AssetListCustomField
                required={required}
                useAssets={useAssets}
                setObjectAttributes={setObjectAttributes}
                value={value}
                searchAssets={searchAssets}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Monetary Value":
            return (
              <NumberCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Percentage":
            return (
              <NumberCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Test Run":
            return (
              <TestRunCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchTestRun}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Test Run list":
            return (
              <TestRunListCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchTestRun}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Test Case":
            return (
              <TestCaseCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchTestCases}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Test Case list":
            return (
              <TestCaseListCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchTestCases}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );

          case "Metric":
            return (
              <MetricCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchMetrics}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Metric list":
            return (
              <MetricListCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchMetrics}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );

          case "Role":
            return (
              <RoleCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchRoles}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Role list":
            return (
              <RoleListCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchRoles}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );

          case "Workflow":
            return (
              <WorkflowCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchWorkflows}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Workflow list":
            return (
              <WorkflowListCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchWorkflows}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );

          case "Action":
            return (
              <ActionCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchActions}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Action list":
            return (
              <ActionListCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchActions}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Process Graph":
            return (
              <Box display="flex" flexDirection="column" gap="10px">
                <WorkflowCustomField
                  required={required}
                  search={searchWorkflows}
                  value={selectedWorkflow}
                  onChange={(newVal: any) => {
                    setSelectedWorkflow(newVal);
                    if (selectedWorkflow !== newVal) {
                      onChange(null);
                    }
                  }}
                  error={valueError}
                  errorMessage={errorMessage}
                />
                <ProcessGraphCustomField
                  disabled={!selectedWorkflow}
                  setSelectedWorkflow={setSelectedWorkflow}
                  setObjectAttributes={setObjectAttributes}
                  workflowId={selectedWorkflow ?? ""}
                  search={searchProcessGraphs}
                  value={value}
                  required={required}
                  onChange={onChange}
                  error={valueError}
                  errorMessage={errorMessage}
                />
              </Box>
            );
          case "Process Graph list":
            return (
              <Box display="flex" flexDirection="column" gap="10px">
                <WorkflowCustomField
                  required={required}
                  search={searchWorkflows}
                  value={selectedWorkflow}
                  onChange={(newVal: any) => {
                    setSelectedWorkflow(newVal);
                    if (selectedWorkflow !== newVal) {
                      onChange(null);
                    }
                  }}
                  error={valueError}
                  errorMessage={errorMessage}
                />
                <ProcessGraphListCustomField
                  disabled={!selectedWorkflow}
                  required={required}
                  setSelectedWorkflow={setSelectedWorkflow}
                  setObjectAttributes={setObjectAttributes}
                  workflowId={selectedWorkflow ?? ""}
                  search={searchProcessGraphs}
                  value={value}
                  onChange={onChange}
                  error={valueError}
                  errorMessage={errorMessage}
                />
              </Box>
            );
          case "Registry Item":
            return (
              <RegistryItemCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchRegistryItems}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Registry Item list":
            return (
              <RegistryItemListCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchRegistryItems}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Framework":
            return (
              <GenericCustomField<ComplianceFramework, string>
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchFrameworks}
                useGetData={useFramework}
                setObjectAttributes={setObjectAttributes}
                label="Framework"
                nameKey="name"
                params={{ fairo_data: true }}
                descriptionKey="description"
              />
            );

          case "Framework list":
            return (
              <GenericListCustomField
                descriptionKey="description"
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchFrameworks}
                useGetData={useFrameworks}
                setObjectAttributes={setObjectAttributes}
                label="Frameworks"
                params={{ fairo_data: true }}
              />
            );
          case "Policy":
            return (
              <GenericCustomField
                useGetData={usePolicy}
                descriptionKey="description"
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchPolicies}
                setObjectAttributes={setObjectAttributes}
                label="Policy"
                params={{}}
              />
            );
          case "Policy list":
            return (
              <GenericListCustomField
                descriptionKey="description"
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchPolicies}
                useGetData={usePolicies}
                setObjectAttributes={setObjectAttributes}
                label="Policies"
                params={{}}
              />
            );
          case "Element":
            return (
              <GenericCustomField
                useGetData={useComplianceElement}
                descriptionKey="description"
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchComplianceElements}
                setObjectAttributes={setObjectAttributes}
                label="Element"
                params={{ fairo_data: true }}
              />
            );

          case "Element list":
            return (
              <GenericListCustomField
                descriptionKey="description"
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchComplianceElements}
                useGetData={useComplianceElements}
                setObjectAttributes={setObjectAttributes}
                label="Elements"
                params={{ fairo_data: true }}
              />
            );
          case "Integration":
            return (
              <GenericCustomField
                useGetData={usePlugin}
                descriptionKey="plugin_description"
                nameKey="plugin_display_name"
                label="Integration"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchPlugins}
                setObjectAttributes={setObjectAttributes}
                onChangeKey="plugin_type"
                params={{}}
              />
            );
          case "Integration list":
            return (
              <GenericListCustomField
                useGetData={usePluginsPaginated}
                descriptionKey="plugin_description"
                nameKey="plugin_display_name"
                label="Integrations"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchPlugins}
                setObjectAttributes={setObjectAttributes}
                onChangeKey="plugin_type"
                params={{}}
              />
            );
          case "Resource":
            return (
              <GenericCustomField
                useGetData={useResource}
                descriptionKey="external_id"
                nameKey=""
                getName={(resource) => `Resource-${resource.resource_num}`}
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchResources}
                setObjectAttributes={setObjectAttributes}
                label="Resource"
                params={{}}
              />
            );
          case "Resource list":
            return (
              <GenericListCustomField
                useGetData={useResources}
                descriptionKey="external_id"
                nameKey="id"
                getName={(resource) => `Resource-${resource.resource_num}`}
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchResources}
                setObjectAttributes={setObjectAttributes}
                label="Resources"
                params={{}}
              />
            );
          case "Evidence":
            return (
              <GenericCustomField
                useGetData={useEvidenceRequirement}
                descriptionKey="summary"
                nameKey="summary"
                getName={(evidence) => `Evidence-${evidence.evidence_requirement_num}`}
                params={{ fairo_data: true }}
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchEvidenceMappings}
                label="Evidence"
              />
            );
          case "Evidence list":
            return (
              <GenericListCustomField
                useGetData={useEvidenceMappings}
                descriptionKey="summary"
                nameKey="summary"
                getName={(evidence) => `Evidence-${evidence.evidence_requirement_num}`}
                params={{ fairo_data: true }}
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchEvidenceMappings}
                label="Evidences"
              />
            );
          case "Registry":
            return (
              <GenericCustomField
                useGetData={useRegistry}
                descriptionKey="description"
                getDescription={(registry) => registry?.registry_type_name?.name}
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchRegistries}
                setObjectAttributes={setObjectAttributes}
                label="Registry"
                params={{ fairo_data: true }}
              />
            );
          case "Registry list":
            return (
              <GenericListCustomField
                useGetData={useRegistries}
                descriptionKey="description"
                getDescription={(registry) => registry?.registry_type_name?.name}
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchRegistries}
                setObjectAttributes={setObjectAttributes}
                label="Registries"
                params={{ fairo_data: true }}
              />
            );
          case "Reference":
            return (
              <GenericCustomField
                useGetData={useReference}
                descriptionKey="reference_type"
                nameKey="title"
                required={required}
                value={value}
                onChange={onChange}
                params={{ fairo_data: true }}
                error={valueError}
                errorMessage={errorMessage}
                search={searchReferences}
                label="Reference"
                setObjectAttributes={setObjectAttributes}
              />
            );
          case "Reference list":
            return (
              <GenericListCustomField
                descriptionKey="reference_type"
                nameKey="title"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchReferences}
                label="References"
                setObjectAttributes={setObjectAttributes}
                useGetData={useReferences}
                params={{}}
              />
            );
          case "Task":
            return (
              <GenericCustomField
                useGetData={useExecutionTask}
                descriptionKey="action_type"
                nameKey="task_num"
                getName={(task) => `Task-${task.task_num}`}
                required={required}
                value={value}
                onChange={onChange}
                params={{}}
                error={valueError}
                errorMessage={errorMessage}
                search={searchExecutionTasks}
                label="Task"
                setObjectAttributes={setObjectAttributes}
              />
            );
          case "Task list":
            return (
              <GenericListCustomField
                useGetData={useExecutionTasks}
                descriptionKey="action_type"
                nameKey="task_num"
                getName={(task) => `Task-${task.task_num}`}
                required={required}
                value={value}
                onChange={onChange}
                params={{}}
                error={valueError}
                errorMessage={errorMessage}
                search={searchExecutionTasks}
                label="Tasks"
                setObjectAttributes={setObjectAttributes}
              />
            );
          case "Parameter":
            return (
              <GenericCustomField
                useGetData={useParameter}
                descriptionKey="description"
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchParameters}
                label="Parameter"
                setObjectAttributes={setObjectAttributes}
              />
            );
          case "Parameter list":
            return (
              <GenericListCustomField
                useGetData={useParameters}
                descriptionKey="description"
                params={{}}
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchParameters}
                label="Parameters"
                setObjectAttributes={setObjectAttributes}
              />
            );
          case "Hyperlink":
            return (
              <StringCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Image":
            return (
              <ImageCustomField
                useGetData={useFile}
                required={required}
                value={value}
                setObjectAttributes={setObjectAttributes}
                params={{}}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Questionnaire":
            return (
              <GenericCustomField
                useGetData={useQuestionnaire}
                descriptionKey="description"
                params={{}}
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchQuestionnaires}
                label="Questionnaire"
                setObjectAttributes={setObjectAttributes}
              />
            );
          case "Report":
            return null;
          case "Metric Result":
            return null;
          case "Process Execution":
            return null;
          case "Process Execution Item":
            return null;
          case "Test Result":
            return null;
          case "Department":
            return (
              <GenericCustomField
                useGetData={useDepartment}
                descriptionKey="name"
                getDescription={(department) => ""}
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchDepartments}
                label="Department"
                setObjectAttributes={setObjectAttributes}
              />
            );
          case "Department list":
            return (
              <GenericListCustomField
                useGetData={useDepartments}
                descriptionKey="name"
                getDescription={(department) => ""}
                params={{}}
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchDepartments}
                label="Departments"
                setObjectAttributes={setObjectAttributes}
              />
            );
          case "Impact Assessment":
            return (
              <GenericCustomField
                useGetData={useImpactAssessment}
                descriptionKey="name"
                getDescription={(impactAssessment) => ""}
                params={{}}
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchImpactAssessments}
                label="Impact Assessment"
                setObjectAttributes={setObjectAttributes}
              />
            );
          case "Name and Description":
            return <NameAndDescriptionField {...props} />;
          case "Name and Description list":
            return (
              <NameAndDescriptionListField
                tableDescription={customExtraComponent}
                modalTitle={customModalTitle}
                showAddButton={true}
                {...props}
              />
            );
          case "Text Area":
            return (
              <StringCustomField
                rows={8}
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "External Link":
            return (
              <ExternalLinkListField
                tableDescription={customExtraComponent}
                modalTitle={customModalTitle}
                showAddButton={true}
                {...props}
              />
            );
          case "IA Stakeholder":
            return (
              <IAStakeHolderListField
                modalTitle={customModalTitle}
                showAddButton={true}
                {...props}
              />
            );
          case "Geographic Areas":
            return (
              <StringCustomField
                rows={8}
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Languages":
            return (
              <StringCustomField
                rows={8}
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Yes or No":
            return <YesOrNoSelectField value={value} onChange={onChange} required={required} />;
          case null:
            return (
              <>
                {fieldTypeOptionsExistsAndIsGreaterThanOne && (
                  <SearchableDropdown<RegistryFieldType>
                    label="Value Type"
                    required={required}
                    error={false}
                    value={selectedFieldType || null}
                    onChange={(newField) => newField != null && setSelectedFieldType(newField)}
                    getOptions={(search: string) =>
                      field_type_options.filter((option) =>
                        option.toLowerCase().includes(search.toLowerCase())
                      )
                    }
                    isOptionEqualToValue={(a, b) => a === b}
                    getOptionLabel={(option) => option}
                    getSummaryDescription={(option) => [option, ""]}
                  />
                )}
                <CustomInputValidation
                  {...props}
                  onChange={(e) => {
                    onChange(
                      e,
                      fieldTypeOptionsExistsAndIsSingleOption
                        ? field_type_options?.[0]
                        : selectedFieldType
                    );
                  }}
                  field_type={
                    fieldTypeOptionsExistsAndIsSingleOption
                      ? field_type_options?.[0]
                      : selectedFieldType
                  }
                />
              </>
            );

          default:
            // below line makes sure the switch case handles all custom field types
            // removed to cover new cenario where field_type is an array
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _: never = field_type;
            return (
              <StringCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
        }
      })()}
    </>
  );
};
