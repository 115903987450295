import {
  FunctionComponent,
  ReactNode,
  useContext,
  useState,
  createContext,
  useEffect,
} from "react";
import { getCookie, setCookie } from "./CookiesContext";

export type UserDashboardOptionsType = "users" | "org-chart";
export type DashboardOptionsType =
  | "default"
  | "executive"
  | "operations"
  | "data-scientist"
  | "impact-assessment";

interface IOrganizationContext {
  currentDashboard: DashboardOptionsType | null;
  setCurrentDashboard: (dashboard: DashboardOptionsType) => void;
  reportsOpen: boolean;
  setReportsOpen: (open: boolean) => void;
  generateReportOpen: boolean;
  setGenerateReportOpen: (open: boolean) => void;
  currentUserDashboard: UserDashboardOptionsType | null;
  setCurrentUserDashboard: (userDashboard: UserDashboardOptionsType) => void;
  selectedTask: string | null;
  setSelectedTask: (taskId: string | null) => void;
  selectedQuestionnaire: string | null;
  setSelectedQuestionnaire: (questionnaireId: string | null) => void;
  selectedImpactAssessment: string | null;
  setSelectedImpactAssessment: (impactAssessmentId: string | null) => void;
  selectedQuestion: string | null;
  setSelectedQuestion: (questionId: string | null) => void;
}

export const OrganizationContext = createContext({} as IOrganizationContext);

interface IOrganizationProviderProps {
  children: ReactNode;
}

export const OrganizationProvider: FunctionComponent<IOrganizationProviderProps> = ({
  children,
}) => {
  const [currentDashboard, setCurrentDashboard] = useState<DashboardOptionsType | null>(null);
  const [reportsOpen, setReportsOpen] = useState(false);
  const [generateReportOpen, setGenerateReportOpen] = useState(false);
  const [userDashboard, setUserDashboard] = useState<UserDashboardOptionsType | null>(null);
  const [selectedTask, setSelectedTask] = useState<string | null>(null);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<string | null>(null);
  const [selectedImpactAssessment, setSelectedImpactAssessment] = useState<string | null>(null);
  const [selectedQuestion, setSelectedQuestion] = useState<string | null>(null);
  useEffect(() => {
    const selectedDashboard =
      (getCookie("overview-dashboard") as DashboardOptionsType) || "executive";
    setCurrentDashboard(selectedDashboard);
    const selectedUserDashboard =
      (getCookie("user-dashboard") as UserDashboardOptionsType) || "users";
    setUserDashboard(selectedUserDashboard);
  }, []);
  useEffect(() => {
    if (currentDashboard) {
      setCookie("overview-dashboard", currentDashboard);
    }
  }, [currentDashboard]);
  useEffect(() => {
    if (userDashboard) {
      setCookie("user-dashboard", userDashboard);
    }
  }, [userDashboard]);
  return (
    <OrganizationContext.Provider
      value={{
        currentDashboard,
        setCurrentDashboard,
        reportsOpen,
        setReportsOpen,
        generateReportOpen,
        setGenerateReportOpen,
        currentUserDashboard: userDashboard,
        setCurrentUserDashboard: setUserDashboard,
        selectedTask,
        setSelectedTask,
        selectedQuestionnaire,
        setSelectedQuestionnaire,
        selectedImpactAssessment,
        setSelectedImpactAssessment,
        setSelectedQuestion,
        selectedQuestion,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganizationContext = () => useContext(OrganizationContext);
