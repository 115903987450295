import { Box } from "@mui/material";
import { PageDetailLayout } from "../../components/layout/PageDetailLayout";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { DetailsLoader } from "../../organisms/skeleton-loaders/DetailsLoader";
import { ChildrenBoxLoader } from "../../organisms/skeleton-loaders/ChildrenBoxLoader";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { ProcessGraphLoader } from "../../organisms/skeleton-loaders/ProcessGraphLoader";
import PrimaryBox from "../../atoms/box/PrimaryBox";

export const ProcessDetailsLoader = () => {
  return (
    <PageDetailLayout
      leftComponent={
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
          <SecondaryBox>
            <DetailsLoader />
          </SecondaryBox>
          <SecondaryBox>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <RectangularLoader width="200px" height="34px" />
              <RectangularLoader width="64px" height="34px" />
            </Box>
          </SecondaryBox>
          <PrimaryBox>
            <ProcessGraphLoader />
          </PrimaryBox>
        </Box>
      }
      rightComponent={
        <Box display="flex" flexDirection="column" gap="20px">
          {[...Array(4)].map((_, idx) => (
            <SecondaryBox>
              <Box display="flex" width="100%" flexDirection="column" gap="10px">
                <ChildrenBoxLoader />
              </Box>
            </SecondaryBox>
          ))}
        </Box>
      }
    />
  );
};
