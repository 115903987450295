import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppThemeContext } from "../context/ThemeContext";
import { useAuth } from "../hooks/useAuth";
import { useNotifications } from "../hooks/useNotification";
import Navbar from "../organisms/Navbar";
import { NotificationsModal } from "../organisms/NotificationsModal";
import { PaywallModal } from "../organisms/modals/PaywallModal";
import { useOrganizationGet } from "../state/QueryStore";
import { ScreenSmallerThen } from "../utilities/UIHelper";
import { useOrganizationContext } from "../context/OrganizationContext";
import { ReportsModal } from "../organisms/modals/reports/ReportsModal";
import { GenerateReportModal } from "../organisms/modals/reports/GenerateReportModal";
import { ExecutionTaskModal } from "../organisms/modals/ExecutionTaskModal";
import { WelcomeToFairoModal } from "../organisms/modals/users/WelcomeToFairoModal";
import { useExecutionTasks } from "../hooks/useExecutionTasks";
import { OnboardUserModal } from "../organisms/modals/users/OnboardUserModal";
import { CompletedModal } from "../organisms/modals/users/CompletedModal";
import { CreateUseCaseModal } from "../organisms/modals/users/CreateUseCaseModal";
import { InviteUsersWithRoleModal } from "../organisms/modals/users/InviteUsersWithRoleModal";
import { FeaturePaywallModal } from "../organisms/modals/FeaturePaywallModal";
import { QuestionnaireAnswerModal } from "../organisms/modals/questionnaire/QuestionnaireAnswerModal";
import { CreateImpactAssessmentModal } from "../organisms/modals/compliances/CreateImpactAssessmentModal";

const MainBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        width: "100vw",
        overflow: "hidden",
        height: "100vh",
        display: "flex",
        flexDirection: "row",
      }}
    >
      {children}
    </Box>
  );
};

const MainAuthenticatedPage = () => {
  const user = useAuth().user!;
  const { isLoading, data } = useOrganizationGet();
  const hasOrganization = !!data;
  const organizationName = data?.name ?? "Omen AI";
  const { pathname } = useLocation();
  const { data: notifications } = useNotifications({
    "read[]": "False",
    page_size: 1,
  });
  const { data: onboardingTasks } = useExecutionTasks({
    status: "Pending",
    task_type: ["Onboard Task"],
    assignee: [user?.id],
  });
  const { data: useCaseSetupTasks } = useExecutionTasks({
    status: "Pending",
    task_type: ["Use Case Setup Task"],
    assignee: [user?.id],
  });
  const [notificationsOpen, setNotificationsOpen] = React.useState(false);
  const isSmallScreen = ScreenSmallerThen();
  const {
    reportsOpen,
    setReportsOpen,
    generateReportOpen,
    setGenerateReportOpen,
    selectedTask,
    setSelectedTask,
    selectedQuestionnaire,
    setSelectedQuestionnaire,
    selectedImpactAssessment,
    setSelectedImpactAssessment,
    setSelectedQuestion,
    selectedQuestion,
  } = useOrganizationContext();
  const { setNavbarExpanded } = useAppThemeContext();
  const [openWelcomeModal, setOpenWelcomeModal] = useState(false);
  const [openCreateImpactAssessment, setOpenCreateImpactAssessment] = useState(false);
  const [openInviteUserModal, setOpenInviteUserModal] = useState(false);
  const [onboardTaskId, setOnboardTaskId] = useState<string | null>(null);
  const [useCaseTaskId, setUseCaseTaskId] = useState<string | null>(null);
  const [welcomeDescription, setWelcomeDescription] = useState<string>(
    "Thank you to believe in our Vision. Let’s start your impact assessment by inviting your users under your organization and assign each a role."
  );
  const [openFeaturePaywall, setOpenFeaturePaywall] = useState(false);
  const [featurePaywallRoute, setFeaturePaywallRoute] = useState("");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1100) {
        setNavbarExpanded(false);
      }
    };

    // Add event listener for resize events
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (onboardingTasks?.results.length) {
      const tasks = onboardingTasks.results[0];
      setOnboardTaskId(tasks.id);
      if (tasks.created_by !== user.id) {
        setOpenWelcomeModal(true);
      } else {
        setOpenInviteUserModal(true);
      }
    } else if (useCaseSetupTasks?.results.length) {
      const tasks = useCaseSetupTasks.results[0];
      setUseCaseTaskId(tasks.id);
      if (tasks.created_by !== user.id) {
        setWelcomeDescription(
          "In order to start your impact assessment we require the use case creation in order to create your questionnaire."
        );
        setOpenWelcomeModal(true);
      } else {
        // setOpenCreateImpactAssessment(true);
      }
    }
  }, [onboardingTasks, useCaseSetupTasks]);

  if (pathname === "/") {
    // if no tab is selected, default to organization
    return <Navigate to="/organization" />;
  }

  const getFirstName = (name: string): string => {
    try {
      const nameSplit = name.split(" ");
      const firstName = nameSplit[0];
      return firstName;
    } catch (e) {
      return "";
    }
  };

  const userDisplayName = user ? getFirstName(user.name) : "";

  const showFeaturePaywall = (route: string) => {
    setFeaturePaywallRoute(route);
    setOpenFeaturePaywall(true);
  };

  /**TODO: Refactor task modal when moving it to atomic design */
  return (
    <MainBox>
      {!isLoading && (
        <Navbar
          userHasActiveTasks={notifications?.results && notifications.results.length > 0}
          handleTasksModal={() => setNotificationsOpen(true)}
          organizationName={organizationName ?? ""}
          userDisplayName={userDisplayName}
          hasOrganization={hasOrganization}
          showFeaturePaywall={showFeaturePaywall}
        />
      )}
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            overflowY: "auto",
            padding: isSmallScreen ? "15px 15px 60px 15px" : "15px 30px 0 30px",
          }}
        >
          <Outlet />
        </Box>
      )}
      <NotificationsModal open={notificationsOpen} onClose={() => setNotificationsOpen(false)} />
      <ReportsModal open={reportsOpen} onClose={() => setReportsOpen(false)} />
      <GenerateReportModal open={generateReportOpen} onClose={() => setGenerateReportOpen(false)} />
      <PaywallModal />
      {selectedTask != null && (
        <ExecutionTaskModal
          open={selectedTask !== null}
          onClose={() => setSelectedTask(null)}
          taskId={selectedTask}
        />
      )}
      {selectedQuestionnaire != null && selectedImpactAssessment !== null && (
        <QuestionnaireAnswerModal
          open={selectedQuestionnaire !== null}
          onClose={() => {
            setSelectedQuestionnaire(null);
            setSelectedImpactAssessment(null);
            setSelectedQuestion(null);
          }}
          questionnaireId={selectedQuestionnaire}
          impactAssessmentId={selectedImpactAssessment}
          questionId={selectedQuestion}
        />
      )}
      <WelcomeToFairoModal
        open={openWelcomeModal}
        onClose={() => setOpenWelcomeModal(false)}
        onStart={() => {
          setOpenWelcomeModal(false);
          if (onboardingTasks?.results.length) {
            setOpenInviteUserModal(true);
          } else if (useCaseSetupTasks?.results.length) {
            setOpenCreateImpactAssessment(true);
          }
        }}
        welcomeDescription={welcomeDescription}
      />
      <InviteUsersWithRoleModal
        open={openInviteUserModal}
        onClose={() => setOpenInviteUserModal(false)}
        onboardingTaskId={onboardTaskId ?? ""}
      />
      <CreateImpactAssessmentModal
        open={openCreateImpactAssessment}
        onClose={() => setOpenCreateImpactAssessment(false)}
      />
      {featurePaywallRoute && (
        <FeaturePaywallModal
          open={openFeaturePaywall}
          onClose={() => {
            setOpenFeaturePaywall(false);
            setFeaturePaywallRoute("");
          }}
          route={featurePaywallRoute}
        />
      )}
    </MainBox>
  );
};

export default MainAuthenticatedPage;
