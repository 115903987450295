import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import {
  ApiKey,
  AssignedToMe,
  AssignedToMeFetchOptions,
  User,
  UserFetchOptions,
} from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpFormPostAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

// TODO: move /organizations/* endpoints to OrganizationService
const userSettingsEndpoints = {
  inviteUserForOrganization: (organizationId: string) =>
    `/organizations/${organizationId}/invite_user`,
  uploadCSV: (organizationId: string) => `/organizations/${organizationId}/upload_csv`,
  user: (userId: string) => `/users/${userId}`,
  resendInvitation: (userId: string) => `/users/${userId}/resend_invitation`,
  cancelInvitation: (userId: string) => `/users/${userId}/cancel_invitation`,
  changePermissions: (userId: string) => `/users/${userId}/change_permissions`,
  updateUser: (userId: string) => `/users/${userId}`,
  smsAuth: (userId: string) => `/users/${userId}/sms_auth`,
  totpAuth: (userId: string) => `/users/${userId}/totp_auth`,
  oauth: (userId: string) => `/users/${userId}/oauth`,
  getUsers: () => "/users",
  changeActiveStatus: (userId: string) => `/users/${userId}/change_active_status`,
  apiKeys: (userId: string) => `/users/${userId}/user_api_key`,
  assignedToMe: () => `/users/assigned_to_me`,
};

export const useUsers = (userId: string | null) => {
  return useQuery([QueryKey.usersGet, userId], async () => {
    if (!userId || userId === "") return null;
    const { data } = await httpGetAuthenticated(userSettingsEndpoints.user(userId ?? ""));
    return data;
  });
};

export const getUsers = (params: UserFetchOptions) => {
  return httpGetAuthenticated<ListResponse<User>>(userSettingsEndpoints.getUsers(), { params });
};

export const inviteUser = async (
  orgId: string,
  userInfo: {
    name: string;
    email?: string;
    job_title?: string;
    department?: string;
    manager?: string;
  }
) => {
  if (userInfo.email) {
    userInfo.email = userInfo.email.toLowerCase();
  }
  const resp: AxiosResponse<{ detail: string; id: string }> = await httpPostAuthenticated(
    userSettingsEndpoints.inviteUserForOrganization(orgId),
    userInfo
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.User] });

  return resp;
};

export const uploadCSV = async (orgId: string, file: File) => {
  let formData = new FormData();
  formData.append("file", file);
  const resp = await httpFormPostAuthenticated(userSettingsEndpoints.uploadCSV(orgId), formData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.User] });
  return resp;
};

export const resendInvitation = async (userId: string) => {
  const resp = await httpGetAuthenticated(userSettingsEndpoints.resendInvitation(userId), null);
  queryClient.invalidateQueries({ queryKey: [QueryKey.User] });
  return resp;
};

export const cancelInvitation = async (userId: string) => {
  const resp = await httpDeleteAuthenticated(userSettingsEndpoints.cancelInvitation(userId), null);
  queryClient.invalidateQueries({ queryKey: [QueryKey.User] });
  return resp;
};

export const createOAuth = (userId: string, provider: string) => {
  return httpPostAuthenticated(userSettingsEndpoints.oauth(userId), {
    provider,
  });
};

export const deleteOAuth = (userId: string) => {
  return httpDeleteAuthenticated(userSettingsEndpoints.oauth(userId), {});
};

export const getOAuthProvider = (userId: string) => {
  return httpGetAuthenticated(userSettingsEndpoints.oauth(userId), {});
};

export const changeUserPermissions = (
  userId: string,
  permissions: string /* must be an enum? */
) => {
  return httpPostAuthenticated(userSettingsEndpoints.changePermissions(userId), { permissions });
};

export const updatePhone = (userId: string, phoneNumber: string) => {
  return httpPatchAuthenticated(userSettingsEndpoints.user(userId), {
    phone_number: phoneNumber,
  });
};

export const sendSMS = (userId: string, phoneNumber: string) => {
  return httpGetAuthenticated(userSettingsEndpoints.smsAuth(userId), {});
};

export const validateCode = (userId: string, code: string) => {
  return httpPostAuthenticated(userSettingsEndpoints.smsAuth(userId), {
    sms_code: code,
  });
};

export const deleteSMSValidation = (userId: string) => {
  return httpDeleteAuthenticated(userSettingsEndpoints.smsAuth(userId), {});
};

export const disableTwoFactorAuth = (userId: string) => {
  return httpPatchAuthenticated(userSettingsEndpoints.updateUser(userId), {
    require_2fa: false,
    two_factor_auth_method: "",
  });
};

export const updateUserName = (userId: string, firstName: string, lastName: string) => {
  return httpPatchAuthenticated(userSettingsEndpoints.updateUser(userId), {
    first_name: firstName,
    last_name: lastName,
  });
};

export const updateOAuthPreference = (userId: string, value: string) => {
  return httpPatchAuthenticated(userSettingsEndpoints.updateUser(userId), {
    primary_auth_method: value,
  });
};

export const updateOrgChartSettings = async (
  userId: string,
  payload: {
    job_title?: string;
    department?: string | null;
    manager?: string | null;
  }
) => {
  const data = await httpPatchAuthenticated(userSettingsEndpoints.updateUser(userId), payload);
  queryClient.invalidateQueries({ queryKey: QueryKey.User });
  return data;
};

export const getTotpCode = (userId: string) => {
  return httpGetAuthenticated(userSettingsEndpoints.totpAuth(userId), {});
};

export const deleteTotpAuth = (userId: string) => {
  return httpDeleteAuthenticated(userSettingsEndpoints.totpAuth(userId), {});
};

export const verifyTotpCode = (userId: string, totpCode: string) => {
  return httpPostAuthenticated(userSettingsEndpoints.totpAuth(userId), {
    totp_code: totpCode,
  });
};

export const requestPhoneUpdate = (userId: string, phoneNumber: string) => {
  return httpPatchAuthenticated(userSettingsEndpoints.updateUser(userId), {
    phone_number: phoneNumber,
  });
};

export const enable2FA = (userId: string, method: "SMS" | "TOTP") => {
  return httpPatchAuthenticated(userSettingsEndpoints.updateUser(userId), {
    require_2fa: true,
    two_factor_auth_method: method,
  });
};

export const getSmsAuth = (userId: string) => {
  return httpGetAuthenticated(userSettingsEndpoints.smsAuth(userId), {});
};

export const verifySMSCode = (userId: string, smsCode: string) => {
  return httpPostAuthenticated(userSettingsEndpoints.smsAuth(userId), {
    sms_code: smsCode,
  });
};

export const changeActiveStatus = (userId: string, activeStatus: "Active" | "Inactive") => {
  return httpPostAuthenticated(userSettingsEndpoints.changeActiveStatus(userId), null, {
    params: { status: activeStatus },
  });
};

export const getUserApiKeys = (userId: string) => {
  return httpGetAuthenticated<ApiKey[]>(userSettingsEndpoints.apiKeys(userId));
};

export const createUserApiKey = (userId: string, description: string, expiresIn?: string) => {
  return httpPostAuthenticated(userSettingsEndpoints.apiKeys(userId), { description });
};

export const deleteUserApiKey = (userId: string, keyId: string) => {
  return httpDeleteAuthenticated(userSettingsEndpoints.apiKeys(userId), {
    data: { api_key_id: keyId },
  });
};

// TODO: Add BE endpoint
// const deleteInvitation = (userId: string) => {
//   httpDeleteAuthenticated(userSettingsEndpoints.user(userId));
// };

export const assignedToMe = (params: AssignedToMeFetchOptions) => {
  return httpGetAuthenticated<ListResponse<AssignedToMe>>(userSettingsEndpoints.assignedToMe(), {
    params,
  });
};
