import {
  ComplianceElement,
  ComplianceElementDetail,
  ComplianceElementFetchOptions,
} from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./ApiService";

const complianceElementsEndpoints = {
  complianceElements: () => `/compliance_elements`,
  complianceElementDetails: () => `/compliance_element_data`,
  complianceElement: (complianceElementId: string) => `/compliance_elements/${complianceElementId}`,
  complianceElementDetail: (detailId: string) => `/compliance_element_data/${detailId}`,
  assignOwner: () => `/compliance_elements/copy?fairo_data=true`,
};

export const getComplianceElements = (params: any) => {
  return httpGetAuthenticated<ListResponse<ComplianceElement>>(
    complianceElementsEndpoints.complianceElements(),
    {
      params,
    }
  );
};

export const getComplianceElement = (
  complianceElementId: string,
  params: { fairo_data: boolean }
) => {
  return httpGetAuthenticated<ComplianceElement>(
    complianceElementsEndpoints.complianceElement(complianceElementId),
    {
      params,
    }
  );
};

export const createComplianceElement = (data: {
  name: string;
  external_id: string;
  element_type: string;
  description?: string;
  assignee?: string;
  file_attachments?: string[];
  custom_fields?: string[];
}) => {
  return httpPostAuthenticated<ComplianceElement>(
    complianceElementsEndpoints.complianceElements(),
    data
  );
};

export const copyFairoElement = (data: { element_id: string }) => {
  return httpPostAuthenticated<ComplianceElement>(complianceElementsEndpoints.assignOwner(), data);
};

export const editComplianceElement = async (
  elementId: string,
  payload: {
    assignee?: string | null;
  }
) => {
  const { data } = await httpPatchAuthenticated(
    complianceElementsEndpoints.complianceElement(elementId),
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.ComplianceElement, elementId] });
  return data;
};
