import { NavigateFunction, useNavigate } from "react-router-dom";
import { getFinalNumberFromPath, getLastPathByRegex } from "../utilities/UIHelper";
import { useQuery } from "react-query";
import { fetchResources } from "../hooks/useResources";

const RESOURCE_NAVIGATOR_KEY = "resource-navigator";
export const useResourcePathState = (locationState: any, path: string) => {
  const resourceId = locationState?.resourceId;
  const edit = locationState?.edit;
  const lastPath = getLastPathByRegex(path);
  const number = getFinalNumberFromPath(lastPath);
  const navigate = useNavigate();
  return useQuery<{
    resourceId: string;
    edit: boolean;
  }>(
    [RESOURCE_NAVIGATOR_KEY, path],
    async () => {
      if (resourceId != null) {
        return { resourceId, edit };
      }

      const list = await fetchResources({ resource_num: [number] });
      const obj = list.results.find((o) => o.resource_num === Number(number));
      if (!obj) {
        navigate("/resources");
        return { resourceId: null, edit };
      }

      return { resourceId: obj.id, edit };
    },
    { suspense: true }
  );
};

const navigateToResource = (params: {
  navigate: NavigateFunction;
  resourceId: string;
  resourceNumber: number;
  edit?: boolean;
}) => {
  const { resourceId, resourceNumber, edit, navigate } = params;
  navigate(`/resources/resource-${resourceNumber}`, {
    state: {
      resourceId,
      edit,
    },
  });
};

export { navigateToResource };
