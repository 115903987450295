import { Box, CircularProgress, useTheme } from "@mui/material";
import { ScreenSmallerThen } from "../../utilities/UIHelper";
import { useEffect, useRef, useState } from "react";

interface IPageDetailLayoutProps {
  leftComponent: React.ReactNode;
  rightComponent: React.ReactNode;
  loading?: boolean;
}

export const PageDetailLayout = ({
  leftComponent,
  rightComponent,
  loading,
}: IPageDetailLayoutProps) => {
  const theme = useTheme();
  const isSmallScreen = ScreenSmallerThen();
  const [leftScrollPadding, setLeftScrollPadding] = useState("0px");
  const [rightScrollPadding, setRightScrollPadding] = useState("0px");
  const rightContainerRef = useRef(null);
  const leftContainerRef = useRef(null);

  /**
   * check if container scroll exists, if so we add padding between the box and the scrollbar
   * validate both current padding value and expected to avoid unnecessary re-renders
   * if there's a prop that could apply the padding only when the scrollbar is visible and work on all browsers, we can remove this workaround and use it
   * */

  useEffect(() => {
    if (rightContainerRef.current) {
      const rightScrollbarVisible =
        (rightContainerRef.current as HTMLElement).scrollHeight >
        (rightContainerRef.current as HTMLElement).clientHeight;
      if (rightScrollbarVisible && rightScrollPadding === "0px") {
        setRightScrollPadding("5px");
      } else if (!rightScrollbarVisible && rightScrollPadding === "5px") {
        setRightScrollPadding("0px");
      }
    }
    if (leftContainerRef.current) {
      const leftScrollbarVisible =
        (leftContainerRef.current as HTMLElement).scrollHeight >
        (leftContainerRef.current as HTMLElement).clientHeight;
      if (leftScrollbarVisible && leftScrollPadding === "0px") {
        setLeftScrollPadding("5px");
      } else if (!leftScrollbarVisible && leftScrollPadding === "5px") {
        setLeftScrollPadding("0px");
      }
    }
  }, [leftContainerRef, rightContainerRef, rightComponent, leftComponent]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflowY: "auto",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-start",
        padding: "15px 0 15px 0",
      }}
      gap={isSmallScreen ? "20px" : "30px"}
    >
      {loading ? (
        <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            ref={leftContainerRef}
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              flexGrow: 1,
              flexBasis: "200px",
              flexShrink: 1,
              minWidth: isSmallScreen ? "100%" : "200px",
              width: "100%",
              maxWidth: "100%",
              height: isSmallScreen ? "auto" : "100%",
              overflow: isSmallScreen ? "unset" : "auto",
              paddingRight: leftScrollPadding,
              scrollbarWidth: "thin",
            }}
          >
            {leftComponent}
          </Box>
          <Box
            ref={rightContainerRef}
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              flex: isSmallScreen ? "none" : "0 1 350px",
              width: isSmallScreen ? "100%" : "350px",
              height: isSmallScreen ? "auto" : "100%",
              overflow: isSmallScreen ? "unset" : "auto",
              paddingRight: rightScrollPadding,
              scrollbarWidth: "thin",
            }}
          >
            {rightComponent}
          </Box>
        </>
      )}
    </Box>
  );
};
