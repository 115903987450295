import { Box, Stack } from "@mui/material";
import { SmallModal } from "../../../components/SmallModal";
import { InviteUsersWithRole } from "./InviteUsersWithRole";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { editExecutionTask } from "../../../services/ExecutionPlanService";

type InviteUsersWithRoleModalProps = {
  /** Should the modal be open? */
  open: boolean;
  /** Callback to close the modal */
  onClose: () => void;
  /** Onboarding Task Id */
  onboardingTaskId: string;
};

export const InviteUsersWithRoleModal = (props: InviteUsersWithRoleModalProps) => {
  const { open, onClose, onboardingTaskId } = props;

  const [loading, setLoading] = useState(false);

  const onFinishOnboarding = async () => {
    setLoading(true);
    try {
      await editExecutionTask(onboardingTaskId, {
        status: "Completed",
      });
    } catch {}
    setLoading(false);
    onClose();
  };

  return (
    <SmallModal open={open} onClose={onClose} size="large">
      <Stack gap="10px">
        <InviteUsersWithRole />
        <Box display="flex" width="100%" justifyContent="flex-end">
          <LoadingButton variant="contained" onClick={onFinishOnboarding} loading={loading}>
            Finish Onboarding
          </LoadingButton>
        </Box>
      </Stack>
    </SmallModal>
  );
};
