import { useQuery } from "react-query";
import {
  getCustomField,
  getCustomFieldValue,
  getCustomFieldValues,
  getCustomFields,
} from "../services/CustomFieldService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useCustomFields = async () => {
  return useQuery([QueryKey.CustomField], async () => {
    const { data } = await getCustomFields();
    return data.results;
  });
};

export const useCustomField = (objectId: string) => {
  return useQuery([QueryKey.CustomField, objectId], async () => {
    const { data } = await getCustomField(objectId);
    return data;
  });
};

export const fetchCustomField = async (objectId: string) => {
  return queryClient.fetchQuery([QueryKey.CustomField, objectId], async () => {
    const { data } = await getCustomField(objectId);
    return data;
  });
};

export const listCustomFields = async () => {
  const { data } = await getCustomFields();
  return data.results;
};

export const useCustomFieldValues = (parentObjectId: string) => {
  return useQuery([QueryKey.CustomFieldValue, parentObjectId], async () => {
    const { data } = await getCustomFieldValues({ parent_object_id: parentObjectId });
    return data.results;
  });
};

export const useCustomFieldValue = (objectId: string) => {
  return useQuery([QueryKey.CustomFieldValue, objectId], async () => {
    const { data } = await getCustomFieldValue(objectId);
    return data;
  });
};
