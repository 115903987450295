import { processNodeHooks } from "../state/QueryStore";
import { IGetListQueryParams } from "../state/QueryStoreHelper";
import AppStrings from "../strings";
import { ActionListViewModel } from "./Action";
import { AssetListViewModel } from "./Asset";
import {
  IObjectCommonFields,
  ObjectDetailViewModel,
  ObjectListViewModel,
  parseObjectCommonFields,
} from "./IObjectCommonFields";
import { IProcessEdge } from "./IProcessEdge";
import { TestCaseListViewModel } from "./TestCase";

interface RelatedObject {
  id: string;
  name: string;
}

interface IProcessNode extends IObjectCommonFields {
  id: string;
  summary: string;
  description: string;
  nodeNumber: number;
  edges: IProcessEdge[];
  positionX: number;
  positionY: number;
  requiredActions: ActionListViewModel[];
  requiredTestCases: TestCaseListViewModel[];
  relatedAssets: AssetListViewModel[];
  mapped_elements?: {
    results: [];
    count: number;
  };
  object_tags?: [];
  plugin_data?: [];
}

class ProcessNodeListViewModel extends ObjectListViewModel {
  constructor(rawData: any) {
    const objectNumber = rawData.process_node_num;
    super(rawData, objectNumber);
  }

  get objectIndicatorName() {
    return AppStrings.processNode.indicatorLabel;
  }
  get objectIndicatorIcon() {
    return <></>;
  }

  get objectName() {
    return AppStrings.processNode.name;
  }

  get objectHooks() {
    return processNodeHooks as any;
  }
}

class ProcessNodeDetailViewModel extends ObjectDetailViewModel {
  edges: IProcessEdge[];
  positionX: number;
  positionY: number;
  requiredActions: ActionListViewModel[];
  requiredTestCases: TestCaseListViewModel[];
  relatedAssets: AssetListViewModel[];
  mapped_elements?: {
    results: [];
    count: number;
  };
  object_tags?: [];
  plugin_data?: [];

  constructor(rawData: any) {
    const objectNumber = rawData.process_node_num;
    super(rawData, objectNumber);

    const parsed = parseProcessNodeResult(rawData);
    this.edges = parsed.edges;
    this.positionX = parsed.positionX;
    this.positionY = parsed.positionY;
    this.requiredActions = parsed.requiredActions;
    this.requiredTestCases = parsed.requiredTestCases;
    this.relatedAssets = parsed.relatedAssets;
    this.mapped_elements = parsed.mapped_elements;
    this.object_tags = parsed.object_tags;
    this.plugin_data = rawData.plugin_data;
  }

  get objectIndicatorName() {
    return AppStrings.processNode.indicatorLabel;
  }
  get objectIndicatorIcon() {
    return <></>;
  }

  get objectName() {
    return AppStrings.processNode.name;
  }

  get objectHooks() {
    return processNodeHooks as any;
  }
}

interface ICreateProcessNodeRequestBody extends IUpdateProcessNodeRequestBody {
  process_graph: string;
}

interface IUpdateProcessNodeRequestBody {
  summary: string;
  description: string;
  position_x?: number;
  position_y?: number;
}

interface IGetProcessNodeListRequestBody extends IGetListQueryParams {}

const parseProcessNodeResult = (data: any): IProcessNode => {
  const rawEdges = JSON.parse(data.edges);
  const edges: IProcessEdge[] = rawEdges.map((e: any) => {
    return {
      id: e.pk,
      fromProcesNodeId: e.fields.process_node_from,
      toProcessNodeId: e.fields.process_node_to,
      summary: e.fields.summary ?? "",
    } as IProcessEdge;
  });

  return {
    id: data.id,
    summary: data.summary ?? "",
    description: data.description ?? "",
    nodeNumber: data.process_node_num,
    positionX: Number.parseFloat(data.position_x ?? 0),
    positionY: Number.parseFloat(data.position_y ?? 0),
    edges,
    ...parseObjectCommonFields(data),
    requiredActions: (data.required_actions ?? []).map((e: any) => new ActionListViewModel(e)),
    requiredTestCases: (data.required_test_cases ?? []).map(
      (e: any) => new TestCaseListViewModel(e)
    ),
    relatedAssets: (data.related_assets ?? []).map((e: any) => new AssetListViewModel(e)),
    mapped_elements: data.mapped_elements ?? {
      results: [],
      count: 0,
    },
    object_tags: data.object_tags ?? [],
  };
};

const parseCreateProcessNodeResult = (data: any): IProcessNode => {
  return parseGetProcessNodeResult(data.id, data);
};

const parseGetProcessNodeResult = (id: string, data: any): IProcessNode => {
  return parseProcessNodeResult(data);
};

export type {
  IProcessNode,
  ICreateProcessNodeRequestBody,
  IUpdateProcessNodeRequestBody,
  IGetProcessNodeListRequestBody,
};
export {
  ProcessNodeListViewModel,
  ProcessNodeDetailViewModel,
  parseProcessNodeResult,
  parseCreateProcessNodeResult,
  parseGetProcessNodeResult,
};
