import { Box, TextField, Typography, useTheme } from "@mui/material";
import { SearchableDropdown } from "../../../components/SearchableDropdown";
import CKEditorField from "../../../components/custom-fields/CKEditorField";
import { searchUsers } from "../../../hooks/useUsers";
import { User } from "../../../models/types";
import { useState } from "react";
import { useInputState } from "../../../hooks/useInputTextState";

type CreateUseCaseFormProps = {
  name: string;
  setName: (name: string) => void;
  nameError: boolean;
  useCaseOwner: User | null;
  setUseCaseOwner: (owner: User | null) => void;
  description: string;
  setDescription: (description: string) => void;
};

export const CreateUseCaseForm = (props: CreateUseCaseFormProps) => {
  const { name, setName, nameError, useCaseOwner, setUseCaseOwner, description, setDescription } =
    props;
  const theme = useTheme();
  const textColor = theme.palette.custom.secondaryTypography;

  return (
    <Box display="flex" flexDirection="column" gap="10px" width="100%">
      <Typography variant="h1" textAlign="center">
        Use Case General Information
      </Typography>
      <Typography variant="body2" textAlign="center">
        Add name, description and select the desired use case type.
      </Typography>
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography variant="h3" color={textColor}>
          Use Case Name
        </Typography>
        <Typography variant="body2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna.
        </Typography>
        <TextField
          error={nameError}
          helperText={nameError && "Please enter name"}
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          label="Name"
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography variant="h3" color={textColor}>
          Use Case Owner
        </Typography>
        <Typography variant="body2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna.
        </Typography>
        <SearchableDropdown<User>
          label="Select or Search"
          value={useCaseOwner}
          onChange={setUseCaseOwner}
          getOptionLabel={(user) => user.name}
          getOptions={searchUsers}
          isOptionEqualToValue={(a, b) => a.id === b.id}
          getSummaryDescription={(user) => [user.name, user.username]}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography variant="h3" color={textColor}>
          Description
        </Typography>
        <Typography variant="body2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna.
        </Typography>
        <CKEditorField value={description} onChange={setDescription} />
      </Box>
    </Box>
  );
};
