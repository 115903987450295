import { NavigateFunction, useNavigate } from "react-router-dom";
import { getFinalNumberFromPath, getLastPathByRegex } from "../utilities/UIHelper";
import { useQuery } from "react-query";
import { fetchAssets } from "../hooks/useAssets";

const ASSET_NAVIGATOR_KEY = "asset-navigator";
export const useAssetPathState = (locationState: any, path: string) => {
  const assetId = locationState?.assetId;
  const edit = locationState?.edit;
  const lastPath = getLastPathByRegex(path);
  const number = getFinalNumberFromPath(lastPath);
  const navigate = useNavigate();
  return useQuery<{
    assetId: string;
    edit: boolean;
  }>(
    [ASSET_NAVIGATOR_KEY, path],
    async () => {
      if (assetId != null) {
        return { assetId, edit };
      }

      const list = await fetchAssets({ asset_num: [number] });
      const obj = list.results.find((o) => o.asset_num === Number(number));
      if (!obj) {
        navigate("/assets");
        return { assetId: null, edit };
      }

      return { assetId: obj.id, edit };
    },
    { suspense: true }
  );
};

const navigateToAsset = (params: {
  navigate: NavigateFunction;
  assetId: string;
  assetNumber: number;
  edit?: boolean;
}) => {
  const { assetId, assetNumber, edit, navigate } = params;
  navigate(`/assets/asset-${assetNumber}`, {
    state: {
      assetId,
      edit,
    },
  });
};

export { navigateToAsset };
