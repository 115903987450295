import { Box, Typography, useTheme } from "@mui/material";
import { useRegistryItem } from "../../../hooks/useRegistryItems";
import UserTag from "../../../components/UserTag";
import { RegistryItemValue } from "../../../models/types";
import { ShowMore } from "../../../molecules/ShowMore";
import { USE_CASE_OWNER_FIELD_ID, DESCRIPTION_FIELD_ID } from "../../../utilities/UIHelper";
import { RectangularLoader } from "../../../molecules/skeleton-loader/RectangularLoader";

type UseCaseReadOnlyProps = {
  useCaseId: string;
};

export const UseCaseReadOnly = (props: UseCaseReadOnlyProps) => {
  const { useCaseId } = props;
  const theme = useTheme();
  const textColor = theme.palette.custom.secondaryTypography;

  const { data: useCaseRegistryItem, isLoading } = useRegistryItem(useCaseId);

  return (
    <Box display="flex" flexDirection="column" gap="10px" width="100%">
      <Typography variant="h1" textAlign="center">
        Use Case General Information
      </Typography>
      <Typography variant="body2" textAlign="center">
        Showing use case name, use case owner and description (View only mode).
      </Typography>
      {isLoading ? (
        <>
          {[1, 2, 3].map((_, index) => (
            <RectangularLoader />
          ))}
        </>
      ) : (
        <>
          <Box display="flex" gap="10px">
            <Typography variant="h3" color={textColor}>
              Use Case Name
            </Typography>
            <Typography variant="body2">{useCaseRegistryItem.name}</Typography>
          </Box>
          <Box display="flex" gap="10px">
            <Typography variant="h3" color={textColor}>
              Use Case Owner
            </Typography>
            <UserTag
              name={
                useCaseRegistryItem.registry_item_values.filter(
                  (registryItemValue: RegistryItemValue) =>
                    registryItemValue.registry_field === USE_CASE_OWNER_FIELD_ID
                ).value_name
              }
            />
          </Box>
          <Box display="flex" flexDirection="column" gap="5px">
            <Typography variant="h3" color={textColor}>
              Description
            </Typography>
            <ShowMore
              description={
                useCaseRegistryItem.registry_item_values
                  .filter(
                    (registryItemValue: RegistryItemValue) =>
                      registryItemValue.registry_field === DESCRIPTION_FIELD_ID
                  )
                  .value?.toString() ?? ""
              }
              maxChar={250}
              richText={false}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
