import { Box, useTheme } from "@mui/material";
import React from "react";
import { ScreenSmallerThen } from "../../utilities/UIHelper";
interface INavbarBox {
  expanded: boolean;
  children: React.ReactElement;
}
const NavbarBox = ({ expanded, children }: INavbarBox) => {
  const theme = useTheme();
  const isSmallScreen = ScreenSmallerThen();
  const isDarkTheme = theme.palette.mode === "dark";
  return (
    <Box
      sx={{
        minHeight: isSmallScreen ? "100%" : "100vh",
        visibility: isSmallScreen ? (expanded ? "visible" : "hidden") : "visible",
        width: isSmallScreen ? "100%" : expanded ? "133px" : "60px",
        border: `1px solid ${theme.palette.custom.secondaryBorder}`,
        borderTopRightRadius: isSmallScreen ? "0px" : "6px",
        borderBottomRightRadius: isSmallScreen ? "0px" : "6px",
        backgroundColor: "background.paper",
        opacity: isSmallScreen ? (expanded ? 1 : 0) : 1,
        transition: "width 0.5s ease, visibility 0.3s ease, opacity 0.3s ease",
        display: "flex",
        top: isSmallScreen ? 0 : "none",
        left: isSmallScreen ? 0 : "none",
        height: "100%",
        position: isSmallScreen ? "fixed" : "unset",
        flexDirection: "column",
        overflowY: "scroll",
        justifyContent: isSmallScreen ? "flex-start" : "space-between",
        padding: "30px 12px 30px 12px",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "-ms-overflow-style": "none" /* IE and Edge */,
        "scrollbar-width": "none" /* Firefox */,
      }}
    >
      {children}
    </Box>
  );
};

export default NavbarBox;
