import { Circle } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavbarIcon } from "../../atoms/navbar/Icon";
import { UserCustomField } from "../../components/CustomFieldForm";
import DeleteIconButton from "../../components/DeleteIconButton";
import { SmallModal } from "../../components/SmallModal";
import { useOrganizationContext } from "../../context/OrganizationContext";
import { useExecutionTask } from "../../hooks/useExecutionTask";
import { searchUsers } from "../../hooks/useUsers";
import { ButtonIcon } from "../../molecules/ButtonIcon";
import { deleteExecutionTask } from "../../services/ExecutionPlanService";
import { useUsers } from "../../services/UserSettingsService";
import { GithubTaskSummary } from "../GithubSummary";
import { JiraTaskSummary } from "../JiraSummary";
import { BaseTaskModal } from "./tasks/BaseTaskModal";
type ExecutionTaskModalProps = {
  /** Should the modal be open? */
  open: boolean;
  /** Callback to close the modal */
  onClose: () => void;
  /** Optional test result for update purposes */
  taskId: string;
  disabled?: boolean;
};

export const HeaderAndValue = (props: { header: string; value: string }) => {
  const theme = useTheme();
  return (
    <Box display="flex" alignItems="center" flexDirection="row" gap="10px">
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        {props.header}
      </Typography>
      <Typography variant="body2" color={theme.palette.custom.secondaryTypography}>
        {props.value}
      </Typography>
    </Box>
  );
};

export const ExecutionTaskModal = (props: ExecutionTaskModalProps) => {
  const { open, onClose, taskId, disabled } = props;
  const { data: task } = useExecutionTask(taskId);
  const navigate = useNavigate();
  const { setSelectedTask } = useOrganizationContext();
  const [assignedUser, setAssignedUser] = useState<string>("");
  const theme = useTheme();
  const [isUpdate, setIsUpdate] = useState(false);
  const isCompleted = task?.status === "Completed";
  const isJira = task?.action_type === "External-Jira";
  const isGithub = task?.action_type === "External-Github";
  const isReview = task?.action_type === "Review";
  const isApproval = task?.action_type === "Approval";
  const secondTitle = isJira
    ? "External Jira"
    : isGithub
    ? "External Github"
    : isReview
    ? "Review"
    : isApproval
    ? "Approval"
    : "";
  return (
    <SmallModal
      size={"medium"}
      open={open}
      onClose={onClose}
      title="Task"
      customTitle={
        <Box display="flex" flexDirection="row" alignItems="center" gap="5px">
          <Circle
            sx={{ fontSize: 9 }}
            color={task?.status === "Completed" ? "success" : "warning"}
          />
          <Typography variant="h2">Task-{task?.task_num}</Typography>
          {secondTitle && (
            <Typography variant="h2" color={theme.palette.custom.gray}>
              ({secondTitle})
            </Typography>
          )}
          {(isJira || isGithub) && (
            <NavbarIcon
              variant={isJira ? "jira" : "github"}
              sx={{ width: "30px", height: "30px", minHeight: "30px", minWidth: "30px" }}
            />
          )}
        </Box>
      }
      rightComponent={
        <Box display="flex" flexDirection="row" gap="5px">
          {!isUpdate && !isCompleted && (
            <ButtonIcon
              iconSize="20px"
              emptyBackground={true}
              iconColor={theme.palette.custom.blue}
              action={() => setIsUpdate(!isUpdate)}
              label=""
              variant="edit-icon"
              disabled={disabled}
            />
          )}
          {isUpdate && (
            <DeleteIconButton
              deleteConfirmationDialogTitle="Remove Execution Task"
              deleteConfirmationDialogDescription={`Are you sure you want to remove Execution Task-${task?.task_num}? Once it’s deleted it’s gone for good`}
              onDeleteConfirm={async () => {
                await deleteExecutionTask(taskId);
                onClose();
              }}
              filledButton
              disabled={disabled}
            />
          )}
        </Box>
      }
    >
      <Box gap="10px" width="100%" display="flex" flexDirection="column">
        {!isJira && !isGithub && (
          <BaseTaskModal onClose={onClose} edit={isUpdate} taskId={taskId} setEdit={setIsUpdate} />
        )}
        {task && isJira && (
          <JiraTaskSummary setIsUpdate={setIsUpdate} isUpdate={isUpdate} task={task} />
        )}
        {task && isGithub && (
          <GithubTaskSummary setIsUpdate={setIsUpdate} isUpdate={isUpdate} task={task} />
        )}
      </Box>
    </SmallModal>
  );
};
