const microsoft_rai_base_forms = [
  {
    section_num: 1,
    name: "System impormation",
    questionnaires: [
      {
        ref: "b6ffcaea-c9ef-4a4b-9f1c-230cbac410f2",
        name: "Microsoft RAI Impact Assessment: Section 1.2 - Lifecycle Stage",
        action: ["125a3c1e-d20d-4c57-ad43-c6e13a98d3c7"],
        section: 1,
        roles: ["1786c096-d195-478a-a6e4-1d4d7345ea74", "fa17d3c7-9e83-42ad-8cbb-a755a0013e80"],
      },
      {
        ref: "2aa6e30b-9ad5-43e1-ba97-6636ed01f010",
        name: "Microsoft RAI Impact Assessment: Section 1.3 - System Description",
        roles: ["fa17d3c7-9e83-42ad-8cbb-a755a0013e80", "1786c096-d195-478a-a6e4-1d4d7345ea74"],
        section: 1,
        action: ["e6cec082-ab2d-491f-be4c-c614976c339f"],
      },
      {
        ref: "7ed822c2-b337-4ada-bfc8-d876e16d0c34",
        name: "Microsoft RAI Impact Assessment: Section 1.4 - System Purpose",
        roles: [
          "089f1f14-0c02-4934-b6ce-a6019863aa18",
          "1786c096-d195-478a-a6e4-1d4d7345ea74",
          "fa17d3c7-9e83-42ad-8cbb-a755a0013e80",
        ],
        section: 1,
        action: ["62af6b17-9037-41ae-a2bc-21051a827c80"],
      },
      {
        ref: "34707d24-213f-4f94-b1ef-34fc182419b8",
        name: "Microsoft RAI Impact Assessment: Section 1.5 System Features",
        section: 1,
        roles: [
          "fed0c871-e7cd-4bf1-bf4d-e8bd7a24c595",
          "1786c096-d195-478a-a6e4-1d4d7345ea74",
          "fa17d3c7-9e83-42ad-8cbb-a755a0013e80",
        ],
        action: ["5c2240d3-0ea6-4c95-bf3b-08823385c7d0"],
      },
      {
        ref: "d052a900-20ba-4d71-aa2e-7cff7ce0344e",
        name: "Microsoft RAI Impact Assessment: Section 1.6 - Geographic Areas and Languages",
        roles: ["1e205bc2-f0a7-4342-87e9-7856853c0243"],
        action: [],
        section: 1,
      },
      {
        ref: "a4415f53-2c73-412c-804a-9dfd55cabba7",
        name: "Microsoft RAI Impact Assessment: Section 1.7 - Deployment Mode",
        roles: ["c8e0dcdb-3055-47f5-9e30-91ae3296f351"],
        action: ["5380d366-f0cb-40ca-ab3c-81e78ba0087d"],
        section: 1,
      },
      {
        ref: "1754e376-949b-4a45-bb4c-398414caf8d5",
        name: "Microsoft RAI Impact Assessment: Section 1.8 - Intended Uses",
        metadata_action: "CREATE_INTENDED_USES",
        roles: ["1786c096-d195-478a-a6e4-1d4d7345ea74"],
        section: 1,
        action: ["fac05e19-5893-42c1-891d-7f8b46828708"],
      },
    ],
  },
  {
    section_num: 2,
    name: "Intended Uses",
    questionnaires: [],
  },
  {
    section_num: 3,
    name: "Adverse Impact",
    questionnaires: [
      {
        ref: "efaf3e94-343c-4610-9af5-45fb47d9e7c5",
        name: "Microsoft RAI Impact Assessment: Section 3.1: Restricted uses",
        roles: [],
        action: [],
        section: 3,
      },
      {
        ref: "4c406e68-dc27-4fda-8cc8-e9789f07544a",
        name: "Microsoft RAI Impact Assessment: Section 3.2: Unsupported uses",
        roles: [],
        section: 3,
        action: [],
      },
      {
        ref: "e467cc56-160b-4d24-abec-a43bd2bf2613",
        name: "Microsoft RAI Impact Assessment: Section 3.3: Known limitations",
        roles: [],
        action: [],
        section: 3,
      },
      {
        ref: "f20e01bb-314d-4136-826d-43720933165e",
        name: "Microsoft RAI Impact Assessment: Section 3.4: Potential impact of failure on stakeholders",
        roles: [],
        action: [],
        section: 3,
      },
      {
        ref: "e2f1ff62-4117-4d85-abda-563f63e7e448",
        name: "Microsoft RAI Impact Assessment: Section 3.5: Potential impact of misuse on stakeholders",
        roles: [],
        action: [],
        section: 3,
      },
      {
        ref: "0adba727-75a4-445f-8264-bb4b568bd965",
        name: "Microsoft RAI Impact Assessment: Section 3.6: Sensitive Uses",
        roles: [],
        action: [],
        section: 3,
      },
    ],
  },
  {
    section_num: 4,
    name: "Data requirements",
    questionnaires: [
      {
        ref: "646c8828-7b49-4a04-870f-55a511b1ab8c",
        name: "Microsoft RAI Impact Assessment: Section 4.1: Data requirements",
        roles: [],
        action: [],
        section: 4,
      },
      {
        ref: "a91fc9e2-96cb-4a2d-a2b4-3eec85738222",
        name: "Microsoft RAI Impact Assessment: Section 4.2: Existing data sets",
        roles: [],
        action: [],
        section: 4,
      },
    ],
  },
  {
    section_num: 5,
    name: "Summary of Impact",
    questionnaires: [
      {
        ref: "071c3b80-0b94-4d7c-8237-ce4a16cf905d",
        name: "Microsoft RAI Impact Assessment: Section 5.1: Summary of Impact",
        roles: [],
        action: [],
        section: 5,
      },
      {
        ref: "5f5a4b16-2e4a-4d79-a086-db17318022c0",
        name: "Microsoft RAI Impact Assessment: Section 5.2: Goal Applicability",
        roles: [],
        action: [],
        section: 5,
      },
    ],
  },
];

export { microsoft_rai_base_forms };
