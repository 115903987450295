import { CustomField, CustomFieldValue } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const endpoints = {
  custom_fields: () => "/custom_fields",
  custom_field: (objectId: string) => `/custom_fields/${objectId}`,
  custom_fields_value: () => "/custom_field_values",
  custom_field_value: (objectId: string) => `/custom_field_values/${objectId}`,
};

export const getCustomFields = async () => {
  const response = await httpGetAuthenticated<ListResponse<CustomField>>(endpoints.custom_fields());
  return response;
};

export const getCustomField = async (objectId: string) => {
  const response = await httpGetAuthenticated<CustomField>(endpoints.custom_field(objectId));
  return response;
};

export const addCustomField = async (
  objectId: string,
  params: { field_type: string; field_name: string; description?: string }
) => {
  const response = await httpPostAuthenticated(endpoints.custom_fields(), {
    ...params,
    parent_object_id: objectId,
  });
  queryClient.invalidateQueries({ queryKey: [QueryKey.CustomField] });
  return response;
};

export const editCustomField = async (
  fieldId: string,
  params: { field_type?: string; field_name?: string; description?: string },
  queryKey: string
) => {
  const response = await httpPatchAuthenticated(endpoints.custom_field(fieldId), params);
  queryClient.invalidateQueries({ queryKey: [QueryKey.CustomField] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.CustomFieldValue] });
  return response;
};

export const deleteCustomField = async (fieldId: string) => {
  const response = await httpDeleteAuthenticated(endpoints.custom_field(fieldId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.CustomField] });
  return response;
};

export const deleteCustomFieldValue = async (fieldId: string, queryKey: string) => {
  const response = await httpDeleteAuthenticated(endpoints.custom_field_value(fieldId), {});
  queryClient.invalidateQueries({ queryKey: [QueryKey.CustomFieldValue] });
  return response;
};

export const editCustomFieldValue = async (
  fieldId: string,
  params: { value: string },
  queryKey: string
) => {
  const response = await httpPatchAuthenticated(endpoints.custom_field_value(fieldId), params);
  queryClient.invalidateQueries({ queryKey: [QueryKey.CustomFieldValue] });
  return response;
};

export const addCustomFieldValue = async (
  objectId: string,
  params: { custom_field: string; value: string },
  queryKey: string
) => {
  const response = await httpPostAuthenticated(endpoints.custom_fields_value(), {
    ...params,
    parent_object_id: objectId,
  });
  queryClient.invalidateQueries({ queryKey: [QueryKey.CustomFieldValue] });
  return response;
};

export const getCustomFieldValues = (params: any) => {
  return httpGetAuthenticated<ListResponse<CustomFieldValue>>(endpoints.custom_fields_value(), {
    params,
  });
};

export const getCustomFieldValue = (objectId: string) => {
  return httpGetAuthenticated<CustomFieldValue>(endpoints.custom_field_value(objectId));
};
