import { Box, useTheme } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { UserSettingsLoader } from "../../organisms/skeleton-loaders/UserSettingsLoader";

export const UserPageLoader = () => {
  const theme = useTheme();
  return (
    <>
      <Box
        display="flex"
        width="100%"
        marginTop="15px"
        border="1px solid"
        borderBottom={0}
        borderColor={theme.palette.custom.secondaryBorder}
        bgcolor={theme.palette.custom.secondaryBackground}
        flexDirection="column"
        sx={{
          backgroundColor: "background.paper",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
        }}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          padding="10px"
          bgcolor={theme.palette.custom.secondaryBackground}
          sx={{ borderTopLeftRadius: "6px", borderTopRightRadius: "6px" }}
        >
          <Box minHeight="35px" display="flex" gap="10px">
            <RectangularLoader height="35px" width="108px" />
            <RectangularLoader height="35px" width="108px" />
          </Box>
          <RectangularLoader height="35px" width="143px" />
        </Box>
      </Box>
      <UserSettingsLoader />
    </>
  );
};
