import { NavigateFunction, useNavigate } from "react-router-dom";
import { getFinalNumberFromPath, getLastPathByRegex } from "../utilities/UIHelper";
import { useQuery } from "react-query";
import { fetchFrameworks } from "../hooks/useFramework";

const FRAMEWORK_NAVIGATOR_KEY = "framework-navigator";
export const useFrameworkPathState = (locationState: any, path: string) => {
  const frameworkId = locationState?.frameworkId;
  const edit = locationState?.edit;
  const lastPath = getLastPathByRegex(path);
  const number = getFinalNumberFromPath(lastPath);
  const navigate = useNavigate();
  return useQuery<{
    frameworkId: string;
    edit: boolean;
  }>(
    [FRAMEWORK_NAVIGATOR_KEY, path],
    async () => {
      if (frameworkId != null) {
        return { frameworkId, edit };
      }

      const list = await fetchFrameworks({ framework_num: [number], fairo_data: true });
      const obj = list.results.find((o) => o.framework_num === Number(number));
      if (!obj) {
        navigate("/oversight/frameworks");
        return { frameworkId: null, edit };
      }

      return { frameworkId: obj.id, edit };
    },
    { suspense: true }
  );
};

const navigateToFramework = (params: {
  navigate: NavigateFunction;
  frameworkId: string;
  frameworkNumber: number;
  edit?: boolean;
}) => {
  const { frameworkId, frameworkNumber, edit, navigate } = params;
  navigate(`/oversight/frameworks/framework-${frameworkNumber}`, {
    state: {
      frameworkId,
      edit,
    },
  });
};

export { navigateToFramework };
