import { Box, useTheme } from "@mui/material";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { ScreenSmallerThen } from "../../utilities/UIHelper";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { Role } from "../../models/types";

type ListViewWithTabsLoaderProps = {
  type?: string;
  bigFixedHeight?: string;
};

export const ListViewWithTabsLoader = (props: ListViewWithTabsLoaderProps) => {
  const { type, bigFixedHeight = "80vh" } = props;
  const theme = useTheme();

  const roleColumnsVisibility: IColumnVisibility<Role>[] = [
    {
      field: "role_num",
      headerName: "Role",
      visible: true,
    },
    { field: "name", headerName: "Name", visible: true },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
    },
  ];

  const fillBlankSpace = true;
  const isSmallScreen = ScreenSmallerThen("1100px");
  const bigFixedHeightSize = isSmallScreen ? "100%" : bigFixedHeight;

  return (
    <>
      <Box
        display="flex"
        width="100%"
        marginTop="15px"
        border="1px solid"
        borderBottom={0}
        borderColor={theme.palette.custom.secondaryBorder}
        bgcolor={theme.palette.custom.secondaryBackground}
        flexDirection="column"
        sx={{
          backgroundColor: "background.paper",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
        }}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          padding="10px"
          bgcolor={theme.palette.custom.secondaryBackground}
          sx={{ borderTopLeftRadius: "6px", borderTopRightRadius: "6px" }}
        >
          <Box minHeight="35px" display="flex" gap="10px">
            <RectangularLoader height="35px" width="108px" />
            <RectangularLoader height="35px" width="108px" />
            <RectangularLoader height="35px" width="108px" />
          </Box>
          <RectangularLoader height="35px" width="143px" />
        </Box>
      </Box>
      <Box paddingBottom="80px">
        <Box
          sx={{
            maxWidth: "100%",
            width: "100%",
            height: fillBlankSpace ? "100%" : "unset",
            overflow: "visible",
          }}
        >
          <Box height={bigFixedHeight ? bigFixedHeightSize : fillBlankSpace ? "100%" : "unset"}>
            <Box
              paddingBottom={"0px"}
              borderRadius="6px"
              border="1px solid"
              minHeight="100%"
              maxHeight="100%"
              overflow="auto"
              borderColor={theme.palette.custom.secondaryBorder}
              bgcolor={theme.palette.custom.secondaryBackground}
              sx={{ borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}
            >
              <APITableLoader
                columnsVisibility={type === "role" ? roleColumnsVisibility : []}
                tableSize={"big"}
                secondComponent={true}
                tableSearch={true}
                filtersVisibility={false}
                dateFilters={false}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
