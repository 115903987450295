import { NavigateFunction } from "react-router-dom";
import { navigateToAction } from "../components/ActionNavigator";
import { navigateToAsset } from "../components/AssetNavigator";
import { navigateToRegistry } from "../components/ComplianceNavigator";
import { navigateToElement } from "../components/ElementNavigator";
import { navigateToFramework } from "../components/FrameworkNavigator";
import { navigateToImpactAssessment } from "../components/ImpactAssessmentNavigator";
import { navigateToResource } from "../components/ResourceNavigator";
import { navigateToRole } from "../components/RoleNavigator";
import {
  navigateToMetric,
  navigateToTestCase,
  navigateToTestRun,
} from "../components/TestNavigator";
import {
  navigateToProcessExecutionPlan,
  navigateToProcessGraph,
  navigateToWorkflow,
} from "../components/WorkflowNavigator";
import config from "../config/config";
import { RegistryFieldType } from "../models/types";
import { getAction } from "../services/ActionService";
import { getAsset } from "../services/AssetService";
import { getComplianceElement } from "../services/ComplianceElements";
import { getTestResult } from "../services/DataService";
import {
  getExecutionItem,
  getExecutionPlan,
  getProcessGraph,
  getWorkflow,
} from "../services/ExecutionPlanService";
import { getFramework } from "../services/FrameworkService";
import { getImpactAssessment } from "../services/ImpactAssessmentService";
import { getMetricResult } from "../services/MetricResultService";
import { getMetric } from "../services/MetricService";
import { getQuestionnaire } from "../services/QuestionnaireService";
import { getRegistryItem } from "../services/RegistryItemsService";
import { getRegistry } from "../services/RegistryService";
import { getResource } from "../services/ResourceService";
import { getRole } from "../services/RoleService";
import { getTestCase } from "../services/TestCaseService";
import { getTestRun } from "../services/TestRunService";
import { isCreatedByFairo } from "./UIHelper";

const useNavigateToObject = async (
  navigate: NavigateFunction,
  fieldType: RegistryFieldType,
  objectId: string,
  setSelectedTask: (taskId: string) => void
) => {
  switch (fieldType) {
    case "Action":
    case "Action list":
      const { data: action } = await getAction(objectId);
      return navigateToAction({
        navigate,
        actionId: action.id,
        actionNumber: action.action_num,
        edit: false,
      });
    case "Process Graph":
    case "Process Graph list":
      const { data: processGraph } = await getProcessGraph(objectId);
      return navigateToProcessGraph({
        navigate,
        processGraphId: processGraph.id,
        processGraphNumber: processGraph.process_graph_num,
        edit: false,
        workflowNumber: processGraph.workflow_num,
      });
    case "Workflow":
    case "Workflow list":
      const { data: workflow } = await getWorkflow(objectId);
      return navigateToWorkflow({
        navigate,
        workflowId: workflow.id,
        workflowNumber: workflow.workflow_num,
        edit: false,
      });
    case "Role":
    case "Role list":
      const { data: role } = await getRole(objectId);
      return navigateToRole({
        navigate,
        roleId: role.id,
        roleNumber: role.role_num,
        edit: false,
      });
    case "Metric":
    case "Metric list":
      const { data: metric } = await getMetric(objectId, { fairo_data: true });
      return navigateToMetric({
        navigate,
        metricId: metric.id,
        metricNumber: metric.metric_num,
        edit: false,
      });
    case "Test Case":
    case "Test Case list":
      const { data: testCase } = await getTestCase(objectId);
      return navigateToTestCase({
        navigate,
        testCaseId: testCase.id,
        testNumber: testCase.test_case_num,
        edit: false,
      });
    case "Test Run":
    case "Test Run list":
      const { data: testRun } = await getTestRun(objectId);
      return navigateToTestRun({
        navigate,
        testRunId: testRun.id,
        testRunNumber: testRun.test_run_num,
        edit: false,
      });
    case "Asset":
    case "Asset list":
      const { data: asset } = await getAsset(objectId);
      return navigateToAsset({
        navigate,
        assetId: asset.id,
        assetNumber: asset.asset_num,
        edit: false,
      });
    case "User":
      break;
    case "Registry Item":
    case "Registry Item list":
      const { data: registryItem } = await getRegistryItem(objectId);
      const { data: registry } = await getRegistry(registryItem.registry, { fairo_data: true });
      return navigateToRegistry({
        navigate,
        registryId: registryItem.registry,
        registryNumber: registry.registry_num,
        edit: false,
      });
    case "Framework":
    case "Framework list":
      const { data: framework } = await getFramework(objectId, { fairo_data: true });
      return navigateToFramework({
        navigate,
        frameworkId: framework.id,
        frameworkNumber: framework.framework_num,
        edit: false,
      });
    case "Policy":
    case "Policy list":
      break;
    case "Element":
    case "Element list":
      const { data: element } = await getComplianceElement(objectId, { fairo_data: true });
      return navigateToElement({
        navigate,
        elementId: element.id,
        external_id: element.external_id,
        fairoData: isCreatedByFairo(element.created_by),
      });
    case "Integration":
    case "Integration list":
      return navigate("/organization/integrations");
    case "Resource":
    case "Resource list":
      const { data: resource } = await getResource(objectId);
      return navigateToResource({
        navigate,
        resourceId: objectId,
        resourceNumber: resource.resource_num,
        edit: false,
      });
    case "Evidence":
    case "Evidence list":
      return null;
    case "Registry":
    case "Registry list":
      const { data: registryData } = await getRegistry(objectId, { fairo_data: true });
      return navigateToRegistry({
        navigate,
        registryId: registryData.id,
        registryNumber: registryData.registry_num,
        edit: false,
      });
    case "Task":
      return setSelectedTask(objectId);
    case "Task list":
      return setSelectedTask(objectId);
    case "MLFlow Model":
      return window.open(`${config.mlflow.baseUrl}/mlflow-static/#/models/${objectId}`, "_blank");
    case "Hyperlink":
      return window.open(objectId, "_blank");
    case "Report":
      // @TODO implement generic modal opening here to centralize navigation logic
      return null;
    case "Questionnaire":
      const { data: questionnaire } = await getQuestionnaire(objectId);
      const { data: questionnaireImpactAssessment } = await getImpactAssessment(
        questionnaire.impact_assessment
      );
      return navigateToImpactAssessment({
        navigate,
        impactAssessmentId: questionnaireImpactAssessment.id,
        impactAssessmentNumber: questionnaireImpactAssessment.impact_assessment_num,
        section: questionnaire?.metadata?.section,
      });
    case "Process Execution":
      const { data: processExecution } = await getExecutionPlan(objectId);
      return navigateToProcessExecutionPlan({
        navigate,
        workflowNumber: processExecution.workflow_num,
        executionPlanId: processExecution.id,
        executionPlanNumber: processExecution.process_execution_plan_num,
        processGraphId: processExecution.process_graph,
      });
    case "Process Execution Item":
      // @TODO add the filter to table item on navigate
      const { data: executionItem } = await getExecutionItem(objectId);
      return navigateToProcessExecutionPlan({
        navigate,
        workflowNumber: executionItem.workflow_num,
        executionPlanId: executionItem.process_execution_plan,
        executionPlanNumber: executionItem.process_execution_plan_num,
        processGraphId: executionItem.process_graph,
      });
    case "Metric Result":
      const { data: result } = await getMetricResult(objectId);
      const { data: metricValue } = await getMetric(result.metric, { fairo_data: true });
      return navigateToMetric({
        navigate,
        metricId: result.metric,
        metricNumber: metricValue.metric_num,
      });
    case "Test Result":
      const { data: testResult } = await getTestResult(objectId);
      const testCaseId =
        typeof testResult.test_case === "string" ? testResult.test_case : testResult.test_case.id;
      const { data: testCaseResult } = await getTestCase(testCaseId);
      return navigateToTestCase({
        navigate,
        testCaseId: testCaseResult.id,
        testNumber: testCaseResult.test_case_num,
      });
    case "Impact Assessment":
      const { data: impactAssessment } = await getImpactAssessment(objectId);
      return navigateToImpactAssessment({
        navigate,
        impactAssessmentId: impactAssessment.id,
        impactAssessmentNumber: impactAssessment.impact_assessment_num,
      });
    default:
      if ((fieldType as any) === "ComplianceElement") {
        const { data: element } = await getComplianceElement(objectId, { fairo_data: true });
        return navigateToElement({
          navigate,
          elementId: element.id,
          external_id: element.external_id,
          fairoData: isCreatedByFairo(element.created_by),
        });
      }
      return null;
  }
};

export { useNavigateToObject as navigateToObject };
