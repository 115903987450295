import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography/Typography";

type TableTextWithEllipsisProps = {
  value: string;
  onClick?: () => void;
  expanded?: boolean;
  hideTooltip?: boolean;
  fullWidth?: boolean;
  color?: string | null;
  variant?: TypographyProps["variant"];
  icon?: React.ReactNode;
};

const TableTextWithEllipsis = (props: TableTextWithEllipsisProps) => {
  const { value, onClick, expanded, hideTooltip, color, fullWidth = true, variant, icon } = props;
  const hasLink = !!onClick;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const buttonColor = isDarkMode
    ? theme.palette.custom.whiteTypography
    : theme.palette.custom.blueTypography;

  const returnValue = () => (
    <Typography
      color={color ? color : hasLink ? buttonColor : theme.palette.custom.secondaryTypography}
      textOverflow="ellipsis"
      whiteSpace={expanded ? "pre-line" : "nowrap"}
      overflow="hidden"
      variant={variant ? variant : hasLink ? "button" : "body2"}
      sx={{
        textDecoration: hasLink ? "underline" : "none",
        overflowWrap: expanded ? "break-word" : "nowrap",
        "&:hover": {
          textDecoration: "none",
          cursor: hasLink ? "pointer" : "unset",
        },
      }}
    >
      {icon}
      {value}
    </Typography>
  );

  return (
    <Box
      width={fullWidth ? "100%" : "unset"}
      maxWidth={fullWidth ? "100%" : "unset"}
      overflow="hidden"
      textOverflow="ellipsis"
      onClick={onClick}
      sx={{
        cursor: hasLink ? "pointer" : "unset",
        color: color ? color : hasLink ? buttonColor : theme.palette.custom.secondaryTypography,
        overflowWrap: expanded ? "break-word" : "nowrap",
      }}
    >
      {!hideTooltip ? (
        <Tooltip
          leaveDelay={200}
          enterNextDelay={500}
          enterDelay={500}
          title={value}
          arrow
          placement="bottom"
          sx={{
            cursor: "pointer",
          }}
        >
          {returnValue()}
        </Tooltip>
      ) : (
        returnValue()
      )}
    </Box>
  );
};

export { TableTextWithEllipsis };
