import { Box, Typography, useTheme } from "@mui/material";
import { RegistryFieldType } from "../models/types";
import { defaultDateDisplay } from "../utilities/UIHelper";

type DisplayReadOnlyCustomFieldProps = {
  field_type: RegistryFieldType;
  value: string;
  sx?: any;
  disabled: boolean;
};

export const NameAndDescriptionReadOnly = (props: { value: string; disabled: boolean }) => {
  const { value, disabled } = props;
  const parsedValue = JSON.parse(value);
  const disabledTypographyEffect = {
    opacity: disabled ? 0.5 : 1,
  };
  return (
    <Box display="flex" flexDirection="column" gap="5px">
      {parsedValue.map((item: any, index: number) => {
        const obj = JSON.parse(item);
        return (
          <>
            <Typography
              fontWeight={600}
              sx={{
                ...(disabled && disabledTypographyEffect),
              }}
              variant="body2"
            >
              {obj.name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                ...(disabled && disabledTypographyEffect),
              }}
            >
              {obj.description}
            </Typography>
          </>
        );
      })}
    </Box>
  );
};

export const ExternalLink = (props: { value: string; disabled: boolean }) => {
  const { value, disabled } = props;
  const parsedValue = JSON.parse(value);
  const disabledTypographyEffect = {
    opacity: disabled ? 0.5 : 1,
  };
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap="5px">
      {parsedValue.map((item: any, index: number) => {
        const obj = JSON.parse(item);
        return (
          <>
            <Typography
              fontWeight={600}
              sx={{
                ...(disabled && disabledTypographyEffect),
              }}
              variant="body2"
            >
              {obj.description}
            </Typography>
            <Typography
              onClick={() => !disabled && window.open(obj.link, "_blank")}
              variant="body2"
              color={theme.palette.custom.blueTypography}
              sx={{
                cursor: disabled ? "default" : "pointer",
                ...(disabled && disabledTypographyEffect),
              }}
            >
              {obj.link}
            </Typography>
          </>
        );
      })}
    </Box>
  );
};

export const DisplayReadOnlyCustomField = (props: DisplayReadOnlyCustomFieldProps) => {
  const { field_type, value, disabled } = props;
  const disabledTypographyEffect = {
    opacity: disabled ? 0.5 : 1,
  };
  return (
    <Box paddingLeft="10px">
      {(() => {
        switch (field_type) {
          case "Date":
            return (
              <Typography
                sx={{
                  ...(disabled && disabledTypographyEffect),
                }}
                variant="body2"
              >
                {defaultDateDisplay(value)}
              </Typography>
            );
          case "Name and Description list":
            return <NameAndDescriptionReadOnly value={value} disabled={disabled} />;
          case "External Link":
            return <ExternalLink value={value} disabled={disabled} />;
          default:
            return (
              <Typography
                sx={{
                  ...(disabled && disabledTypographyEffect),
                }}
                variant="body2"
              >
                {value}
              </Typography>
            );
        }
      })()}
    </Box>
  );
};
