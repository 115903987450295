import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import { IColumnVisibility } from "../molecules/ColumnVisibility";
import { EmptyTable } from "../molecules/EmptyTable";
import LoaderLabeled from "./LoaderLabeled";

type TableProps<T extends { id: string }> = {
  /** Column display information */
  columnsVisibility: IColumnVisibility<T>[];
  /** Is the data loading? */
  isLoading?: boolean;
  /** Data for the rows to display*/
  rows: T[] | null;
  /** `onClick` handler for the row*/
  rowOnClick?: (row: T) => void;
  /** Empty table component */
  emptyTableComponent?: React.ReactNode;
  /** Callback to show all columns */
  onShowAllColumns?: () => void;
  /** Small empty version */
  smallEmptyHeight?: boolean;
  /** Height used for hidding/showing the table with effect */
  visible?: boolean;
  borderTopRadius?: string;
};

export const Table = <T extends { id: string }>(props: TableProps<T>) => {
  const {
    smallEmptyHeight,
    columnsVisibility,
    isLoading,
    rows,
    rowOnClick,
    emptyTableComponent,
    onShowAllColumns,
    visible = true,
    borderTopRadius = "0px",
  } = props;
  const containerHeight = visible ? "100%" : "0px";
  const theme = useTheme();
  const visibleColumns = columnsVisibility.filter((col) => col.visible);
  const noColumnsVisible = !visibleColumns.length;
  const isTableEmpty = (rows && rows.length === 0) || noColumnsVisible;
  return (
    <>
      <TableContainer
        sx={{
          borderRadius: 0,
          boxShadow: "none",
          border: "none",
          display: "flex",
          flex: isTableEmpty ? 1 : 0,
          height: containerHeight,
          "& .MuiTableRow-root": {
            borderTop: "1px solid",
            borderTopLeftRadius: borderTopRadius,
            borderTopRightRadius: borderTopRadius,
            borderTopColor: theme.palette.custom.secondaryBorder,
          },
          "& .MuiTableCell-root": {
            borderBottom: "unset",
          },
        }}
      >
        <MuiTable sx={{ borderRadius: 0 }}>
          <TableHead
            sx={{
              borderRadius: 0,
              borderBottom: "1px solid",
              borderBottomColor: theme.palette.custom.secondaryBorder,
            }}
          >
            <TableRow>
              {visibleColumns.map((col, idx) => (
                <TableCell
                  key={col.field}
                  sx={{
                    height: "35px",
                    padding: 0,
                    maxWidth: col.columnMaxWidth,
                    width: col.columnMaxWidth,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {idx > 0 && (
                      <Divider sx={{ height: "14px", width: "2px" }} orientation="vertical" />
                    )}
                    <Typography
                      variant="body2"
                      sx={{
                        padding: "0 10px 0 10px",
                        color: theme.palette.custom.withoutFillButton,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {col.headerName}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!!isLoading || rows == null ? (
            <caption
              style={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <LoaderLabeled />
            </caption>
          ) : (
            <TableBody>
              {isTableEmpty ? (
                <TableCell colSpan={visibleColumns.length}>
                  <Box
                    sx={{
                      width: "100%",
                      height: smallEmptyHeight ? "174px" : "440px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {noColumnsVisible ? (
                      <EmptyTable
                        variant="no-visible"
                        label="No Columns Selected"
                        description="Select columns to see content here"
                        actionLabel="Show All Columns"
                        action={onShowAllColumns}
                      />
                    ) : (
                      emptyTableComponent ?? <EmptyTable label="No Data" />
                    )}
                  </Box>
                </TableCell>
              ) : (
                rows.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      height: "48px",
                      ...(rowOnClick && {
                        cursor: "pointer",
                      }),
                    }}
                    onClick={(e) => {
                      if (rowOnClick != null) {
                        rowOnClick(row);
                        e.stopPropagation();
                      }
                    }}
                  >
                    {visibleColumns.map(({ field, columnOnClick, renderer }, index) => {
                      const tableCellMinWidth = (minWidth: number = 80) => {
                        try {
                          const maxSize = visibleColumns[index]?.columnMaxWidth ?? minWidth;
                          return `${maxSize < minWidth ? maxSize : minWidth}px`;
                        } catch (ex) {
                          return `${minWidth}px`;
                        }
                      };
                      if ((row as any).fullRowWidth && index > 0) {
                        return null;
                      } else
                        return (
                          <TableCell
                            colSpan={(row as any).fullRowWidth ? visibleColumns.length : 1}
                            key={field}
                            id={(row as any).id}
                            sx={{
                              minWidth: tableCellMinWidth(),
                              maxWidth: "300px",
                              ...(columnOnClick && {
                                cursor: "pointer",
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              }),
                            }}
                            onClick={(e) => {
                              if (columnOnClick != null) {
                                columnOnClick(row);
                                e.stopPropagation();
                              }
                            }}
                          >
                            {renderer == null ? (row as any)[field] : renderer(row)}
                          </TableCell>
                        );
                    })}
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </MuiTable>
      </TableContainer>
    </>
  );
};
