import { useQuery } from "react-query";
import { getFile, getFiles } from "../services/FileService";
import { QueryKey } from "../state/QueryStore";

export const useFiles = (objectId: string) => {
  return useQuery([QueryKey.File, objectId], async () => {
    const { data } = await getFiles({ parent_object_id: objectId });
    return data.results;
  });
};

export const useFile = (fileId: string) => {
  return useQuery([QueryKey.File, fileId], async () => {
    if (fileId === "") return undefined;
    const { data } = await getFile({ id: fileId });
    return data;
  });
};
