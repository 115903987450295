import { Box, Tooltip, Typography, useTheme } from "@mui/material";

interface IBadgeProps {
  name: string;
  onClick: () => void;
  hyperlink?: string;
  hyperlinkOnClick?: () => void;
  maxWidth?: string;
  color?: string;
  typography?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "subtitle1"
    | "body1"
    | "button"
    | "body2"
    | "small"
    | "navbar"
    | "navbarSelected"
    | "logo"
    | "code";
  fontSize?: string;
}

const BadgeOption = ({
  name,
  onClick,
  hyperlink,
  hyperlinkOnClick,
  color,
  typography = "body2",
  fontSize,
  maxWidth = "100%",
}: IBadgeProps) => {
  const theme = useTheme();
  return (
    <Box
      padding={"5px"}
      borderRadius="38px"
      border={`1px solid ${theme.palette.custom.primaryBorder}`}
      alignItems="center"
      onClick={() => !hyperlinkOnClick && onClick()}
      display="flex"
      flexDirection="row"
      maxWidth={maxWidth}
      sx={{
        cursor: "pointer",
        background: color ? `${color}80` : theme.palette.custom.planBackground,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      <Tooltip
        leaveDelay={200}
        enterNextDelay={500}
        enterDelay={500}
        title={`${name}${hyperlink ? hyperlink : ""}`}
        arrow
        placement="bottom"
        sx={{
          cursor: "pointer",
        }}
      >
        <Typography
          color={theme.palette.custom.primaryTypography}
          variant={typography}
          fontSize={fontSize}
          onClick={() => hyperlinkOnClick && onClick()}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            ":hover": {
              cursor: "pointer",
            },
          }}
        >
          {`${name}`}
          {hyperlink && (
            <span
              onClick={() => hyperlinkOnClick && hyperlinkOnClick()}
              style={{
                textDecoration: "underline",
                color: theme.palette.custom.blueTypography,
              }}
            >
              {hyperlink}
            </span>
          )}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export { BadgeOption };
