import { Box, Typography, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { SearchableDropdown } from "../../../components/SearchableDropdown";
import { searchUsers } from "../../../hooks/useUsers";
import { User } from "../../../models/types";

type QuestionnaireAssignmentProps = {
  questionnaire: any;
  sectionAssignedUser: User | null;
  assignees: {
    ref: string;
    assignee: User | null;
  }[];
  setAssignees: (assginees: any) => void;
};

export const QuestionnaireAssignment = (props: QuestionnaireAssignmentProps) => {
  const { questionnaire, sectionAssignedUser, assignees, setAssignees } = props;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const secondaryColor = theme.palette.custom.secondaryTypography;

  const [expanded, setExpanded] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(
    assignees.find((assignee) => assignee.ref === questionnaire.id)?.assignee ?? sectionAssignedUser
  );

  useEffect(() => {
    setAssignees([
      ...assignees.filter((assignee) => assignee.ref !== questionnaire.ref),
      { ref: questionnaire.ref, assignee: selectedUser },
    ]);
  }, [selectedUser]);

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Box
        display="flex"
        gap="10px"
        justifyContent="space-between"
        alignItems="center"
        paddingBottom="10px"
        borderBottom={`1px solid ${theme.palette.custom.secondaryBorder}`}
      >
        <Typography variant="h4" color={secondaryColor}>
          {questionnaire.name.split("Section")[1].trim()}
        </Typography>
        <Box display="flex" gap="10px">
          <Box width="263px">
            <SearchableDropdown<User>
              label="Select or Search"
              value={selectedUser}
              onChange={setSelectedUser}
              getOptionLabel={(user) => user.name}
              getOptions={searchUsers}
              isOptionEqualToValue={(a, b) => a.id === b.id}
              getSummaryDescription={(user) => [user.name, user.username]}
            />
          </Box>
          {/* <IconButton onClick={() => setExpanded(!expanded)}>
            <NavbarIcon
              variant={expanded ? "chevron-up" : "chevron-down"}
              sx={{
                width: "16",
                height: "13",
              }}
              color={
                isDarkMode
                  ? theme.palette.custom.whiteTypography
                  : theme.palette.custom.blueTypography
              }
            />
          </IconButton> */}
        </Box>
      </Box>
      {/* {expanded &&
        ((questionnaire.metadata?.roles?.length ?? 0) > 0 ? (
          <Box display="flex" flexDirection="column" gap="10px">
            {questionnaire.metadata?.roles?.map((role) => (
              <RolesAssignment roleId={role} />
            ))}
          </Box>
        ) : (
          <Typography variant="body2">No roles assigned!</Typography>
        ))} */}
    </Box>
  );
};
