import { LoadingButton } from "@mui/lab";
import { Box, IconButton, Stack, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { openSnackbar } from "../context/SnackbarContext";
import { useInputState } from "../hooks/useInputTextState";
import { getAPIErrorMessage } from "../utilities/ApiResponseHelper";
import { Awaitable } from "../utilities/common";
import { SmallModal } from "./SmallModal";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { SearchableDropdown } from "./SearchableDropdown";
import { Department, PartialUser, User } from "../models/types";
import { searchUsers } from "../hooks/useUsers";
import { searchDepartments } from "../hooks/useDepartments";
import { inviteUser, updateOrgChartSettings, useUsers } from "../services/UserSettingsService";
import { sendIntercomEvent } from "../services/IntercomService";
import { useAuth } from "../hooks/useAuth";
import { GenericListCustomField } from "../organisms/GenericCustomField";
import { searchRoles, useRoles } from "../hooks/useRoles";
import { addUserToRole, removeUserFromRole } from "../services/DataService";

type InviteUserModalProps = {
  open: boolean;
  onClose: () => void;
  userId?: string | null;
  openCreateDepartment?: () => void;
};
export const InviteUserModal = (props: InviteUserModalProps) => {
  const { open, onClose, userId, openCreateDepartment } = props;
  const [name, setName, nameError, setNameError] = useInputState("");
  const [email, setEmail, emailError, setEmailError] = useInputState("");
  const [jobTitle, setJobTitle] = useState<string | undefined>(undefined);
  const [department, setDepartment] = useState<Pick<Department, "id" | "name"> | null>(null);
  const [manager, setManager] = useState<Pick<User, "id" | "name"> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<string[]>([]);
  const { organization } = useAuth();
  const onInviteUser = async (
    name: string,
    email: string,
    job_title?: string,
    department?: string,
    manager?: string
  ): Promise<string | null> => {
    if (organization) {
      const invitedUser = await inviteUser(organization.id, {
        name,
        email,
        job_title,
        department,
        manager,
      });
      return invitedUser.data.id;
    }
    return null;
  };

  const handleUserRoles = async (newUserId?: string | null) => {
    const userToUpdate = newUserId ? newUserId : userId;
    if (userToUpdate) {
      await Promise.all([
        user?.roles?.map(async (userRole: PartialUser) => {
          // Role doesn't exist on new array and is attached to the user, remove it
          if (!roles.some((role) => role === userRole.id)) {
            await removeUserFromRole(userToUpdate, userRole.id);
          }
        }),
        roles?.map(async (role) => {
          // Role doesn't exist on the user, add it
          if (!user?.roles?.some((userRole: PartialUser) => userRole.id === role)) {
            await addUserToRole(userToUpdate, role);
          }
        }),
      ]);
    } else {
      openSnackbar("Failed to update user roles", "error");
    }
  };

  const onEditUserOrgChartSettings = async (
    userId: string,
    payload: {
      jobTitle?: string;
      department?: string;
      manager?: string;
    }
  ) => {
    await updateOrgChartSettings(userId, {
      job_title: payload.jobTitle ?? "",
      department: payload.department ?? null,
      manager: payload.manager ?? null,
    });
  };

  const submit = async () => {
    let isError = false;

    if (!name.trim()) {
      setNameError(true);
      isError = true;
    }
    if (name.split(" ").length < 2) {
      setNameError(true);
      isError = true;
    }
    // TODO: validate email address
    if (!email.trim()) {
      setEmailError(true);
      isError = true;
    }
    if (isError) {
      return;
    }
    if (userId) {
      try {
        setIsLoading(true);
        await Promise.all([
          onEditUserOrgChartSettings(userId, {
            jobTitle,
            department: department?.id,
            manager: manager?.id,
          }),
          handleUserRoles(),
        ]);
        openSnackbar("User org settings updated", "success");
        onclose();
      } catch (e) {
        openSnackbar("Failed to update org settings", "error");
      } finally {
        setIsLoading(false);
      }
      return;
    }
    try {
      setIsLoading(true);
      const newUserId = await onInviteUser(name, email, jobTitle, department?.id, manager?.id);
      await handleUserRoles(newUserId);
      sendIntercomEvent("invite-user");
      openSnackbar(`Invite email sent to ${email}`, "success");
      onclose();
    } catch (e) {
      const errorMessage = getAPIErrorMessage(e as any, "Failed to invite user");
      openSnackbar(errorMessage, "error");
    } finally {
      setIsLoading(false);
    }
  };
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const subtitleColor = theme.palette.custom.secondaryTypography;

  const [orgSettingsVisible, setOrgSettingsVisible] = useState(false);

  const { data: user, isLoading: isUserLoading, isFetching } = useUsers(userId ?? "");

  const onclose = () => {
    onClose();
    setName("");
    setEmail("");
    setJobTitle(undefined);
    setDepartment(null);
    setManager(null);
    setOrgSettingsVisible(false);
    setRoles([]);
  };

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setJobTitle(user.job_title);
      setDepartment(user.department_name);
      setManager(user.manager_name);
      setRoles(user?.roles?.map((role: any) => role.id) ?? []);
    }
  }, [user]);

  const handleOnChangeRoles = (value: any | null, optionalParams?: any) => {
    setRoles(value);
  };

  return (
    <SmallModal
      open={open}
      onClose={() => {
        onclose();
      }}
      title={userId ? "Edit User" : "Invite User"}
      isLoading={isUserLoading || isFetching}
    >
      <Stack gap="10px">
        {!userId && <Typography variant="body2">Add a new user to your organization</Typography>}
        <Box display="flex" flexDirection={userId ? "row" : "column"} gap="10px">
          <Typography variant="h3" color={subtitleColor} width="96px">
            Name
          </Typography>
          {userId ? (
            <Typography variant="body2" color={subtitleColor}>
              {name}
            </Typography>
          ) : (
            <TextField
              required
              error={nameError}
              helperText={nameError ? "Provide user first and last name" : null}
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          )}
        </Box>
        <Box display="flex" flexDirection={userId ? "row" : "column"} gap="10px">
          <Typography variant="h3" color={subtitleColor} width="96px">
            Work email
          </Typography>
          {userId ? (
            <Typography variant="body2" color={subtitleColor}>
              {email}
            </Typography>
          ) : (
            <TextField
              required
              error={emailError}
              helperText={emailError ? "Invalid email address" : null}
              label="Work email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap="10px">
          <Typography variant="h3" color={subtitleColor}>
            Roles
          </Typography>
          <GenericListCustomField
            useGetData={useRoles}
            descriptionKey="description"
            nameKey="name"
            required={false}
            value={roles}
            onChange={handleOnChangeRoles}
            error={false}
            errorMessage=""
            search={searchRoles}
            label="Roles"
            params={{}}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          gap="10px"
        >
          <Typography variant="h2" color={subtitleColor}>
            Job Information
          </Typography>
          <IconButton
            onClick={() => {
              setOrgSettingsVisible(!orgSettingsVisible);
            }}
          >
            <NavbarIcon
              variant={orgSettingsVisible ? "chevron-up" : "chevron-down"}
              sx={{
                width: "16",
                height: "13",
              }}
              color={
                isDarkMode
                  ? theme.palette.custom.whiteTypography
                  : theme.palette.custom.blueTypography
              }
            />
          </IconButton>
        </Box>
        {orgSettingsVisible && (
          <>
            <Typography variant="h3" color={subtitleColor}>
              Job Position
            </Typography>
            <TextField
              label="Job Position"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
            <Typography variant="h3" color={subtitleColor}>
              Department
            </Typography>
            <SearchableDropdown<Pick<Department, "id" | "name">>
              label="Department"
              value={department}
              onChange={(newDepartment: any) =>
                newDepartment !== null
                  ? setDepartment({ id: newDepartment.id, name: newDepartment.name })
                  : setDepartment(null)
              }
              getOptions={searchDepartments}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(a, b) => a.id === b.id}
              actionButton={[
                "Create Department",
                openCreateDepartment ? () => openCreateDepartment() : () => {},
              ]}
            />
            <Typography variant="h3" color={subtitleColor}>
              Manager
            </Typography>
            <SearchableDropdown<Pick<User, "id" | "name">>
              label="Manager"
              value={manager}
              onChange={(newUser: any) =>
                newUser !== null
                  ? setManager({ id: newUser.id, name: newUser.name })
                  : setManager(null)
              }
              getOptions={searchUsers}
              isOptionEqualToValue={(a, b) => a.id === b.id}
              getSummaryDescription={(user: any) => [user.name, user.username]}
            />
          </>
        )}
        <LoadingButton onClick={submit} loading={isLoading} variant="contained">
          {userId ? "Save" : "Send"}
        </LoadingButton>
      </Stack>
    </SmallModal>
  );
};
